import React from "react";

const EachPhoneCaseItem = ({ phone_case, quantity, id }) => {
  const finalPrice = parseInt(phone_case.price);
  return (
    <li className="border-bottom pb-3 mb-3">
      <div>
        <ul className="list-unstyled row mx-n2 d-flex align-items-center">
          <li className="px-2 col-auto cart_img">
            <img
              className="img-fluid"
              src={phone_case?.default_image?.img_path}
              alt={phone_case?.product_name}
            />
          </li>
          <li className="px-2 col">
            <h5 className="text-gray-5 font-size-14 font-weight-bold">
              {phone_case?.product_name}
            </h5>
            <span className="text-gray-5 font-size-14">
              {quantity} x {finalPrice}
            </span>
          </li>
          <li className="px-2 col-auto">
            <h5 className="font-color font-size-14 font-weight-bold">
              ₹ {quantity * finalPrice}
            </h5>
          </li>
        </ul>
      </div>
    </li>
  );
};

export default EachPhoneCaseItem;
