import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { showErrorAlert, showSuccessAlert } from "../../helpers/SweetAlert";

const CallbackHandler = () => {
  const { status } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    // console.log(status);
    let m = "";
    if (status === "1") {
      m = "Payment was successful!";
      navigate(`/orders`);
      showSuccessAlert(m);
    } else if (status === "0") {
      m = "Payment failed.";
      navigate(`/orders`);
      showErrorAlert(m);
    } else if (status === "PENDING") {
      m = "Payment is pending.";
      navigate(`/orders`);
      showErrorAlert(m);
    } else {
      m = "Unknown payment status.";
      navigate(`/orders`);
      showErrorAlert(m);
    }
  }, [status]);

  return (
    <div>
      {/* <h2>Payment Status</h2>
      <p>{message}</p> */}
    </div>
  );
};

export default CallbackHandler;
