import React from "react";

const EachStickerItem = ({
  sticker,
  quantity,
  updateQuantity,
  removeItem,
  id,
  selected_option_choices,
}) => {
  const basicPrice = parseInt(sticker.price);
  // Calculate the total price of selected options
  const optionsPrice = selected_option_choices.reduce(
    (total, optionChoice) =>
      total + parseInt(optionChoice.sticker_choice_map.price),
    0
  );

  const finalPrice = basicPrice + optionsPrice;
  const handleIncrement = () => {
    updateQuantity(id, quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      updateQuantity(id, quantity - 1);
    }
  };

  const handleRemove = () => {
    removeItem(id);
  };
  return (
    <div className=" cart-item-row mb-3">
      <div className="row">
        <div className="col-md-2 col-4">
          <div className="image_box">
            <img
              className="img-fluid  p-1 "
              src={sticker?.default_image?.img_path}
              alt={sticker?.product_name}
            />
          </div>
        </div>
        <div className="col-md-2 col-4">
          <div>
            <button className="cart_col_btn btn  btn-depth text-secondary">
              Product
            </button>
            <p className="cart_col_value">{sticker?.product_name}</p>
          </div>
        </div>

        <div className="col-md-2 col-4">
          <button className="cart_col_btn btn  btn-depth text-secondary">
            Price
          </button>
          <p className="cart_col_value">₹ {finalPrice}</p>
        </div>
        <div className="col-md-2 col-4">
          <button className="cart_col_btn btn  btn-depth text-secondary">
            Quantity
          </button>
          {/* Quantity */}

          <div className="js-quantity row align-items-center justify-content-center">
            <div className="d-flex cart_col_value">
              <button
                className="qty_btn btn  btn-clicks text-secondary "
                onClick={handleDecrement}
              >
                <small className="fas fa-minus btn-icon__inner" />
              </button>

              <div className="qty_count ml-2">
                <input
                  className="qty_input js-result form-control h-auto border-0 rounded p-0 shadow-none"
                  type="text"
                  value={quantity}
                  readOnly
                />
              </div>
              <button
                className=" ml-2 btn  btn-clicks text-secondary qty_btn"
                onClick={handleIncrement}
              >
                <small className="fas fa-plus btn-icon__inner_1" />
              </button>
            </div>
          </div>

          {/* End Quantity */}
        </div>
        <div className="col-md-2 col-4">
          <button className="cart_col_btn btn  btn-depth text-secondary">
            Total
          </button>
          <p className="cart_col_value">₹ {quantity * finalPrice}</p>
          <span
            className="text-gray-32 font-size-26 cart-remove"
            onClick={handleRemove}
          >
            <i className="fa fa-trash-alt"> </i>
          </span>
        </div>
      </div>

      <div className="row">
        {selected_option_choices.map((selected_option_choice) => (
          <div className="col-md-12" key={selected_option_choice.id}>
            <p>
              {selected_option_choice.sticker_option_map.option.option_name}
            </p>
            <p>
              {selected_option_choice.sticker_choice_map.choice.choice_name}
            </p>
            <p>{selected_option_choice.sticker_choice_map.price}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EachStickerItem;
