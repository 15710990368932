import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import Api from "../../helpers/Api";
// import ThemeBtn from "./ThemeBtn";
// import ThemeBtnWithSub from "./ThemeBtnWithSub";
import EachProductCard from "./EachProductCard";
import { Link } from "react-router-dom";
import { lightTheme, darkTheme } from "../../components/SelectTheme";
import { ThemeModeContext } from "../../context/ThemeModeContext";
// import SelectWithCheckBox from "./SelectWithCheckBox";
import { LoaderContext } from "../../context/LoaderContext";
import PreLoader from "../../components/PreLoader";

const CategoryPage = () => {
  const { categoryID } = useParams();
  const [category, setCategory] = useState();
  const [themesOptions, setThemesOptions] = useState([]);
  const [subThemesOptions, setSubThemesOptions] = useState([]);
  const { isDarkMode } = useContext(ThemeModeContext);
  const [brands, setBrands] = useState([]);
  const { isLoading, setIsLoading } = useContext(LoaderContext);

  const [resetCounter, setResetCounter] = useState(0);
  const filterInitial = {
    brand_id: null,
    device_id: null,
    is_custom: null,
    skin_theme_id: null,
    sub_theme_id: null,
    category_id: categoryID,
  };
  const [selectedFilters, setSelectedFilters] = useState(filterInitial);
  const [devices, setDevices] = useState([]);
  const [products, setProducts] = useState([]);
  const [shouldShowCustomizeButton, setShouldShowCustomizeButton] =
    useState(false);
  // const [shouldShowSubTheme, setShouldShowSubTheme] =
  //   useState(false);
  useEffect(() => {
    setIsLoading(true);

    Api.FilterPage(categoryID)
      .then((res) => {
        setCategory(res.data.category);
        setThemesOptions(
          res.data.themes.map((theme) => ({
            value: theme.id,
            label: theme.theme_name,
            image: theme.theme_logo,
          }))
        );
        setBrands(
          res.data.brands.map((brand) => ({
            value: brand.id,
            label: brand.brand_name,
          }))
        );
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setIsLoading(false));
  }, [categoryID, setIsLoading]);
  useEffect(() => {
    // Convert to JSON string
    // const requestBody = JSON.stringify(selectedFilters);
    Api.makeFilterPost(selectedFilters)
      .then((res) => {
        setProducts(res.data.skins);
        // Check if brand_id and device_id are not null
        const isButtonVisible =
          selectedFilters.device_id !== null && selectedFilters.is_custom;
        setShouldShowCustomizeButton(isButtonVisible);
        setSubThemesOptions(
          res.data.sub_themes.map((sub_theme) => ({
            value: sub_theme.sub_theme_id,
            label: sub_theme.sub_theme_name,
          }))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }, [selectedFilters]);
  const handleBrandChange = (selectedOption) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      device_id: null,
      brand_id: selectedOption?.value,
    }));
    if (selectedOption) {
      Api.getDevicesByBrand(selectedOption.value)
        .then((res) => {
          setDevices(
            res.data.devices.map((d) => ({
              value: d.id,
              label: d.model_no,
              is_custom: d.is_custom,
            }))
          );
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      // If no brand is selected, reset the devices list
      setDevices([]);
    }
  };
  const handleDeviceChange = (selectedOption) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      device_id: selectedOption?.value,
      is_custom: selectedOption?.is_custom === 1 ? true : false,
    }));
  };
  const handleThemeChange = (selectedOption) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      skin_theme_id: selectedOption?.value,
    }));
  };
  const handleSubThemeChange = (selectedOption) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      sub_theme_id: selectedOption?.value,
    }));
  };
  const handleReset = () => {
    setSelectedFilters(filterInitial);
    setResetCounter((prevCounter) => prevCounter + 1);
  };

  return (
    <>
      {isLoading ? (
        <PreLoader />
      ) : (
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-2 col-6  my-2">
              <Select
                options={brands}
                placeholder="Select Brand"
                onChange={handleBrandChange}
                styles={isDarkMode ? darkTheme : lightTheme}
                key={`reset_${resetCounter}`}
              />
            </div>
            <div className="col-md-2 col-6  my-2">
              <Select
                options={devices}
                placeholder="Select Model"
                onChange={handleDeviceChange}
                styles={isDarkMode ? darkTheme : lightTheme}
                key={`reset_${resetCounter}`}
              />
            </div>
            {shouldShowCustomizeButton && (
              <div className="col-md-3 col-12  my-2">
                <Link to={`/custom/${selectedFilters.device_id}`}>
                  <button type="button" className="cust_skin_btn btn ">
                    Customize Your Skin
                    <span>
                      <img
                        className="img-icon  p-1 "
                        src="../../assets/img/cus-btn.png"
                        alt="Customization"
                      />
                    </span>
                  </button>
                </Link>
              </div>
            )}
            <div className="col-md-2 col-6  my-2">
              {/* <p className='text-start font-color p_head '>Themes</p> */}
              {/* <SelectWithCheckBox
                themesOptions={themesOptions}
                setSelectedFilters={setSelectedFilters}
                placeholder="Select Themes"
                objKey="skin_themes_id"
                key={`reset_${resetCounter}`}
              /> */}
              <Select
                options={themesOptions}
                placeholder="Select Theme"
                onChange={handleThemeChange}
                styles={isDarkMode ? darkTheme : lightTheme}
                key={`reset_${resetCounter}`}
              />
            </div>

            {subThemesOptions.length > 0 && (
              <div className="col-md-2 col-6  my-2">
                <Select
                  options={subThemesOptions}
                  placeholder="Select Sub Theme"
                  onChange={handleSubThemeChange}
                  styles={isDarkMode ? darkTheme : lightTheme}
                  key={`reset_${resetCounter}`}
                />
              </div>
            )}
            <div className="col-md-1 col-6 reset-btn my-2">
              <button
                type="button"
                className="btn r_btn theme_flt_btn btn-depth"
                onClick={handleReset}
                style={{ width: "110%", padding: "8px" }}
              >
                Reset <i className="fa fa-rotate" aria-hidden="true"></i>
              </button>
            </div>
            {category?.is_pair === 1 && (
              <div className="col-md-1 col-6 reset-btn my-2">
                <Link to={`/couples/${category?.id}`}>
                  <button
                    type="button"
                    className="btn  theme_flt_btn btn-depth"
                    style={{ width: "100%", padding: "8px" }}
                  >
                    Couples
                  </button>
                </Link>
              </div>
            )}
          </div>

          <div className="row each_product_row mt-3">
            {products.length === 0 && <p className="text-center">Not Found</p>}
            {products.map((product) => (
              <EachProductCard
                {...product}
                key={product.id}
                category_name={category?.category_name}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default CategoryPage;
