import React from "react";
import { Link } from "react-router-dom";

const EachSlideCard = ({ img_path, card_title, link }) => {
  return (
    <li className='px-2 flex-shrink-0 flex-xl-shrink-1 mb-2 mt-1'>
      <Link
        to={link}
        className='min-height-126 max-width-320 py-1 py-xl-2 py-wd-1 banner-bg d-flex align-items-center text-gray-90'
      >
        <div className='col col-lg-6 col-xl-5 col-wd-6 mb-3 mb-lg-0 pr-lg-0'>
          <img className='img-fluid' src={img_path} alt={card_title} />
        </div>
        <div className='col col-lg-6 col-xl-7 col-wd-6 pr-xl-4 pr-wd-3'>
          <div className='mb-2 pb-1 font-size-18 font-weight-light text-ls-n1 text-lh-23'>
            <strong>{card_title}</strong>
          </div>
          <div className='link text-gray-90 font-weight-bold font-size-15'>
            Shop now
            <span className='link__icon ml-1'>
              <span className='link__icon-inner'>
                <i className='ec ec-arrow-right-categproes' />
              </span>
            </span>
          </div>
        </div>
      </Link>
    </li>
  );
};

export default EachSlideCard;
