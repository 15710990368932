// ThemeContext.js
import React, { createContext, useEffect, useState } from 'react';

// Create the theme context
export const ThemeModeContext = createContext();

// Create a provider component for the theme context
export const ThemeModeProvider = ({ children }) => {
  const theme_mode = localStorage.getItem('theme_mode');
  const [isDarkMode, setIsDarkMode] = useState(
    theme_mode === null ? false : theme_mode === '1' ? true : false
  );

  const toggleMode = () => {
    setIsDarkMode(!isDarkMode);
  };
  useEffect(() => {
    if (isDarkMode) {
      localStorage.setItem('theme_mode', 1);
      document.body.classList.add('dark-mode');
      document.body.classList.remove('light-mode');
    } else {
      localStorage.setItem('theme_mode', 0);
      document.body.classList.remove('dark-mode');
      document.body.classList.add('light-mode');
    }
  }, [isDarkMode]);
  return (
    <ThemeModeContext.Provider value={{ isDarkMode, toggleMode }}>
      {children}
    </ThemeModeContext.Provider>
  );
};
