import React, { useEffect, useRef, useState } from "react";
import Api from "../../helpers/Api";
import EachCustomSkin from "./EachCustomSkin";

const EachPartAccordion = ({
  id,
  mainThemes,
  part_id,
  handleSkinSelection,
  addToCartData,
  setPartBasedSkins,
  selectedPartSkin,
  handleSkinRemoval,
}) => {
  const filterInitial = {
    part_id: part_id,
    skin_theme_id: mainThemes[0]?.id || null,
  };
  const [themeActiveTab, setThemeActiveTab] = useState(0);
  const [selectedFilters, setSelectedFilters] = useState(filterInitial);
  const [subThemeWithSkins, setSubThemeWithSkins] = useState([]);
  const [activeSubTheme, setActiveSubTheme] = useState(0);
  const tabRefs = useRef([]);

  useEffect(() => {
    Api.ProdCustomFilter(selectedFilters.part_id, selectedFilters.skin_theme_id)
      .then((res) => {
        if (res.data.main_theme_Based_skins.length > 0) {
          // Create a new object in sub_with_skins
          const newObjectForMainThemeBasedSkins = {
            sub_theme_name: "",
            sub_theme_id: 0,
            skins_arr: res.data.main_theme_Based_skins,
          };
          // Add the new object at the beginning of sub_with_skins
          res.data.sub_with_skins.unshift(newObjectForMainThemeBasedSkins);
        }
        setSubThemeWithSkins(res.data.sub_with_skins);
        setPartBasedSkins((prevPartBasedSkins) => ({
          ...prevPartBasedSkins,
          [part_id]: res.data.category_and_part_related_skins,
        }));
      })
      .catch((e) => {
        console.log(e);
      });
  }, [selectedFilters]);

  const handleTabClick = (index) => {
    setThemeActiveTab(index);

    // Scroll to the clicked tab's position
    if (tabRefs.current && tabRefs.current[index]) {
      tabRefs.current[index].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      skin_theme_id: mainThemes[index].id,
    }));
  };

  // Function to show the previous set of sub-themes
  const handlePrevSubTheme = () => {
    setActiveSubTheme((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  // Function to show the next set of sub-themes
  const handleNextSubTheme = () => {
    setActiveSubTheme((prevIndex) =>
      Math.min(prevIndex + 1, subThemeWithSkins.length - 1)
    );
  };
  return (
    <div className="card-body p-0">
      <div id="body">
        <div className="tabContent p-0">
          <div className=" tab-pane active">
            <div className="part_head_no details-wrap out-wrap custom-details-wrap">
              <ul id="tabs" className="main_thmes nav-tabs" role="tablist">
                {mainThemes.map((theme, index) => (
                  <li
                    key={theme.id}
                    onClick={() => handleTabClick(index)}
                    ref={(element) => (tabRefs.current[index] = element)}
                  >
                    <a
                      className={themeActiveTab === index ? "active" : ""}
                      href={`#tab${index + 1}`}
                      aria-controls="home"
                      role="tab"
                      data-toggle="tab"
                    >
                      {theme.theme_name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Show the currently active sub-theme set */}
          <div key={subThemeWithSkins[activeSubTheme]?.sub_theme_id}>
            <h5>{subThemeWithSkins[activeSubTheme]?.sub_theme_name}</h5>
            <div className="row">
              {subThemeWithSkins[activeSubTheme]?.skins_arr.map((each_skin) => (
                <EachCustomSkin
                  key={each_skin.id}
                  part_id={part_id}
                  {...each_skin}
                  addToCartData={addToCartData}
                  handleSkinSelection={handleSkinSelection}
                  selectedPartSkin={selectedPartSkin}
                  handleSkinRemoval={handleSkinRemoval}
                  // nextStep={nextStep}
                />
              ))}
            </div>
          </div>

          {activeSubTheme > 0 && (
            <div className="prev-btn" onClick={handlePrevSubTheme}>
              &#8249;
            </div>
          )}
          {activeSubTheme < subThemeWithSkins.length - 1 && (
            <div className="next-btn" onClick={handleNextSubTheme}>
              &#8250;
            </div>
          )}

          {/* {mainThemes &&
            mainThemes.map((each_main) =>
              activeTab === each_main.id ? (
                <EachThemeBox
                  key={each_main.id}
                  part_id={id}
                  mask_img={id}
                  subThemes={each_main.sub_themes}
                />
              ) : null
            )} */}
        </div>
      </div>
    </div>
  );
};

export default EachPartAccordion;
