import React from "react";
const Customize = ({
  skinRelatedKit,
  onCheckboxChange,
  addToCartData,
  withLogo,
  finishes,
  onFinishChange,
  handleWithLogo,
}) => {
  return (
    <div className="col-md-12">
      <div className="row">
        <div className="col-md-12">
          <h6 className="font-color"> Customize</h6>
        </div>
      </div>
      {skinRelatedKit.map((each_kit_item, i) => (
        <div className="row" key={each_kit_item.id}>
          <div
            className=" col-md-12 mt-2 mb-3 d-flex align-items-center"
            key={each_kit_item.id}
          >
            <div className="col-md-3 col-4 d-flex justify-content-center">
              <div className="skin_box_img">
                <img
                  src={each_kit_item.img_path}
                  alt={each_kit_item.heading}
                  className="img-fluid p-2"
                />
              </div>
            </div>
            <div className="col-md-7 col-7 pro-desc">
              <h4 className="font-color">{each_kit_item.heading}</h4>
              <p className="font-17 font-color text-left desc">
                {each_kit_item.desc}
              </p>
              <p className="font-17 font-color text-left">
                ₹ {each_kit_item.price}
              </p>
            </div>
            <div
              className="col-md-2 col-1 d-flex justify-content-center"
              id="customize"
            >
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="skin_related_kit"
                  value={each_kit_item.id}
                  id={`kit-items-${each_kit_item.id}`}
                  checked={each_kit_item.id === addToCartData.skin_related_kit}
                  onChange={(e) => onCheckboxChange(each_kit_item.id)}
                />
                <label
                  className="form-check-label"
                  htmlFor={`kit-items-${each_kit_item.id}`}
                ></label>
              </div>

              {/* <input
                type="radio"
                name="skin_related_kit"
                className="neumorphic-radio check-box"
                checked={each_kit_item.id === addToCartData.skin_related_kit}
                onChange={(e) => onCheckboxChange(each_kit_item.id)}
              />
              <label className="radio-label">ddfsdfdsfsdfsdf</label> */}
            </div>
          </div>
        </div>
      ))}
      {withLogo === 1 && (
        <div className="row d-flex align-items-center">
          <div className="col-md-5 col-7 text-center">
            <h4 className="font-color">With Logo/ Without Logo</h4>
          </div>
          <div className="col-md-3 col-1"></div>
          <div className="col-md-4 col-3">
            <div className="custom-control custom-switch ">
              <input
                type="checkbox"
                className="custom-control-input btn-depth"
                name="with_logo"
                id="customSwitch1"
                checked={addToCartData.with_logo}
                onChange={(e) => handleWithLogo()}
              />{" "}
              <label className="custom-control-label" htmlFor="customSwitch1">
                With Logo
              </label>
            </div>
          </div>
        </div>
      )}
      <h4 className="font-color mt-1 mb-n3">Choose Finish Type</h4>

      {/* there finish.id is skin finish relation id */}
      <div className="row  d-flex align-items-center">
        {finishes.map((finish) => (
          <div className="col-md-6 col-12 " key={finish.id}>
            <div className="row  d-flex align-items-center">
              <div className="col-md-3 col-5">
                {finish.finish_logo && (
                  <img src={finish.finish_logo} alt={finish.finish_name} />
                )}
              </div>
              <div className="col-md-3 col-5">
                <p className="font-17 font-color ">{finish.finish_name}</p>
              </div>

              <div className="col-md-3 col-5">
                <p className="font-17 font-color text-center">
                  ₹{finish.reg_price}
                </p>
              </div>
              <div className="col-md-1 col-2" id="customize">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="finish_id"
                    id={`custom-radio-${finish.id}`}
                    value={finish.id}
                    checked={finish.id === addToCartData.finish_id}
                    onChange={(e) => onFinishChange(finish.id)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`custom-radio-${finish.id}`}
                  ></label>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Customize;
