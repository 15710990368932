import Header from "./Header";
import Footer from "./Footer";
import SideBar from "./SideBar";
import { ThemeModeProvider } from "../context/ThemeModeContext";
import { LoginAsideProvider } from "../context/LoginAsideContext";
import { LeftAsideProvider } from "../context/LeftAsideContext";
import { AuthProvider } from "../context/AuthContext";
import Navbar from "./Navbar";

const MainLayout = ({ children }) => {
  return (
    <div>
      <AuthProvider>
        <LoginAsideProvider>
          <LeftAsideProvider>
            <ThemeModeProvider>
              <Header />
              <div id="min-height">{children}</div>
              {/* <SideBar /> */}
              {/* <Navbar /> */}
              <Footer />
            </ThemeModeProvider>
          </LeftAsideProvider>
        </LoginAsideProvider>
      </AuthProvider>
    </div>
  );
};
export default MainLayout;
