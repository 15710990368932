import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Api from "../../helpers/Api";
import EachThumbnail from "./EachThumbnail";
import Customize from "./Customize";
import RelatedProducts from "./RelatedProducts";
import AddToCart from "./AddToCart";
import CategoryRelatedProducts from "./CategoryRelatedProducts";
// import AuthContext from "../../context/AuthContext";
import { showSuccessAlert } from "../../helpers/SweetAlert";
import NormalSelect from "../../components/NormalSelect";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const ProductDetail = () => {
  // const { user } = useContext(AuthContext);
  const { skinId } = useParams();
  /* Skin Images Slide */
  const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    arrows: false,
  };

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [customTabShow, setCustomTabShow] = useState(1);
  const [activeSubTheme, setActiveSubTheme] = useState(0);
  const [formattedCategoryName, setFormattedCategoryName] = useState("");
  const [activeSkin, setActiveSkin] = useState({});
  const [thumbnail, setThumbnail] = useState(null);
  const [subThemeWithSkins, setSubThemeWithSkins] = useState([]);
  const [deviceRelatedThemes, setDeviceRelatedThemes] = useState([]);
  const [skinRelatedKit, setSkinRelatedKit] = useState([]);
  const [deviceRelatedKit, setDeviceRelatedKit] = useState([]);
  const [categoryRelatedKit, setCategoryRelatedKit] = useState([]);
  const [colorRelatedSkins, setColorRelatedSkins] = useState([]);
  const [skinMultiImages, setSkinMultiImages] = useState([]);
  const [finishes, setFinishes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [devices, setDevices] = useState([]);
  const [brandBasedDevices, setBrandBasedDevices] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const filterInitial = {
    skin_id: skinId,
    skin_theme_id: null,
    device_id: null,
  };
  const addToCartDataInit = {
    skin_id: activeSkin?.id,
    skin_related_kit: null,
    with_logo: false,
    device_extra_items: [],
    category_extra_items: [],
    finish_id: null,
  };
  const [selectedFilters, setSelectedFilters] = useState(filterInitial);
  const [showCustomizeButton, setShowCustomizeButton] = useState(false);
  const [addToCartData, setAddToCartData] = useState(addToCartDataInit);

  useEffect(() => {
    Api.DetailPage(skinId)
      .then((res) => {
        const {
          category,
          skin,
          color_related_skins,
          device_related_themes,
          category_related_kit_parts,
          skin_multi_images,
          brands,
          devices,
          selected_main,
          skin_angles_kit,
          thumbnail,
          device_related_kit_parts,
          finishes,
        } = res.data;
        setCurrentStep(1);
        setThumbnail(thumbnail);
        setBrands(brands);
        setDevices(devices);
        setSkinMultiImages(skin_multi_images);
        setActiveSkin(skin);
        setCustomTabShow(skin.with_logo);
        if (skin.with_logo === 0) {
          setCustomTabShow(
            skin_angles_kit.length > 0 || finishes.length > 0 ? 1 : 0
          );
        }
        setSkinRelatedKit(skin_angles_kit);
        setFinishes(finishes);
        setDeviceRelatedKit(device_related_kit_parts);
        setAddToCartData((prev) => ({
          ...prev,
          skin_id: skin.id,
          finish_id: skin.default_finish_id,
        }));
        setDeviceRelatedThemes(device_related_themes);
        setCategoryRelatedKit(category_related_kit_parts);
        setColorRelatedSkins(color_related_skins);
        const filteredDevices = devices
          .filter((d) => d.brand_id === skin.brand_id)
          .map((d) => ({
            value: d.id,
            label: d.model_no,
            is_custom: d.is_custom,
            skin_id: d.skin_id,
          }));
        setBrandBasedDevices(filteredDevices);
        // Replace whitespace with "-" and convert to lowercase
        setFormattedCategoryName(
          category.category_name?.replace(/\s/g, "-").toLowerCase()
        );

        setSelectedFilters((prev) => ({
          ...prev,
          skin_theme_id: selected_main,
          device_id: skin.device_id,
        }));
        const activeDevice = devices.find(
          (device) => device.id === skin.device_id
        );
        setShowCustomizeButton(activeDevice?.is_custom === 1 ? true : false);

        if (device_related_themes.length > 0) {
          // Find the index of the tab with the selected_main theme id
          const selectedTabIndex = device_related_themes.findIndex(
            (theme) => theme.id === selected_main
          );

          // Set the active tab index based on the selected_main theme id
          if (selectedTabIndex !== -1) {
            setActiveTab(selectedTabIndex);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => "");
  }, [skinId]);

  useEffect(() => {
    if (
      selectedFilters.skin_id !== null &&
      selectedFilters.skin_theme_id !== null
    ) {
      Api.ProdDetailFilter(skinId, selectedFilters.skin_theme_id)
        .then((res) => {
          const {
            selected_skin_from_main,
            selected_sub,
            main_theme_Based_skins,
            sub_with_skins,
          } = res.data;
          //
          //
          if (main_theme_Based_skins.length > 0) {
            // Create a new object in sub_with_skins
            const newObjectForMainThemeBasedSkins = {
              sub_theme_name: "",
              sub_theme_id: 0,
              skins_arr: res.data.main_theme_Based_skins,
            };
            // Add the new object at the beginning of sub_with_skins
            sub_with_skins.unshift(newObjectForMainThemeBasedSkins);
          }
          if (sub_with_skins.length > 0) {
            let selectedSubIndex = 0;

            if (selected_skin_from_main === 0) {
              // Find the index of the tab with the selected_sub theme id
              selectedSubIndex = sub_with_skins.findIndex(
                (sub) => sub.sub_theme_id === selected_sub
              );
            }
            // Set the active tab index based on the selected_sub theme id
            if (selectedSubIndex !== -1) {
              setActiveSubTheme(selectedSubIndex);
            }
          }
          setSubThemeWithSkins(res.data.sub_with_skins);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [selectedFilters, skinId]);
  const handlePrevClick = (skin_id) => {
    navigate(`/${formattedCategoryName}/${skin_id}`);
  };
  // Function to show the previous set of sub-themes
  const handlePrevSubTheme = () => {
    setActiveSubTheme((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  // Function to show the next set of sub-themes
  const handleNextSubTheme = () => {
    setActiveSubTheme((prevIndex) =>
      Math.min(prevIndex + 1, subThemeWithSkins.length - 1)
    );
  };
  const tabRefs = useRef([]);

  const handleTabClick = (index) => {
    setActiveTab(index);

    // Scroll to the clicked tab's position
    if (tabRefs.current && tabRefs.current[index]) {
      tabRefs.current[index].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }

    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      skin_theme_id: deviceRelatedThemes[index].id,
    }));
  };

  const nextStep = () => {
    let next_step = currentStep + 1;
    if (next_step === 2) {
      next_step = customTabShow === 0 ? next_step + 1 : next_step;
    }
    if (next_step === 3) {
      next_step =
        deviceRelatedKit.length > 0 || categoryRelatedKit.length > 0
          ? next_step
          : next_step + 1;
    }
    setCurrentStep(next_step);
  };
  const prevStep = () => {
    let prev_step = currentStep - 1;
    if (prev_step === 3) {
      prev_step =
        deviceRelatedKit.length > 0 || categoryRelatedKit.length > 0
          ? prev_step
          : prev_step - 1;
    }
    if (prev_step === 2) {
      prev_step = customTabShow === 0 ? prev_step - 1 : prev_step;
    }
    setCurrentStep(prev_step);
  };

  const handleSkinKit = (itemId) => {
    setAddToCartData((prevData) => ({
      ...prevData,
      skin_related_kit: itemId,
    }));
  };
  const handleDeviceKit = (isChecked, itemId) => {
    setAddToCartData((prevData) => ({
      ...prevData,
      device_extra_items: isChecked
        ? [...prevData.device_extra_items, itemId]
        : prevData.device_extra_items.filter((id) => id !== itemId),
    }));
  };
  const handleCategoryKit = (isChecked, itemId) => {
    setAddToCartData((prevData) => ({
      ...prevData,
      category_extra_items: isChecked
        ? [...prevData.category_extra_items, itemId]
        : prevData.category_extra_items.filter((id) => id !== itemId),
    }));
  };
  const handleBrandChange = (selectedOption) => {
    if (selectedOption) {
      const filteredDevices = devices
        .filter((d) => d.brand_id === selectedOption.value)
        .map((d) => ({
          value: d.id,
          label: d.model_no,
          is_custom: d.is_custom,
          skin_id: d.skin_id,
        }));

      setBrandBasedDevices(filteredDevices);
    } else {
      setBrandBasedDevices([]);
    }
  };
  const handleDeviceChange = (selectedOption) => {
    const newDeviceId = selectedOption.value;

    // Find the selected device in brandBasedDevices array
    const selectedDevice = brandBasedDevices.find(
      (device) => device.value === newDeviceId
    );

    const skin_id = selectedDevice.skin_id;

    navigate(`/${formattedCategoryName}/${skin_id}`);
  };
  const handleFinishType = (itemId) => {
    setAddToCartData((prevData) => ({
      ...prevData,
      finish_id: itemId,
    }));
  };
  const handleWithLogo = () => {
    setAddToCartData((prevData) => ({
      ...prevData,
      with_logo: !prevData.with_logo,
    }));
  };
  const handleAddCart = (e) => {
    // if (user.apiToken) {
    // Convert to JSON string
    const requestBody = JSON.stringify(addToCartData);
    Api.ProdDetailAddToCart(requestBody)
      .then((res) => {
        // console.log(res.data);
        showSuccessAlert("Skin Added In Your Cart SuccessFully");
        navigate("/cart");
      })
      .catch((e) => {
        console.log(e);
      });
    // } else {
    //   e.stopPropagation();
    //   setLoginAsideActive(true);
    // }
  };
  const renderSwitch = (param) => {
    switch (param) {
      // case 1 to show stepOne form and passing nextStep,
      // prevStep, and handleInputData as handleFormData method as prop and also formData as value to the form
      case 1:
        return (
          <>
            <div id="body">
              <div className="out-wrap ">
                <div className="tab-content">
                  <div className="tab-pane active">
                    {/* Show the currently active sub-theme set */}
                    <div key={subThemeWithSkins[activeSubTheme]?.sub_theme_id}>
                      <div className="row d-flex align-items-center justify-content-center">
                        {activeSubTheme > 0 && (
                          <div
                            className=" col-md-3 prev-btn"
                            onClick={handlePrevSubTheme}
                          >
                            <i className="fa fa-chevron-left"></i>
                          </div>
                        )}

                        <h4 style={{ color: "#bdbdbd" }}>
                          {subThemeWithSkins[activeSubTheme]?.sub_theme_name}
                        </h4>
                        {activeSubTheme < subThemeWithSkins.length - 1 && (
                          <div
                            className=" col-md-3 next-btn"
                            onClick={handleNextSubTheme}
                          >
                            <i className="fa fa-chevron-right"></i>
                          </div>
                        )}
                      </div>
                      <div className="row mt-3">
                        {subThemeWithSkins[activeSubTheme]?.skins_arr.map(
                          (each_skin) => (
                            <EachThumbnail
                              key={each_skin.id}
                              {...each_skin}
                              activeSkin={activeSkin}
                              setActiveSkin={setActiveSkin}
                              handlePrevClick={handlePrevClick}
                              nextStep={nextStep}
                            />
                          )
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Previous and Next buttons */}
                </div>
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <Customize
            prevStep={prevStep}
            nextStep={nextStep}
            skinRelatedKit={skinRelatedKit}
            withLogo={activeSkin.with_logo}
            onCheckboxChange={handleSkinKit}
            addToCartData={addToCartData}
            finishes={finishes}
            onFinishChange={handleFinishType}
            handleWithLogo={handleWithLogo}
            currentStep={currentStep}
          />
        );
      case 3:
        return (
          <>
            <div className="col-md-12">
              <div className="row ">
                <div className="col-md-12">
                  <h6 className="font-color">Related Products</h6>
                </div>
                <RelatedProducts
                  prevStep={prevStep}
                  nextStep={nextStep}
                  deviceRelatedKit={deviceRelatedKit}
                  onCheckboxChange={handleDeviceKit}
                  addToCartData={addToCartData}
                  currentStep={currentStep}
                />
                <CategoryRelatedProducts
                  prevStep={prevStep}
                  nextStep={nextStep}
                  categoryRelatedKit={categoryRelatedKit}
                  onCheckboxChange={handleCategoryKit}
                  addToCartData={addToCartData}
                  currentStep={currentStep}
                />
              </div>
            </div>
          </>
        );
      case 4:
        return (
          <AddToCart
            prevStep={prevStep}
            nextStep={nextStep}
            handleAddCart={handleAddCart}
            activeSkin={activeSkin}
            thumbnail={thumbnail}
            addToCartData={addToCartData}
            deviceRelatedKit={deviceRelatedKit}
            skinRelatedKit={skinRelatedKit}
            categoryRelatedKit={categoryRelatedKit}
            finishes={finishes}
          />
        );
      default:
        return <AddToCart prevStep={prevStep} nextStep={nextStep} />;
    }
  };
  const responsiveSettings = [
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ];
  return (
    <>
      {/* {isLoading ? (
        <PreLoader />
      ) : ( */}
      <div className="container p-2">
        <div className="row d-flex ">
          <div className="col-md-4 col-12 ">
            <div className="row mb-2">
              <div className="col-md-6 col-6 my-2">
                <NormalSelect
                  options={brands.map((b) => ({
                    value: b.id,
                    label: b.brand_name,
                  }))}
                  placeholder="Select Brand"
                  onChange={handleBrandChange}
                  default_selected={activeSkin?.brand_id}
                />
              </div>
              <div className="col-md-6 col-6 my-2">
                <NormalSelect
                  options={brandBasedDevices}
                  placeholder="Select Model"
                  onChange={handleDeviceChange}
                  default_selected={activeSkin?.device_id}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-6 col-6">
                {/* <button className="btn back_btn" onClick={() => navigate(-1)}> */}
                <Link
                  to={`/category/${activeSkin?.category_id}`}
                  className="btn back_btn"
                  // onClick={handleAsideClose}
                >
                  <span
                  // style={{
                  //   padding: "11px 13px ",
                  //   margin: "0px 10px 0px -20px",
                  // }}
                  >
                    <i className="fa fa-backward" aria-hidden="true"></i>
                  </span>
                  Back
                </Link>
              </div>
              {showCustomizeButton && (
                <div className="col-md-6 col-6  my-1 d-page">
                  <Link to={`/custom/${selectedFilters.device_id}`}>
                    <button
                      type="button"
                      className="cust_skin_btn btn custom-mobile-font-size"
                    >
                      Customize Your Skin
                      <span>
                        <img
                          className="img-icon  p-1 "
                          src="../../assets/img/cus-btn.png"
                          alt=" Customization"
                        />
                      </span>
                    </button>
                  </Link>
                </div>
              )}
            </div>
          </div>

          <div id="body" className="col-md-8 col-12 ">
            <div
              className={`col-md-12 col-6 text-right ${
                currentStep > 1 && currentStep < 4 ? "prev-step" : "next-step"
              }`}
            >
              {currentStep > 1 && (
                <button
                  className="prev_btn btn btn-depth"
                  onClick={prevStep}
                  // disabled={currentStep === 1}
                >
                  <i className="fa fa-angles-left"></i>
                  {/* Previous */}
                </button>
              )}
              {currentStep < 4 && (
                <button
                  className="next_btn btn btn-depth"
                  onClick={nextStep}
                  // disabled={currentStep === 4}
                >
                  <span className="circle-animation"></span>
                  {/* Proceed */}
                  <i className="fa fa-angles-right"></i>
                </button>
              )}
            </div>
            {currentStep === 1 && (
              <div className="part_head details-wrap out-wrap detail-out-wrap">
                <ul id="tabs" className="main_thmes nav-tabs" role="tablist">
                  {deviceRelatedThemes.map((theme, index) => (
                    <li
                      key={theme.id}
                      onClick={() => handleTabClick(index)}
                      ref={(element) => (tabRefs.current[index] = element)}
                    >
                      <a
                        className={activeTab === index ? "active" : ""}
                        href={`#tab${index + 1}`}
                        aria-controls="home"
                        role="tab"
                        data-toggle="tab"
                      >
                        {theme.theme_name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          {/* <div className='col-md-1 col-3 d-flex'>
            {currentStep > 1 && (
              <button
                className={`prev_btn btn btn-depth  ${
                  currentStep === 4 ? 'prev_btn_m1' : ''
                }`}
                onClick={prevStep}

              >
                <i className='fa fa-angles-left'></i>
              </button>
            )}
            {currentStep < 4 && (
              <button
                className={`next_btn btn btn-depth ${
                  currentStep > 1 ? 'next_btn_m2' : 'next_btn_m1'
                }`}
                onClick={nextStep}
                // disabled={currentStep === 4}
              >
                <span className='circle-animation'></span>
                <i className='fa fa-angles-right'></i>
              </button>
            )}
          </div> */}
        </div>

        <div className="row ">
          {/* <div className="col-md-4 pro_det_step"> */}
          <div className="col-md-4 ">
            <div className="pro_det_img slide-container">
              {/* https://react-slideshow-image.netlify.app/?path=/story/examples-customarrows--page */}
              {/* https://www.npmjs.com/package/react-slideshow-image */}
              {skinMultiImages.length > 0 && (
                <Slide {...properties} responsive={responsiveSettings}>
                  {skinMultiImages.map((each_angle) => (
                    <div className="each-slide-effect" key={each_angle.id}>
                      <img src={each_angle.skin_img} alt="Skin Images" />
                    </div>
                  ))}
                </Slide>
              )}
            </div>
            <div className="reltd_pro">
              <div className="row">
                {colorRelatedSkins.map((eachColor) => (
                  <EachThumbnail
                    key={eachColor.id}
                    {...eachColor}
                    activeSkin={activeSkin}
                    handlePrevClick={handlePrevClick}
                    currentStep={currentStep}
                    nextStep={nextStep}
                    prevStep={prevStep}
                  />
                ))}
              </div>
            </div>
          </div>
          {/* <div className="col-md-8 pro_step"> */}
          <div className="col-md-8 ">
            {/* <div
              className={`col-md-12 col-6 text-right ${
                currentStep > 1 && currentStep < 4 ? 'prev-step' : 'next-step'
              }`}
            >
              {currentStep > 1 && (
                <button
                  className='prev_btn btn btn-depth'
                  onClick={prevStep}
                  // disabled={currentStep === 1}
                >
                  <i className='fa fa-angles-left'></i> Previous Step
                </button>
              )}
              {currentStep < 4 && (
                <button
                  className='next_btn btn btn-depth'
                  onClick={nextStep}
                  // disabled={currentStep === 4}
                >
                  <span className='circle-animation'></span>
                  Next Step<i className='fa fa-angles-right'></i>
                </button>
              )}
            </div> */}
            <div className="row skin_box scroll ">
              {renderSwitch(currentStep)}
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default ProductDetail;
