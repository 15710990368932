import React from "react";
import { Link } from "react-router-dom";

const PhoneCaseTypeCard = ({ id, type_name, image_path }) => {
  return (
    <div className="col-md-2">
      <div className="card mx-2 shadow-soft">
        <Link to={`/phone-cases/${id}`} className="font-size-12 text-gray-5">
          <div className="p-0">
            <img src={image_path} alt={type_name} className="img-fluid " />
          </div>
          <p>{type_name}</p>
        </Link>
      </div>
    </div>
  );
};

export default PhoneCaseTypeCard;
