import React, { useContext } from "react";
import Select from "react-select";
import { lightTheme, darkTheme } from "./SelectTheme";
import { ThemeModeContext } from "../context/ThemeModeContext";

const NormalSelect = ({ options, placeholder, onChange,default_selected }) => {
  const { isDarkMode } = useContext(ThemeModeContext);
    // Find the default selected brand based on brand_id
    const selectedOption = options.find((option) => option.value === default_selected);
  return (
    <Select
      options={options}
      placeholder={placeholder}
      onChange={onChange}
      value={selectedOption}
      styles={isDarkMode ? darkTheme : lightTheme}
    />
  );
};

export default NormalSelect;
