import Home from "./pages/home/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainLayout from "./layout/MainLayout";
import CategoryPage from "./pages/filterpage/CategoryPage";
import Cart from "./pages/cartpage/Cart";
import ProductDetail from "./pages/detailpage/ProductDetail";
import Custom from "./pages/custompage/Custom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Checkout from "./pages/checkoutpage/Checkout";
import OrderList from "./pages/OrderList";
import TermsAndCondition from "./pages/terms-and-condition";
import ShippingCanceller from "./pages/shipping-canceller";
import PrivacyPolicy from "./pages/privacy-policy";
import ContactUs from "./pages/contact-us";
import MaskImagesList from "./pages/maskimage/MaskImagesList";
import MaskImagesCreate from "./pages/maskimage/MaskImagesCreate";
import CouplePreview from "./pages/couplepage/CouplePreview";
import CoupleFilterPage from "./pages/couplelistpage/CoupleFilterPage";
import CallbackHandler from "./pages/checkoutpage/CallbackHandler";
import ResetPassword from "./pages/recoverpage/ResetPassword";
import { LoaderProvider } from "./context/LoaderContext";
import NfcList from "./pages/nfc/NfcList";
import NfcPreview from "./pages/nfc/NfcPreview";
import AccessoryProductPreview from "./pages/accessories/productpreviewpage/AccessoryProductPreview";
import AccessoryList from "./pages/accessories/productlistpage/AccessoryList";
import FramePreview from "./pages/photoFrame/FramePreview";
import FrameList from "./pages/photoFrame/FrameList";
import AcrylicListPage from "./pages/acrylic/listpage/AcrylicListPage";
import AcrylicPreviewPage from "./pages/acrylic/previewpage/AcrylicPreviewPage";
import Invoice from "./pages/invoice";
import MyAccount from "./pages/my_account_page/MyAccount";
import StickerList from "./pages/stickers/StickerList";
import StickerTypeList from "./pages/stickers/StickerTypeList";
import StickerProductDetail from "./pages/stickers/StickerProductDetail";
import PhoneCaseTypeList from "./pages/phone_cases/PhoneCaseTypeList";
import PhoneCasesList from "./pages/phone_cases/PhoneCasesList";
import PhoneCasePreview from "./pages/phone_cases/PhoneCasePreview";

const App = () => {
  return (
    <BrowserRouter>
      <ToastContainer />
      <LoaderProvider>
        <MainLayout>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route
              path="/payment-status/:status"
              element={<CallbackHandler />}
            />
            <Route path="/password-reset/:token" element={<ResetPassword />} />
            <Route
              path="/terms-and-condition"
              element={<TermsAndCondition />}
            ></Route>
            <Route
              path="/shipping-canceller"
              element={<ShippingCanceller />}
            ></Route>
            <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
            <Route path="/contact-us" element={<ContactUs />}></Route>
            <Route path="/custom/:DeviceID" element={<Custom />}></Route>
            <Route path="/mask-images" element={<MaskImagesList />}></Route>

            <Route path="/nfc" element={<NfcList />}></Route>
            {/* stickers routes */}
            <Route path="/sticker-types" element={<StickerTypeList />}></Route>
            <Route path="/stickers/:TypeId" element={<StickerList />}></Route>
            <Route
              path="/sticker/:ProductId"
              element={<StickerProductDetail />}
            ></Route>
            {/* phone cases routes */}
            <Route
              path="/phone-cases-types"
              element={<PhoneCaseTypeList />}
            ></Route>
            <Route
              path="/phone-cases/:TypeId"
              element={<PhoneCasesList />}
            ></Route>
            <Route
              path="/phone-case/:ProductId"
              element={<PhoneCasePreview />}
            ></Route>
            <Route path="/nfc/:NfcId" element={<NfcPreview />}></Route>
            <Route path="/photo-frame" element={<FrameList />}></Route>
            <Route path="/acrylic" element={<AcrylicListPage />}></Route>
            <Route
              path="/acrylic/:AcrylicId"
              element={<AcrylicPreviewPage />}
            ></Route>
            <Route
              path="/photo-frame/:FrameId"
              element={<FramePreview />}
            ></Route>
            <Route
              path="/mask-images/create"
              element={<MaskImagesCreate />}
            ></Route>
            <Route
              path="/category/:categoryID"
              element={<CategoryPage />}
            ></Route>
            <Route
              path="/couples/:categoryID"
              element={<CoupleFilterPage />}
            ></Route>
            <Route
              path="/couples/preview/:skinId"
              element={<CouplePreview />}
            ></Route>
            <Route path="/cart" element={<Cart />}></Route>
            <Route path="/my-account" element={<MyAccount />}></Route>
            <Route path="/invoice" element={<Invoice />}></Route>
            <Route path="/checkout" element={<Checkout />}></Route>
            <Route path="/orders" element={<OrderList />}></Route>
            <Route
              path="/:categoryName/:skinId"
              element={<ProductDetail />}
            ></Route>
            {/* accessories based routes start */}
            <Route
              path="/accessories/:AccessoryID"
              element={<AccessoryList />}
            ></Route>
            <Route
              path="/accessories/:AccessoryName/:ProductId"
              element={<AccessoryProductPreview />}
            ></Route>
            {/* accessories based routes end */}
          </Routes>
        </MainLayout>
      </LoaderProvider>
    </BrowserRouter>
  );
};

export default App;
