import React from "react";
import { Link } from "react-router-dom";

const EachNfc = ({ product_name, id, price, d_price, img_path }) => {
  return (
    <div className="col-md-2 col-6 ">
      <div className="mr-1 p-card ">
        <Link to={`/nfc/${id}`} className="font-size-12 text-gray-5">
          <div className="content">
            <div className="front cat_img">
              <img src={img_path} alt={product_name} className="img-fluid " />
            </div>

            <div className="back from-bottom">
              <div className="row pl-3 mt-2 mb-3 price-card d-flex justify-content-between align-items-center">
                <h4 className="text-center pt-2 price-c"> {product_name}</h4> |
                <h4 className="text-center pt-2 price-c"> ₹ {price}</h4>
                <div className="product-btn d-flex align-items-center">
                  {/* <button className='btn btn-link text-secondary fav'>
              <span>
                <i className='fa fa-shopping-basket'></i>
              </span>
            </button> */}

                  <button className="btn btn-link text-secondary ">
                    <span>
                      <i className="fa fa-magnifying-glass-arrow-right"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default EachNfc;
