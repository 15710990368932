import React, { useState, useEffect, useContext } from "react";
import ShareButton from "../components/ShareButton";
import { ThemeModeContext } from "../context/ThemeModeContext";
import { Link } from "react-router-dom";
import Api from "../helpers/Api";
import { ToastSuccess, ToastError } from "../helpers/Toast";

const Footer = () => {
  const { toggleMode, isDarkMode } = useContext(ThemeModeContext);
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 500) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  /* Current year */
  const [currentYear, setCurrentYear] = useState("");
  useEffect(() => {
    const year = new Date().getFullYear();
    setCurrentYear(year);
  }, []);
  const handleToggleMode = () => {
    toggleMode(); // Call the toggleMode function from the context
  };
  const whatsappLink = "https://api.whatsapp.com/send?phone=+919940255602";

  /* Subscriber */
  const [disable, setDisable] = useState(false);
  let subData = {
    email: "",
  };

  const [formData, setFormData] = useState(subData);
  const handleInput = (event) => {
    const latestData = { ...formData };
    latestData[event.target.name] = event.target.value;
    setFormData(latestData);
  };
  const SubmitForm = (event) => {
    event.preventDefault();
    setDisable(true);
    const data = new FormData(event.target);
    Api.Subscriber(data)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          ToastSuccess("You are a techskins subscriber now!!!");
          setTimeout(() => {
            setDisable(false);
          }, 3000);
          setFormData(subData);
        } else {
          ToastError("Oops! Something went wrong.");
        }
      })
      .catch((e) => {
        if (e.response.status === 400) {
          const errorMessages = e.response.data.message;
          // Iterate through each key in errorMessages object
          Object.keys(errorMessages).forEach((key) => {
            const errorArray = errorMessages[key]; // Array of error messages for the current key
            console.log(`Errors for key "${key}":`, errorArray);

            // Display each error message in a separate alert
            errorArray.forEach((errorMessage) => {
              ToastError(errorMessage);
            });
          });
        } else {
          ToastError("An error occurred. Please try again later.");
        }
      });
  };
  return (
    <footer className="footer mt-5">
      <div className=" pt-3">
        <div className="container">
          <div className="flex-center-between d-block d-md-flex">
            <div className="text-md-right mb-3 text-center">
              <span className="d-inline-block font-color p-1">
                ©{" "}
                <Link
                  to="/"
                  className="font-weight-bold text-gray-100 text-center"
                >
                  TechSkins{" "}
                </Link>
                {currentYear} - All rights Reserved.
              </span>
            </div>
            <div className="news-letter col-md-3 col-12 mb-3">
              <form className="myForm" method="POST" onSubmit={SubmitForm}>
                <input
                  type="email"
                  className="form-control "
                  id="email"
                  name="email"
                  placeholder="Enter Email"
                  required="required"
                  autoComplete="off"
                  value={formData.email}
                  onChange={handleInput}
                />
                <button
                  type="submit"
                  className="btn share_btn btn-depth"
                  disabled={disable ? "disable" : ""}
                >
                  Subscribe
                </button>
              </form>
            </div>
            <div className="col-md-2 col-12 btn-group dropup mb-3 mr-md-10">
              <button
                type="button"
                className="btn btn-secondary dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Links
              </button>
              <div className="dropdown-menu">
                <Link to="/contact-us">
                  <p className="text-left">Contact</p>
                </Link>
                <Link to="/privacy-policy">
                  <p className="text-left">Privacy Policy</p>
                </Link>
                <Link to="/shipping-canceller">
                  <p className="text-left">Shipping Canceller</p>
                </Link>
                <Link to="/terms-and-condition">
                  <p className="text-left">Terms And Condition</p>
                </Link>
              </div>
            </div>
            <div className="col-md-3 col-12 mb-3 connect text-right">
              <ShareButton />
            </div>
          </div>
        </div>
      </div>
      {/* End Footer-copy-right */}

      <li className="list-inline-item dark-toggle">
        <div>
          <input
            type="checkbox"
            onClick={handleToggleMode}
            name="checkbox"
            className="switch"
            checked={isDarkMode}
            readOnly
          />
        </div>
      </li>

      <button
        // style={{ display: isVisible ? "block" : "none" }}
        onClick={scrollToTop}
        id="scrollUp"
        className={`back-to-top d-flex align-items-center justify-content-center ${
          isVisible ? "show active" : ""
        }`}
      >
        <span className="arrow-container">
          <i className="fa fa-angle-up"></i>
        </span>
        <span className="arrow-container">
          <i className="fa fa-angle-up py-2"></i>
        </span>
        <span className="arrow-container">
          <i className="fa fa-angle-up py-3"></i>
        </span>
      </button>
      <a
        href={whatsappLink}
        target="_blank"
        rel="noopener noreferrer"
        className="wtsapp"
      >
        <img
          src={process.env.PUBLIC_URL + "/assets/img/whatsapp.png"}
          alt="whatsapp"
        />
      </a>
    </footer>
  );
};

export default Footer;
