import React, { useContext, useEffect, useState } from "react";
import EachNfc from "./EachNfc";
import Api from "../../helpers/Api";
import PreLoader from "../../components/PreLoader";
import { LoaderContext } from "../../context/LoaderContext";

const NfcList = () => {
  const [products, setProducts] = useState([]);
  const { isLoading, setIsLoading } = useContext(LoaderContext);
  useEffect(() => {
    setIsLoading(true);

    Api.NFCList()
      .then((res) => {
        setProducts(res.data.products);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setIsLoading(false));
  }, [setIsLoading]);
  return (
    <>
      {isLoading ? (
        <PreLoader />
      ) : (
        <div className="container">
          <div className="row each_product_row mt-3">
            {products.map((product) => (
              <EachNfc
                {...product}
                key={product.id}
                // product_name={products?.product_name}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default NfcList;
