import React, { createContext, useState } from 'react';

// Create a new context
export const LoaderContext = createContext();

// Create a context provider component
export const LoaderProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <LoaderContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
    </LoaderContext.Provider>
  );
};
