import React, { useState, useEffect, useMemo, useContext } from "react";
import SliderSection from "./SliderSection";
import EachCarouselSlide from "./EachCarouselSlide";
import EachProductCard from "./EachProductCard";
import Api from "../../helpers/Api";
// import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import PreLoader from "../../components/PreLoader";
import { LoaderContext } from "../../context/LoaderContext";
// import Parallax from "../../components/Parallax";

const Home = () => {
  const { isLoading, setIsLoading } = useContext(LoaderContext);
  const [state, setState] = useState({
    categories: [],
    features: [],
    deals: [],
    dealActiveTab: 0,
    featureActiveTab: 0,
    banners: [],
    slider: {},
    eachSlides: [],
    slideCards: [],
  });

  const handleTabClick = (tab_num, isFeatureTab) => {
    setState({
      ...state,
      [isFeatureTab ? "featureActiveTab" : "dealActiveTab"]: tab_num,
    });
  };
  useEffect(() => {
    setIsLoading(true);
    Api.HomePage()
      .then(({ data }) => {
        setState({
          ...state,
          categories: data.categories,
          features: data.features,
          dealActiveTab: data.categories[0]?.id,
          featureActiveTab: data.categories[0]?.id,
          deals: data.hot_skins,
          banners: data.banners,
          slider: data.slider,
          eachSlides: data.each_slides,
          slideCards: data.slide_cards,
        });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setIsLoading(false));
  }, [setIsLoading]);
  // Memoized value for filtered features based on active tab
  const filteredFeatures = useMemo(() => {
    return state.features.filter(
      (f) => f.category_id === state.featureActiveTab
    );
  }, [state.features, state.featureActiveTab]);
  // Memoized value for filtered deals based on active tab
  const filteredDeals = useMemo(() => {
    return state.deals.filter(
      (deal) => deal.category_id === state.dealActiveTab
    );
  }, [state.deals, state.dealActiveTab]);
  return (
    <>
      {isLoading ? (
        <PreLoader />
      ) : (
        <>
          <SliderSection
            {...state.slider}
            eachSlides={state.eachSlides}
            slideCards={state.slideCards}
          />

          <div className="mb-1 container">
            <div className="row category">
              {/* <Carousel responsive={responsive}> */}
              {state.categories.map((category) => {
                return <EachCarouselSlide key={category.id} {...category} />;
              })}
              {/* </Carousel> */}
            </div>
          </div>
          <div className="container">
            {state.features.length > 0 && (
              <>
                <h3 className="font-color text-center">Featured Products</h3>
                <div className="card-box">
                  <div className="category_tabs ">
                    {state.categories.map((category) => {
                      const categoryFeatures = state.features.filter(
                        (f) => f.category_id === category.id
                      );
                      return (
                        categoryFeatures.length > 0 && (
                          <a
                            key={category.id}
                            className={`tab tab-h ${
                              state.featureActiveTab === category.id
                                ? "active"
                                : ""
                            }`}
                            onClick={() => handleTabClick(category.id, true)}
                          >
                            {category.category_name}
                          </a>
                        )
                      );
                    })}
                  </div>
                  <ul className="row list-unstyled products-group ">
                    {filteredFeatures.map((feature) => (
                      <EachProductCard key={feature.id} {...feature} />
                    ))}
                  </ul>
                </div>
              </>
            )}
            <div className="container">
              {state.banners.map(
                (b) =>
                  b.section_id === "1" && (
                    <div className="banner_box_img" key={b.id}>
                      <Link to={b.banner_link}>
                        <img src={b.banner_img} alt="banners" />
                      </Link>
                    </div>
                  )
              )}
            </div>
            {state.deals.length > 0 && (
              <>
                <h3 className="font-color text-center">HOT DEALS</h3>
                <div className="card-box">
                  <div id="tabs" className="tabs-container">
                    <div className="category_tabs ">
                      {state.categories.map((category) => {
                        const categoryDeals = state.deals.filter(
                          (d) => d.category_id === category.id
                        );
                        return (
                          categoryDeals.length > 0 && (
                            <a
                              key={category.id}
                              className={`tab tab-h ${
                                state.dealActiveTab === category.id
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => handleTabClick(category.id, false)}
                            >
                              {category.category_name}
                            </a>
                          )
                        );
                      })}
                    </div>

                    <div className="content">
                      <ul className="row list-unstyled products-group hot-deal">
                        {filteredDeals // Filter deals based on category_id
                          .map((deal) => (
                            <EachProductCard key={deal.id} {...deal} />
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="container">
              {state.banners.map(
                (b) =>
                  b.section_id === "2" && (
                    <div className="banner_box_img" key={b.id}>
                      <Link to={b.banner_link}>
                        <img src={b.banner_img} alt="banners" />
                      </Link>
                    </div>
                  )
              )}
            </div>
          </div>
        </>
      )}
      {/* <Parallax /> */}
    </>
  );
};

export default Home;
