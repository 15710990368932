import React from 'react';
import { Link } from 'react-router-dom';

const EachCarouselSlide = ({ id, category_name, category_logo }) => {
  // const navigate = useNavigate();
  // const categoryBase = (id) => {
  //   navigate(`/${category_name}`, { state: { id: id } });
  // };
  return (
    <Link to={`/category/${id}`}>
      <div className='col-md-less col-4 mt-2'>
        <div className='cat-slide cat-card'>
          <div className='bg  height-75' style={{ cursor: 'pointer' }}>
            <img
              src={category_logo}
              alt={category_name}
              className='img-fluid'
            />
          </div>

          {/* <div className='bg-white px-2 pt-2 width-122'>
          <h6 className='font-weight-semi-bold font-size-14 text-gray-90 mb-0 text-lh-1dot2'>
            {category_name}
          </h6>
        </div> */}
        </div>
      </div>
    </Link>
  );
};

export default EachCarouselSlide;
