import CustomAxios from "../utils/CustomAxios";

const axiosForJSON = CustomAxios("application/json");
const axiosForMultipart = CustomAxios("multipart/form-data");
const Api = {
  RecoverEmail: (data) => {
    return axiosForJSON.post(`/recover-email`, data);
  },
  ResetPassword: (data) => {
    return axiosForJSON.post(`/reset-password`, data);
  },
  FbLogin: (data) => {
    return axiosForJSON.post(`/fb-login`, data);
  },
  GLogin: (data) => {
    return axiosForJSON.post(`/google-login`, data);
  },
  Login: (data) => {
    return axiosForJSON.post(`/auth/login`, data);
  },
  Register: (data) => {
    return axiosForJSON.post(`/auth/register`, data);
  },
  FaceBookLogin: () => {
    return axiosForJSON.get(`/login/facebook`);
  },
  Logout: () => {
    return axiosForJSON.post(`/logout`);
  },

  Subscriber: (data) => {
    return axiosForJSON.post(`/subscriber`, data);
  },
  Contact: (data) => {
    return axiosForJSON.post(`/contact-mail`, data);
  },
  HomePage: () => {
    return axiosForJSON.get("home-page");
  },
  FilterPage: (category_id) => {
    return axiosForJSON.get(`category-filter-page/${category_id}`);
  },

  getDevicesByBrand: (brand_id) => {
    return axiosForJSON.get(`get-devices-by-brand/${brand_id}`);
  },
  makeFilterPost: (selectedFilters) => {
    const queryParams = new URLSearchParams(selectedFilters);
    return axiosForJSON.get(`/make-filter-post`, {
      params: queryParams,
    });
  },

  DetailPage: (skin_id) => {
    return axiosForJSON.get(`/product-detail/${skin_id}`);
  },
  ProdDetailFilter: (s_id, t_id) => {
    return axiosForJSON.get(`/device-and-theme-based-skins/${s_id}/${t_id}`);
  },
  ProdDetailAddToCart: (data) => {
    return axiosForJSON.post(`/product-detail-add-to-cart`, data);
  },

  CoupleFilterPage: (category_id) => {
    return axiosForJSON.get(`category-couple-filter-page/${category_id}`);
  },

  makeCoupleFilterPost: (selectedFilters) => {
    const queryParams = new URLSearchParams(selectedFilters);
    return axiosForJSON.get(`/make-couple-filter-post`, {
      params: queryParams,
    });
  },
  CouplePreviewPage: (skin_id) => {
    return axiosForJSON.get(`/couple-preview-page/${skin_id}`);
  },
  CoupleSkinAddToCart: (data) => {
    return axiosForJSON.post(`/couple-skin-add-to-cart`, data);
  },

  getRelatedKitBySkin: (skin_id) => {
    return axiosForJSON.get(`get-skin-related-kit/${skin_id}`);
  },
  getSkinMultiImg: (skin_id) => {
    return axiosForJSON.get(`get-skin-related-images/${skin_id}`);
  },
  CustomPage: (device_id) => {
    return axiosForJSON.get(`/product-detail-customize/${device_id}`);
  },
  ProdCustomFilter: (part_id, t_id) => {
    return axiosForJSON.get(`/part-and-theme-based-skins/${part_id}/${t_id}`);
  },
  ProdCustomAddToCart: (data) => {
    return axiosForJSON.post(`/product-custom-add-to-cart`, data);
  },
  CartPage: () => {
    return axiosForJSON.get(`/cart-page`);
  },
  UpdateCart: (data) => {
    return axiosForJSON.post(`/update-cart`, data);
  },
  CartHeader: () => {
    return axiosForJSON.get(`cart-notifications`);
  },
  CheckoutPage: () => {
    return axiosForJSON.get(`/checkout`);
  },
  CouponValidate: (data) => {
    return axiosForJSON.post(`/coupon-validate`, data);
  },
  // PlaceOrder: (data) => {
  //   return axiosForJSON.post(`/place-order`, data);
  // },
  GetPaymentUrl: (data) => {
    return axiosForJSON.post(`/get-payment-url`, data);
  },
  OrdersList: () => {
    return axiosForJSON.get(`/orders-list`);
  },

  NFCList: () => {
    return axiosForJSON.get(`/nfc-list`);
  },
  NFCPreview: (n_id) => {
    return axiosForJSON.get(`/nfc-preview/${n_id}`);
  },
  NFCAddToCart: (data) => {
    return axiosForMultipart.post(`/nfc-add-cart`, data);
  },
  FrameList: () => {
    return axiosForJSON.get(`/frame-list`);
  },
  FramePreview: (photo_frame_id) => {
    return axiosForJSON.get(`/frame-preview/${photo_frame_id}`);
  },
  FrameAddToCart: (data) => {
    return axiosForMultipart.post(`/frame-add-cart`, data);
  },
  // accessories pages api start
  AccessoryCategoryDetail: (c_id) => {
    return axiosForJSON.get(`/accessories-category-detail/${c_id}`);
  },
  AccessoryProductList: (selectedFilters) => {
    const queryParams = new URLSearchParams(selectedFilters);
    return axiosForJSON.get(`/accessories-product-list`, {
      params: queryParams,
    });
  },
  AccessoryProductPreview: (p_id) => {
    return axiosForJSON.get(`/accessories-product-preview/${p_id}`);
  },
  AccessoryProductAddToCart: (data) => {
    return axiosForJSON.post(`/accessories-product-add-cart`, data);
  },
  // accessories pages api end
  // acrylic pages api start
  AcrylicProductList: () => {
    return axiosForJSON.get(`/acrylic-product-list`);
  },
  AcrylicProductPreview: (p_id) => {
    return axiosForJSON.get(`/acrylic-product-preview/${p_id}`);
  },
  AcrylicProductAddToCart: (data) => {
    return axiosForJSON.post(`/acrylic-product-add-cart`, data);
  },
  // acrylic pages api end

  // stickers pages api start
  StickerTypesList: () => {
    return axiosForJSON.get(`/sticker-types-list`);
  },

  TypeBasedStickerFilters: (type_id) => {
    return axiosForJSON.get(`type-based-sticker-filters/${type_id}`);
  },

  TypeBasedStickersList: (selectedFilters) => {
    return axiosForJSON.get(`type-based-stickers-list`, {
      params: selectedFilters,
    });
  },

  StickerProductDetail: (product_id) => {
    return axiosForJSON.get(`sticker_detail/${product_id}`);
  },
  StickerAddToCart: (data) => {
    return axiosForMultipart.post(`/sticker-add-cart`, data);
  },

  // phone cases api
  PhoneCasesTypeList: () => {
    return axiosForJSON.get(`/phone-cases-types-list`);
  },
  TypeBasedPhoneCaseFilters: (type_id) => {
    return axiosForJSON.get(`type-based-phone-case-filters/${type_id}`);
  },
  TypeBasedPhoneCasesList: (selectedFilters) => {
    return axiosForJSON.get(`type-based-phone-cases-list`, {
      params: selectedFilters,
    });
  },
  PhoneCaseProductDetail: (product_id) => {
    return axiosForJSON.get(`phone_case_detail/${product_id}`);
  },
  PhoneCaseAddToCart: (data) => {
    return axiosForMultipart.post(`/phone-case-add-cart`, data);
  },
  // My Account Api
  MyAccount: () => {
    return axiosForJSON.get(`/account-details`);
  },
  ProfileUpdate: (data) => {
    return axiosForJSON.post(`/basic-details-update`, data);
  },
  PasswordUpdate: (data) => {
    return axiosForJSON.post(`/panel-password-change`, data);
  },
};

export default Api;
