import React, { useEffect, useState } from "react";
import { ToastError, ToastSuccess } from "../../../helpers/Toast";
import Api from "../../../helpers/Api";

const ChangePasswordTab = ({ pwd_text }) => {
  const [pwdText, setPwdText] = useState("");
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    ConfirmPassword: "",
  });
  // New state variables for password visibility
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  useEffect(() => {
    setPwdText(pwd_text);
  }, [pwd_text]);
  const handleTogglePasswordVisibility = (field) => {
    // Toggle the visibility state for the specified field
    switch (field) {
      case "oldPassword":
        setShowOldPassword(!showOldPassword);
        break;
      case "newPassword":
        setShowNewPassword(!showNewPassword);
        break;
      case "ConfirmPassword":
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };
  const handleInputField = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const SubmitForm = async (e) => {
    e.preventDefault();

    try {
      const data = new FormData(e.target);
      const validationErrors = [];

      if (!formData.oldPassword) {
        validationErrors.push("Current password is required.");
      }
      if (formData.oldPassword !== pwdText) {
        validationErrors.push("Current password do not match.");
      }

      if (!formData.newPassword) {
        validationErrors.push("New password is required.");
      } else if (formData.newPassword.length < 8) {
        validationErrors.push("New password must be at least 8 characters.");
      }

      if (formData.newPassword !== formData.ConfirmPassword) {
        validationErrors.push(
          "New password and confirm password do not match."
        );
      }
      if (validationErrors.length > 0) {
        validationErrors.forEach((error) => ToastError(error));
        return;
      }
      Api.PasswordUpdate(data).then((response) => {
        if (response.status === 200) {
          ToastSuccess("Password Updated Successfully!!!");
        } else {
          ToastError("Oops! Something went wrong.");
        }
      });

      // Handle the response as needed
    } catch (error) {
      console.error("Error:", error.message);
      ToastError(`Oops! Something went wrong. ${error.message}`);
    }
  };
  return (
    <div className="row">
      <div className="w-100 position-relative overflow-hidden">
        <div className="card-body ">
          {/* <p className="card-subtitle mb-4">
            To change your password please confirm here
          </p> */}
          <form onSubmit={SubmitForm} id="contact-form">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="  mb-4">
                  <label
                    htmlFor="exampleInputPassword1"
                    className="form-label fw-semibold"
                  >
                    Current Password
                  </label>
                  <div className="input-group">
                    <input
                      type={showOldPassword ? "text" : "password"}
                      className="form-control"
                      id="exampleInputPassword1"
                      value={formData.oldPassword || ""}
                      onChange={handleInputField}
                      name="oldPassword"
                      autoComplete="off"
                    />
                    <button
                      type="button"
                      className="btn
                    "
                      onClick={() =>
                        handleTogglePasswordVisibility("oldPassword")
                      }
                    >
                      {showOldPassword ? (
                        <i className="fa fa-eye-slash"></i>
                      ) : (
                        <i className="fa fa-eye"></i>
                      )}
                    </button>
                  </div>
                </div>
                <div className="  mb-4">
                  <label
                    htmlFor="exampleInputPassword2"
                    className="form-label fw-semibold"
                  >
                    New Password
                  </label>
                  <div className="input-group">
                    <input
                      type={showNewPassword ? "text" : "password"}
                      className="form-control"
                      id="exampleInputPassword2"
                      value={formData.newPassword || ""}
                      onChange={handleInputField}
                      name="newPassword"
                      autoComplete="off"
                    />
                    <button
                      type="button"
                      className="btn "
                      onClick={() =>
                        handleTogglePasswordVisibility("newPassword")
                      }
                    >
                      {showNewPassword ? (
                        <i className="fa fa-eye-slash"></i>
                      ) : (
                        <i className="fa fa-eye"></i>
                      )}
                    </button>
                  </div>
                </div>
                <div className=" ">
                  <label
                    htmlFor="exampleInputPassword3"
                    className="form-label fw-semibold"
                  >
                    Confirm Password
                  </label>
                  <div className="input-group">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      className="form-control"
                      id="exampleInputPassword3"
                      value={formData.ConfirmPassword || ""}
                      onChange={handleInputField}
                      name="ConfirmPassword"
                      autoComplete="off"
                    />
                    <button
                      type="button"
                      className="btn "
                      onClick={() =>
                        handleTogglePasswordVisibility("ConfirmPassword")
                      }
                    >
                      {showConfirmPassword ? (
                        <i className="fa fa-eye-slash"></i>
                      ) : (
                        <i className="fa fa-eye"></i>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6 text-center">
                <h5 className="card-title fw-semibold">Change Password</h5>
                <img
                  src="/assets/img/auth-reset-password-illustration-light.png"
                  alt="Password Update"
                  className="img-responsive"
                  style={{ width: "35%", height: "auto" }}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-end mt-4 gap-3">
                <button className="btn theme_flt_btn mr-3" type="submit">
                  Save <i className="fa fa-upload ml-2"></i>
                </button>
                {/* <button className="btn theme_flt_btn text-danger">
                  Cancel
                </button> */}
              </div>
            </div>
          </form>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default ChangePasswordTab;
