import React, { useContext, useEffect, useState } from "react";
import { LoaderContext } from "../../context/LoaderContext";
import Api from "../../helpers/Api";
import PreLoader from "../../components/PreLoader";
import PhoneCaseTypeCard from "./PhoneCaseTypeCard";

const PhoneCaseTypeList = () => {
  const { isLoading, setIsLoading } = useContext(LoaderContext);
  const [types, setTypes] = useState([]);
  const PhoneCasesTypesFetch = () => {
    setIsLoading(true);
    Api.PhoneCasesTypeList()
      .then((res) => {
        setTypes(res.data.types);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    PhoneCasesTypesFetch();
  }, []);
  return (
    <>
      {isLoading ? (
        <PreLoader />
      ) : (
        <div className="container">
          <div className="row each_product_row mt-3">
            {types.map((type) => (
              <PhoneCaseTypeCard {...type} key={type.id} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};
export default PhoneCaseTypeList;
