import React, { useContext, useEffect, useState } from "react";
import CouponAccordion from "./CouponAccordion";
import Api from "../../helpers/Api";
import SkinProduct from "./SkinProduct";
import CommonKitProduct from "./CommonKitProduct";
import DeviceKitProduct from "./DeviceKitProduct";
import BillingDetail from "./BillingDetail";
import ShippingDetail from "./ShippingDetail";
import StickerProduct from "./StickerProduct";
import {
  showConfirmAlert,
  showErrorAlert,
  showSuccessAlert,
} from "../../helpers/SweetAlert";
// import { useNavigate } from 'react-router-dom';
import CustomSkinProduct from "./CustomSkinProduct";
import CoupleSkinProduct from "./CoupleSkinProduct";
// import { ToastError } from '../../helpers/Toast';
import { LoaderContext } from "../../context/LoaderContext";
import PreLoader from "../../components/PreLoader";
import AccessoryProduct from "./AccessoryProduct";
import PhoneCaseProduct from "./PhoneCaseProduct";
import {
  placeOrderDataInit,
  BasicRequiredFields,
  customErrorMessages,
} from "../../const/placeOrderDataInit";
const Checkout = () => {
  // const navigate = useNavigate();
  const [skins, setSkins] = useState([]);
  const [coupon, setCoupon] = useState("");
  const [couponApplied, setCouponApplied] = useState(false);
  const [termAccept, setTermAccept] = useState(false);
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [commonKitsInCart, setCommonKitsInCart] = useState([]);
  const [deviceKitsInCart, setDeviceKitsInCart] = useState([]);
  const [customSkinsInCart, setCustomSkinsInCart] = useState([]);
  const [coupleSkinsInCart, setCoupleSkinsInCart] = useState([]);
  const [accessoryProductInCart, setAccessoryProductInCart] = useState([]);
  const [acrylicProductInCart, setAcrylicProductInCart] = useState([]);
  const [NfcProductInCart, setNfcProductInCart] = useState([]);
  const [FramesProductInCart, setFramesProductInCart] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [stickerProductsInCart, setStickerProductsInCart] = useState([]);
  const [phoneCases, setPhoneCases] = useState([]);
  const { isLoading, setIsLoading } = useContext(LoaderContext);

  const [PlaceOrderData, setPlaceOrderData] = useState(placeOrderDataInit);

  useEffect(() => {
    setIsLoading(true);

    Api.CheckoutPage()
      .then((res) => {
        const {
          skins_in_cart,
          device_kits_in_cart,
          common_kits_in_cart,
          custom_skins_in_cart,
          couple_skins_in_cart,
          accessories_products_in_cart,
          acrylic_products_in_cart,
          nfc_products_in_cart,
          frame_products_in_cart,
          stickers,
          phone_cases,
        } = res.data;
        setSkins(skins_in_cart);
        setCommonKitsInCart(common_kits_in_cart);
        setDeviceKitsInCart(device_kits_in_cart);
        setCustomSkinsInCart(custom_skins_in_cart);
        setCoupleSkinsInCart(couple_skins_in_cart);
        setAccessoryProductInCart(accessories_products_in_cart);
        setAcrylicProductInCart(acrylic_products_in_cart);
        setFramesProductInCart(frame_products_in_cart);
        setNfcProductInCart(nfc_products_in_cart);
        setStickerProductsInCart(stickers);
        setPhoneCases(phone_cases);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setIsLoading(false));
  }, [setIsLoading]);

  // Function to calculate the subtotal based on the "price" field of each object
  const calculateSubtotal = (items) => {
    return items.reduce((total, item) => {
      let itemPrice = 0;

      if (item.sticker) {
        // Accessing the base sticker price
        itemPrice += parseInt(item.sticker.price);

        // Adding additional charges if they exist
        if (item.selected_option_choices.length > 0) {
          item.selected_option_choices.forEach((option_choice) => {
            // Accessing additional charges from selected options
            itemPrice += parseInt(option_choice.sticker_choice_map.price) || 0;
          });
        }
      } else if (item.phone_case) {
        // Accessing the phone case price
        itemPrice += parseInt(item.phone_case.price);
      } else if (item.product) {
        // Accessing the base sticker price
        itemPrice += parseInt(item.product.price);

        // Adding additional charges if they exist
        if (item.selected_option_choices.length > 0) {
          item.selected_option_choices.forEach((option_choice) => {
            // Accessing additional charges from selected options
            itemPrice +=
              parseInt(option_choice.accessory_choice_map.price) || 0;
          });
        }
      } else {
        // If it's a different item type with a regular price structure
        itemPrice += parseInt(item.price) || 0;
      }
      return total + itemPrice * item.quantity;
    }, 0);
  };
  const handleCouponValidate = (e) => {
    // Convert to JSON string
    let postData = {
      coupon: coupon,
    };
    const requestBody = JSON.stringify(postData);
    Api.CouponValidate(requestBody)
      .then((res) => {
        if (res.data.status === 400) {
          setCouponApplied(false);
          setPlaceOrderData((pre) => ({
            ...pre,
            coupon_applied_code: "",
            coupon_applied_id: null,
          }));
          setCouponDiscount(0);
          showErrorAlert(res.data.message);
        } else if (res.data.status === 200) {
          setCouponApplied(true);
          setPlaceOrderData((pre) => ({
            ...pre,
            coupon_applied_code: res.data.coupon.coupon_code,
            coupon_applied_id: res.data.coupon.id,
          }));
          setCouponDiscount(res.data.coupon.discount);
          showSuccessAlert("Coupon Applied  SuccessFully");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleShipDiffer = () => {
    const newIsShippingDiffer = !PlaceOrderData.is_shipping_differ;
    // Clear validation errors for shipping details fields if is_shipping_differ is set to false
    if (!newIsShippingDiffer) {
      const newValidationErrors = { ...validationErrors };
      const shippingFields = [
        "s_name",
        "s_streetAddress",
        "s_cityAddress",
        "s_postcode",
        "s_state",
        "s_email",
        "s_phone",
      ];
      shippingFields.forEach((field) => {
        newValidationErrors[field] = null;
      });
      setValidationErrors(newValidationErrors);
    }

    setPlaceOrderData((prevData) => ({
      ...prevData,
      is_shipping_differ: !prevData.is_shipping_differ,
    }));
  };
  const handlePlaceOrder = (e) => {
    // Validate required fields
    const requiredFields = [...BasicRequiredFields];
    if (PlaceOrderData.is_shipping_differ) {
      requiredFields.push(
        "s_name",
        "s_streetAddress",
        "s_cityAddress",
        "s_postcode",
        "s_state",
        "s_email",
        "s_phone"
      );
    }

    let hasValidationErrors = false;
    const newValidationErrors = {};
    for (const field of requiredFields) {
      if (!PlaceOrderData[field]) {
        const errorMessage = customErrorMessages[field];
        newValidationErrors[field] = errorMessage;
        hasValidationErrors = true;
      }
    }
    setValidationErrors(newValidationErrors);
    if (hasValidationErrors) {
      return;
    }
    // Extracting the necessary data from state variables
    const cartItems = [
      { name: "skins", items: skins },
      { name: "customSkinsInCart", items: customSkinsInCart },
      { name: "coupleSkinsInCart", items: coupleSkinsInCart },
      { name: "commonKitsInCart", items: commonKitsInCart },
      { name: "deviceKitsInCart", items: deviceKitsInCart },
      { name: "accessoryProductInCart", items: accessoryProductInCart },
      { name: "acrylicProductInCart", items: acrylicProductInCart },
      { name: "FramesProductInCart", items: FramesProductInCart },
      { name: "NfcProductInCart", items: NfcProductInCart },
      { name: "stickerProductsInCart", items: stickerProductsInCart },
      { name: "phoneCases", items: phoneCases },
    ];
    const updatedData = {
      ...Object.fromEntries(
        cartItems.map(({ name, items }) => [
          name,
          items.map(({ id, quantity }) => ({ id, quantity })),
        ])
      ),
      ...PlaceOrderData,
    };
    const requestBody = JSON.stringify(updatedData);
    Api.GetPaymentUrl(requestBody)
      .then((response) => {
        console.log(response);
        window.location.href = response.data.payment_url;
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleUpdateQuantity = (itemId, newQuantity, setterFun, items_name) => {
    const updatedData = items_name.map((item) => {
      if (item.id === itemId) {
        return { ...item, quantity: newQuantity };
      }
      return item;
    });
    setterFun(updatedData);
    // setUpdateBtn(true);
  };
  const handleRemoveItem = (itemId, setterFun, items) => {
    showConfirmAlert(
      "Are You Sure To Remove This Item From Cart",
      "Yes",
      "Cancel"
    ).then((result) => {
      if (result.isConfirmed) {
        const updatedItems = items.filter((item) => item.id !== itemId);
        setterFun(updatedItems);
      }
    });
  };
  return (
    <div className="container">
      {isLoading ? (
        <PreLoader />
      ) : (
        <>
          {skins.length +
            customSkinsInCart.length +
            commonKitsInCart.length +
            coupleSkinsInCart.length +
            accessoryProductInCart.length +
            acrylicProductInCart.length +
            FramesProductInCart.length +
            NfcProductInCart.length +
            stickerProductsInCart.length +
            phoneCases.length +
            deviceKitsInCart.length >
          0 ? (
            <>
              <div className="row mt-5">
                <div className="col-lg-6 order-lg-2 mb-7 mb-lg-0">
                  <div className="pl-lg-3 checkout-table">
                    <div className=" rounded-lg">
                      {/* Order Summary */}
                      <div className="py-2 mb-4">
                        {/* Title */}
                        <div className="border-bottom border-color-1">
                          <h3 className="section-title mb-0 pb-2 font-size-25">
                            Order Summary
                          </h3>
                        </div>
                        <div className="checkout-scroll">
                          {skins.map((skin_in_cart) => (
                            <SkinProduct
                              key={skin_in_cart.id}
                              {...skin_in_cart}
                              updateQuantity={(itemId, newQuantity) =>
                                handleUpdateQuantity(
                                  itemId,
                                  newQuantity,
                                  setSkins,
                                  skins
                                )
                              }
                              removeItem={(itemId) =>
                                handleRemoveItem(itemId, setSkins, skins)
                              }
                            />
                          ))}

                          {customSkinsInCart.map((custom_skin_in_cart) => (
                            <CustomSkinProduct
                              key={custom_skin_in_cart.id}
                              {...custom_skin_in_cart}
                              updateQuantity={(itemId, newQuantity) =>
                                handleUpdateQuantity(
                                  itemId,
                                  newQuantity,
                                  setCustomSkinsInCart,
                                  customSkinsInCart
                                )
                              }
                              removeItem={(itemId) =>
                                handleRemoveItem(
                                  itemId,
                                  setCustomSkinsInCart,
                                  customSkinsInCart
                                )
                              }
                            />
                          ))}
                          {coupleSkinsInCart.map((couple_skin_in_cart) => (
                            <CoupleSkinProduct
                              key={couple_skin_in_cart.id}
                              {...couple_skin_in_cart}
                              updateQuantity={(itemId, newQuantity) =>
                                handleUpdateQuantity(
                                  itemId,
                                  newQuantity,
                                  setCoupleSkinsInCart,
                                  coupleSkinsInCart
                                )
                              }
                              removeItem={(itemId) =>
                                handleRemoveItem(
                                  itemId,
                                  setCoupleSkinsInCart,
                                  coupleSkinsInCart
                                )
                              }
                            />
                          ))}

                          {commonKitsInCart.map((common_kit_in_cart) => (
                            <CommonKitProduct
                              key={common_kit_in_cart.id}
                              {...common_kit_in_cart}
                              updateQuantity={(itemId, newQuantity) =>
                                handleUpdateQuantity(
                                  itemId,
                                  newQuantity,
                                  setCommonKitsInCart,
                                  commonKitsInCart
                                )
                              }
                              removeItem={(itemId) =>
                                handleRemoveItem(
                                  itemId,
                                  setCommonKitsInCart,
                                  commonKitsInCart
                                )
                              }
                            />
                          ))}

                          {deviceKitsInCart.map((device_kit_in_cart) => (
                            <DeviceKitProduct
                              key={device_kit_in_cart.id}
                              {...device_kit_in_cart}
                              updateQuantity={(itemId, newQuantity) =>
                                handleUpdateQuantity(
                                  itemId,
                                  newQuantity,
                                  setDeviceKitsInCart,
                                  deviceKitsInCart
                                )
                              }
                              removeItem={(itemId) =>
                                handleRemoveItem(
                                  itemId,
                                  setDeviceKitsInCart,
                                  deviceKitsInCart
                                )
                              }
                            />
                          ))}
                          {accessoryProductInCart.map((product) => (
                            <AccessoryProduct
                              key={product.id}
                              {...product}
                              updateQuantity={(itemId, newQuantity) =>
                                handleUpdateQuantity(
                                  itemId,
                                  newQuantity,
                                  setAccessoryProductInCart,
                                  accessoryProductInCart
                                )
                              }
                              removeItem={(itemId) =>
                                handleRemoveItem(
                                  itemId,
                                  setAccessoryProductInCart,
                                  accessoryProductInCart
                                )
                              }
                            />
                          ))}
                          {acrylicProductInCart.map((product) => (
                            <AccessoryProduct
                              key={product.id}
                              {...product}
                              updateQuantity={(itemId, newQuantity) =>
                                handleUpdateQuantity(
                                  itemId,
                                  newQuantity,
                                  setAcrylicProductInCart,
                                  acrylicProductInCart
                                )
                              }
                              removeItem={(itemId) =>
                                handleRemoveItem(
                                  itemId,
                                  setAcrylicProductInCart,
                                  acrylicProductInCart
                                )
                              }
                            />
                          ))}
                          {FramesProductInCart.map((product) => (
                            <AccessoryProduct
                              key={product.id}
                              {...product}
                              updateQuantity={(itemId, newQuantity) =>
                                handleUpdateQuantity(
                                  itemId,
                                  newQuantity,
                                  setFramesProductInCart,
                                  FramesProductInCart
                                )
                              }
                              removeItem={(itemId) =>
                                handleRemoveItem(
                                  itemId,
                                  setFramesProductInCart,
                                  FramesProductInCart
                                )
                              }
                            />
                          ))}
                          {NfcProductInCart.map((product) => (
                            <AccessoryProduct
                              key={product.id}
                              {...product}
                              updateQuantity={(itemId, newQuantity) =>
                                handleUpdateQuantity(
                                  itemId,
                                  newQuantity,
                                  setNfcProductInCart,
                                  NfcProductInCart
                                )
                              }
                              removeItem={(itemId) =>
                                handleRemoveItem(
                                  itemId,
                                  setNfcProductInCart,
                                  NfcProductInCart
                                )
                              }
                            />
                          ))}
                          {stickerProductsInCart.map((product) => (
                            <StickerProduct
                              key={product.id}
                              {...product}
                              updateQuantity={(itemId, newQuantity) =>
                                handleUpdateQuantity(
                                  itemId,
                                  newQuantity,
                                  setStickerProductsInCart,
                                  stickerProductsInCart
                                )
                              }
                              removeItem={(itemId) =>
                                handleRemoveItem(
                                  itemId,
                                  setAccessoryProductInCart,
                                  accessoryProductInCart
                                )
                              }
                            />
                          ))}
                          {phoneCases.map((product) => (
                            <PhoneCaseProduct
                              key={product.id}
                              {...product}
                              updateQuantity={(itemId, newQuantity) =>
                                handleUpdateQuantity(
                                  itemId,
                                  newQuantity,
                                  setPhoneCases,
                                  phoneCases
                                )
                              }
                              removeItem={(itemId) =>
                                handleRemoveItem(
                                  itemId,
                                  setPhoneCases,
                                  phoneCases
                                )
                              }
                            />
                          ))}
                        </div>
                        {/* End Title */}
                        {/* Product Content */}

                        <div className="px-5 border-top border-color-1">
                          <CouponAccordion
                            coupon={coupon}
                            couponApplied={couponApplied}
                            setCoupon={setCoupon}
                            handleCouponValidate={handleCouponValidate}
                          />
                          <div className="row">
                            <div className="col-md-6 col-6 text-left">
                              <h6>Subtotal</h6>
                            </div>
                            <div className="col-md-6 col-6 text-right">
                              ₹
                              {calculateSubtotal([
                                ...skins,
                                ...customSkinsInCart,
                                ...commonKitsInCart,
                                ...deviceKitsInCart,
                                ...accessoryProductInCart,
                                ...acrylicProductInCart,
                                ...FramesProductInCart,
                                ...NfcProductInCart,
                                ...stickerProductsInCart,
                                ...phoneCases,
                              ]).toFixed(2)}
                            </div>
                          </div>
                          {couponApplied && (
                            <div className="row">
                              <div className="col-md-6 col-6 text-left">
                                <h6>Coupon discount</h6>
                              </div>
                              <div className="col-md-6 col-6 text-right">
                                {calculateSubtotal([
                                  ...skins,
                                  ...customSkinsInCart,
                                  ...commonKitsInCart,
                                  ...deviceKitsInCart,
                                  ...accessoryProductInCart,
                                  ...acrylicProductInCart,
                                  ...FramesProductInCart,
                                  ...NfcProductInCart,
                                  ...stickerProductsInCart,
                                  ...phoneCases,
                                ]).toFixed(2) / couponDiscount}
                              </div>
                            </div>
                          )}

                          <div className="row">
                            <div className="col-md-6 col-6 text-left">
                              <h6>Shipping</h6>
                            </div>
                            <div className="col-md-6 col-6 text-right">
                              <span> ₹ 300.00 </span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-6 text-left">
                              <h6>Total</h6>
                            </div>
                            <div className="col-md-6 col-6 text-right">
                              <strong>
                                ₹
                                {couponApplied
                                  ? (
                                      calculateSubtotal([
                                        ...skins,
                                        ...customSkinsInCart,
                                        ...commonKitsInCart,
                                        ...deviceKitsInCart,
                                        ...accessoryProductInCart,
                                        ...acrylicProductInCart,
                                        ...FramesProductInCart,
                                        ...NfcProductInCart,
                                        ...stickerProductsInCart,
                                        ...phoneCases,
                                      ]) *
                                      (1 - couponDiscount / 100)
                                    ).toFixed(2)
                                  : calculateSubtotal([
                                      ...skins,
                                      ...customSkinsInCart,
                                      ...commonKitsInCart,
                                      ...deviceKitsInCart,
                                      ...accessoryProductInCart,
                                      ...acrylicProductInCart,
                                      ...FramesProductInCart,
                                      ...NfcProductInCart,
                                      ...stickerProductsInCart,
                                      ...phoneCases,
                                    ]).toFixed(2)}
                              </strong>
                            </div>
                          </div>
                        </div>
                        {/* End Product Content */}
                        <div className="border-top border-width-3 border-color-1 pt-3 mb-3"></div>
                        <div
                          className="form-group d-flex align-items-center justify-content-between px-3 "
                          id="customize"
                        >
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              id="defaultCheck10"
                              required=""
                              data-msg="Please agree terms and conditions."
                              data-error-class="u-has-error"
                              data-success-class="u-has-success"
                              onChange={() => setTermAccept((prev) => !prev)}
                            />
                            <label
                              className="form-check-label form-label"
                              htmlFor="defaultCheck10"
                            >
                              <span className="ml-2 font-color">
                                I have read and agree to the website{" "}
                                <a
                                  href="/terms-and-condition"
                                  className="font-color"
                                >
                                  <strong> terms and conditions </strong>
                                </a>
                                <span className="text-danger">*</span>
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="px-5">
                          <button
                            type="button"
                            className="btn btn-primary-dark-w btn-depth btn-block btn-pill font-size-20 mb-3 py-2 odr_btn"
                            disabled={!termAccept}
                            onClick={handlePlaceOrder}
                          >
                            Place order
                            <i className="fa fa-paper-plane font-color ml-2"></i>
                          </button>
                        </div>
                      </div>
                      {/* End Order Summary */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 order-lg-1">
                  <div className="pb-7 mb-7" style={{ padding: "10px" }}>
                    {/* Title */}
                    <div className="border-bottom border-color-1 mb-5">
                      <h3 className="section-title mb-0 pb-2 font-size-25 font-color">
                        Billing details
                      </h3>
                    </div>

                    <BillingDetail
                      placeOrderData={PlaceOrderData}
                      setPlaceOrderData={setPlaceOrderData}
                      setValidationErrors={setValidationErrors}
                      validationErrors={validationErrors}
                    />

                    <div className="border-bottom border-color-1 mb-5">
                      {/* <h3 className='section-title mb-0 pb-2 font-size-25 font-color'>
                    Shipping Details details
                  </h3> */}
                    </div>
                    <div className="col-md-12" id="customize">
                      <div className="form-check " id="shopCartHeadingFour">
                        <input
                          className="form-check-input "
                          type="checkbox"
                          name="is_shipping_differ"
                          id="is_shipping_differ"
                          value={PlaceOrderData.is_shipping_differ}
                          checked={PlaceOrderData.is_shipping_differ}
                          onChange={handleShipDiffer}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="is_shipping_differ"
                          data-toggle="collapse"
                          data-target="#shopCartfour"
                          aria-expanded="false"
                          aria-controls="shopCartfour"
                        >
                          <span className="ml-2">
                            Ship to a different address?
                          </span>
                        </label>
                      </div>
                    </div>

                    <ShippingDetail
                      placeOrderData={PlaceOrderData}
                      setPlaceOrderData={setPlaceOrderData}
                      setValidationErrors={setValidationErrors}
                      validationErrors={validationErrors}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <p>No Data Found</p>
          )}
        </>
      )}
    </div>
  );
};

export default Checkout;
