const EachCustomSkin = ({
  thumbnail_img,
  skin_name,
  reg_price,
  addToCartData,
  part_id,
  skin_img,
  handleSkinSelection,
  handleSkinRemoval,
  id,
}) => {
  const isActiveSkin = addToCartData.part_based_skins[part_id] === id;
  const removeActSkin = () => {
    const element = document.getElementById(`part_bg_${part_id}`);
    if (element) {
      element.style.backgroundImage = `url('')`;
    }
    handleSkinRemoval(part_id);
  };
  const handleApplySkin = () => {
    const element = document.getElementById(`part_bg_${part_id}`);
    if (element) {
      element.style.backgroundImage = `url(${skin_img})`;
    }
    handleSkinSelection(part_id, id);
  };
  return (
    <div className="col-md-2 col-6">
      <div
        className="text-center"
        style={{ width: "100px" }}

      >
        <div className="thumbnail_img">
          <img
            src={thumbnail_img}
            alt={skin_name}
            className={`img-fluid custom_thumb_img ${
              isActiveSkin ? "active_skin" : ""
            }`}
            onClick={handleApplySkin}
          />
          {isActiveSkin ? (
            <span
              className="position-absolute top-0 cart-count start-100 translate-middle rounded-circle"
              onClick={removeActSkin}
            >
              <span className="">
                <i className="fa fa-pencil"></i>
              </span>
            </span>
          ) : (
            ""
          )}
        </div>
        {/* <p>{skin_name}</p> */}
        <h6 className="font-color font-15">₹ {reg_price}</h6>
      </div>
    </div>
  );
};
export default EachCustomSkin;
