import React from "react";

const EachSkinItem = ({
  skin_img,
  model_no,
  reg_price,
  finish_name,
  id,
  quantity,
  updateQuantity, // Function to update the quantity in the parent component
  removeItem, // Function to remove the item from the cart in the parent component
}) => {
  const handleIncrement = () => {
    updateQuantity(id, quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      updateQuantity(id, quantity - 1);
    }
  };

  const handleRemove = () => {
    removeItem(id);
  };
  return (
    <div className='row cart-item-row mb-3'>
      <div className='col-md-2 col-4'>
        <div className='image_box'>
          <img className='img-fluid  p-1 ' src={skin_img} alt={model_no} />
        </div>
      </div>

      <div className='col-md-2 col-4'>
        <div>
          <button className='cart_col_btn btn  btn-depth text-secondary'>
            Model
          </button>
          <p className='cart_col_value'>{model_no}</p>
        </div>
      </div>
      <div className='col-md-2 col-4'>
        <button className='cart_col_btn btn  btn-depth text-secondary'>
          Finish
        </button>
        <p className='cart_col_value'>{finish_name}</p>
      </div>
      <div className='col-md-2 col-4'>
        <button className='cart_col_btn btn  btn-depth text-secondary'>
          Price
        </button>
        <p className='cart_col_value'>₹ {reg_price}</p>
      </div>
      <div className='col-md-2 col-4'>
        <button className='cart_col_btn btn  btn-depth text-secondary'>
          Quantity
        </button>
        {/* Quantity */}

        <div className='js-quantity row align-items-center justify-content-center'>
          <div className='d-flex cart_col_value'>
            <button
              className='qty_btn btn  btn-clicks text-secondary'
              onClick={handleDecrement}
            >
              <small className='fas fa-minus btn-icon__inner' />
            </button>

            <div className='qty_count ml-2'>
              <input
                className='qty_input js-result form-control h-auto border-0 rounded p-0 shadow-none'
                type='text'
                value={quantity}
                readOnly
              />
            </div>
            <button
              className=' ml-2 btn  btn-clicks text-secondary qty_btn'
              onClick={handleIncrement}
            >
              <small className='fas fa-plus btn-icon__inner_1' />
            </button>
          </div>
        </div>

        {/* End Quantity */}
      </div>
      <div className='col-md-2 col-4'>
        <button className='cart_col_btn btn  btn-depth text-secondary'>
          Total
        </button>
        <p className='cart_col_value'>₹ {quantity * reg_price}</p>
        <span
          className='text-gray-32 font-size-26 cart-remove'
          onClick={handleRemove}
        >
          <i className='fa fa-trash-alt'> </i>
        </span>
      </div>
    </div>
  );
};

export default EachSkinItem;
