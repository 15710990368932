export const placeOrderDataInit = {
  b_name: "",
  b_companyName: "",
  b_gst: "",
  b_streetAddress: "",
  b_cityAddress: "",
  b_postcode: "",
  b_state: "",
  b_email: "",
  b_phone: "",
  is_shipping_differ: false,
  s_name: "",
  s_companyName: "",
  s_streetAddress: "",
  s_cityAddress: "",
  s_gst: "",
  s_postcode: "",
  s_state: "",
  s_email: "",
  s_phone: "",
  coupon_applied_code: "",
  coupon_applied_id: null,
};
export const BasicRequiredFields = [
  "b_name",
  "b_streetAddress",
  "b_cityAddress",
  "b_postcode",
  "b_state",
  "b_email",
  "b_phone",
];
// Define custom error messages for each field
export const customErrorMessages = {
  b_name: "Please enter billing name",
  b_streetAddress: "Please enter street address",
  b_cityAddress: "Please enter city address",
  b_postcode: "Please enter postcode",
  b_state: "Please enter state",
  b_email: "Please enter email",
  b_phone: "Please enter phone number",
  s_name: "Please enter shipping name",
  s_streetAddress: "Please enter shipping street address",
  s_cityAddress: "Please enter shipping city address",
  s_postcode: "Please enter shipping postcode",
  s_state: "Please enter shipping state",
  s_email: "Please enter shipping email",
  s_phone: "Please enter shipping phone number",
};
