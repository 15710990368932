import React from "react";

const EachThumbnail = ({
  thumbnail_img,
  skin_name,
  reg_price,
  handlePrevClick,
  activeSkin,
  setActiveSkin,
  id,
}) => {
  const isActiveSkin = activeSkin?.id === id;

  // const removeActSkin = (e) => {
  //   setActiveSkin(!id);
  // };
  return (
    <>
      <div className='col-md-2 col-6'>
        {/* <span className='font-color'>{skin_name}</span> */}
        <div
          style={{ width: '100px' }}
          className='text-center'
          onClick={() => handlePrevClick(id)}
        >
          <div className='thumbnail_img'>
            <img
              src={thumbnail_img}
              alt={skin_name}
              className={`img-fluid ${isActiveSkin ? 'active_skin' : ''}`}
            />
            {/* {isActiveSkin ? (
              <span
                className='position-absolute top-0 cart-count start-100 translate-middle rounded-circle'
                onClick={removeActSkin}
              >
                <span className=''>
                  <i className='fa fa-pencil'></i>
                </span>
              </span>
            ) : (
              ''
            )} */}
          </div>
          {/* <h6 className='text-capitalize'>{skin_name}</h6> */}
          <h6 className='font-color font-15'>₹ {reg_price}</h6>
        </div>
      </div>
    </>
  );
};

export default EachThumbnail;
