import EachSlide from "./EachSlide";
import EachSlideCard from "./EachSlideCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
// https://blog.openreplay.com/creating-a-simple-carousel-with-react-slick/

const SliderSection = ({ main_img, is_full, eachSlides, slideCards }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <>
      {is_full === 1 ? (
        <div className='container overflow-hidden'>
          <div className='row '>
            <div
              className='col-xl pr-xl-2 mb-4 mb-xl-0 col-md-12 col-12'
              style={{ width: '65%' }}
            >
              <Slider {...settings}>
                {eachSlides.map((slide) => (
                  <EachSlide key={slide.id} {...slide} main_img={main_img} />
                ))}
              </Slider>
            </div>
          </div>
        </div>
      ) : (
        // <Slider {...settings}>
        //   {eachSlides.map((slide, index) => (
        //     <div key={index}>
        //       <div
        //         className="bg-img-hero"
        //         style={{ backgroundImage: `url(${main_img})` }}
        //       >
        //         <div className="container overflow-hidden">
        //           <div className="row pt-7 py-md-0">
        //             <div className="d-none d-wd-block offset-1"></div>
        //             <div className="col-xl col col-md-6 mt-md-8 mt-lg-10">
        //               <div className="ml-xl-4">
        //                 <h6
        //                   className="font-size-15 font-weight-bold mb-2 text-cyan"
        //                   data-scs-animation-in="fadeInUp"
        //                 >
        //                   {slide.title}
        //                 </h6>
        //                 <h1
        //                   className="font-size-46 text-lh-50 font-weight-light mb-8"
        //                   data-scs-animation-in="fadeInUp"
        //                   data-scs-animation-delay="200"
        //                 >
        //                   {slide.sub_title}
        //                   <strong className="font-weight-bold">40% OFF</strong>
        //                 </h1>
        //                 <Link
        //                   to={slide.link}
        //                   className="btn btn-primary mb-5 mt-5 transition-3d-hover rounded-lg font-weight-normal py-2 px-md-7 px-3 font-size-16"
        //                   data-scs-animation-in="fadeInUp"
        //                   data-scs-animation-delay="300"
        //                 >
        //                   Start Buying
        //                 </Link>
        //               </div>
        //             </div>
        //             <div
        //               className="col-xl-7 col-6 d-flex align-items-end ml-auto ml-md-0 slides"
        //               data-scs-animation-in="fadeInRight"
        //               data-scs-animation-delay="500"
        //             >
        //               <img
        //                 className="img-fluid ml-auto mr-10 mr-wd-auto"
        //                 src={slide.img_path}
        //                 alt="Description"
        //               />
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   ))}
        // </Slider>
        <div className='container overflow-hidden'>
          <div className='row '>
            {/* pr-xl-2 */}
            <div
              className='col-xl  mb-4 mb-xl-0 col-12 col-md-8 p-4'
              style={{ width: '65%' }}
            >
              <Slider {...settings}>
                {eachSlides.map((slide) => (
                  <EachSlide key={slide.id} {...slide} main_img={main_img} />
                ))}
              </Slider>
            </div>
            <div className='col-xl-auto pl-xl-2 col-md-4'>
              <div className='overflow-hidden'>
                <ul className='list-unstyled row flex-nowrap flex-xl-wrap overflow-auto overflow-lg-visble mx-n2 mx-xl-0 d-xl-block mb-0 mt-1'>
                  {slideCards.map((card) => (
                    <EachSlideCard key={card.id} {...card} />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SliderSection;
