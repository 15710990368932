import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Api from '../../helpers/Api';
import { ToastError, ToastSuccess } from '../../helpers/Toast';

const ResetPassword = () => {
  const { token } = useParams();
  const resetData = {
    token: token,
    password: '',
    password_confirmation: '',
  };
  const [resetPswrd, setResetPswrd] = useState(resetData);
  const [validationErrors, setValidationErrors] = useState({});
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setResetPswrd({
      ...resetPswrd,
      [name]: value,
    });
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
  };
  const handleReset = (e) => {
    const requiredFields = ['password', 'password_confirmation'];
    // Define custom error messages for each field
    const customErrorMessages = {
      password: 'Please enter password',
      password_confirmation: 'Please re-enter password',
    };
    let hasValidationErrors = false;
    const newValidationErrors = {};
    for (const field of requiredFields) {
      if (!resetPswrd[field]) {
        const errorMessage = customErrorMessages[field];
        newValidationErrors[field] = errorMessage;
        hasValidationErrors = true;
      }
    }
    if (resetPswrd.password.length < 6) {
      newValidationErrors.password =
        'Password must be at least 6 characters long';
    }
    // / Additional validation: check if passwords match
    if (resetPswrd.password !== resetPswrd.password_confirmation) {
      newValidationErrors.password_confirmation = "Passwords don't match";
      hasValidationErrors = true;
    }
    setValidationErrors(newValidationErrors);
    if (hasValidationErrors) {
      return;
    }
    e.preventDefault();

    const requestBody = JSON.stringify(resetPswrd);
    Api.ResetPassword(requestBody)

      .then((res) => {
        if (res.data.status === 200) {
          ToastSuccess('Password Reset SuccessFully');
          setTimeout(() => {
            window.location.href = '/';
          }, 6000);
        } else {
          ToastError(res.data.error);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <div className='container'>
      <div className='row'>
        {/* <div className='d-none d-lg-flex col-lg-7 p-0'> */}
        <div className='d-flex justify-content-center align-items-center col-md-8'>
          <img
            src={
              process.env.PUBLIC_URL +
              '/assets/img/auth-reset-password-illustration-light.png'
            }
            alt='auth-reset-password-cover'
            className='img-fluid my-5 auth-illustration'
            style={{ width: '300px' }}
          />
        </div>
        {/* </div> */}
        <div className='col-md-4 p-0'>
          <header className='text-center mb-7'>
            <h2 className='font-color'>TechSkins</h2>
            <h2 className='h4 mb-0 font-color'>Reset Password</h2>
            <p className='font-color'>
              Enter new password for your Techskins account.
            </p>
          </header>
          {/* <form> */}
          <div className='form-group'>
            <div className='js-form-message js-focus-state'>
              <div className='slide_field'>
                <span className='fas fa-lock' />
                <input
                  type='password'
                  className='form-control'
                  name='password'
                  id='signupPassword'
                  placeholder='Password'
                  aria-label='Password'
                  aria-describedby='signupPasswordLabel'
                  required
                  data-msg='Your password is invalid. Please try again.'
                  data-error-class='u-has-error'
                  data-success-class='u-has-success'
                  value={resetPswrd.password}
                  onChange={handleInputChange}
                />
                <label className='sr-only' htmlFor='signupPassword'>
                  Password
                </label>
              </div>
              {validationErrors.password && (
                <p className='text-left text-danger'>
                  {validationErrors.password}
                </p>
              )}
            </div>
          </div>

          <div className='form-group'>
            <div className='js-form-message js-focus-state'>
              <div className='slide_field'>
                <span className='fas fa-key' />
                <input
                  type='password'
                  className='form-control'
                  name='password_confirmation'
                  id='signupConfirmPassword'
                  placeholder='Confirm Password'
                  aria-label='Confirm Password'
                  aria-describedby='signupConfirmPasswordLabel'
                  required
                  data-msg='Password does not match the confirm password.'
                  data-error-class='u-has-error'
                  data-success-class='u-has-success'
                  value={resetPswrd.password_confirmation}
                  onChange={handleInputChange}
                />
                <label className='sr-only' htmlFor='signupConfirmPassword'>
                  Confirm Password
                </label>
              </div>
              {validationErrors.password_confirmation && (
                <p className='text-left text-danger'>
                  {validationErrors.password_confirmation}
                </p>
              )}
            </div>
          </div>
          <div className='mb-2'>
            <button
              type='button'
              className='btn btn-block btn-depth btn-sm lgn_btn transition-3d-hover'
              onClick={handleReset}
            >
              Reset Password
            </button>
          </div>
          {/* </form> */}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
