import React, { useContext } from "react";
import Select from "react-select";
import { ThemeModeContext } from "../../context/ThemeModeContext";
import { lightTheme, darkTheme } from "../../components/SelectTheme";
const ShippingDetail = ({
  placeOrderData,
  setPlaceOrderData,
  setValidationErrors,
  validationErrors,
}) => {
  const { isDarkMode } = useContext(ThemeModeContext);
  const states = [
    {
      value: "Tamil Nadu",
      label: `Tamil Nadu`,
    },
  ];
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPlaceOrderData((prevData) => ({ ...prevData, [name]: value }));
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
  };
  const handleStateChange = (selectOption) => {
    setPlaceOrderData((pre) => ({
      ...pre,
      s_state: selectOption?.label,
    }));
    setValidationErrors((prevErrors) => ({ ...prevErrors, s_state: null }));
  };
  return (
    <div id='shopCartAccordion3' className='accordion rounded mb-5'>
      {/* Card */}
      <div className='card border-0'>
        {/* <div
          id='shopCartHeadingFour'
          className='custom-control custom-checkbox d-flex align-items-center'
        >
          <input
            type='checkbox'
            className='custom-control-input'
            id='is_shipping_differ'
            name='is_shipping_differ'
            checked={placeOrderData.is_shipping_differ}
            onChange={handleInputChange}
          />
          <label
            className='custom-control-label form-label'
            htmlFor='is_shipping_differ'
            data-toggle='collapse'
            data-target='#shopCartfour'
            aria-expanded='false'
            aria-controls='shopCartfour'
          >
            Ship to a different address?
          </label>
        </div> */}
        <div
          id='shopCartfour'
          className='collapse mt-5'
          aria-labelledby='shopCartHeadingFour'
          data-parent='#shopCartAccordion3'
        >
          {/* Shipping Form */}
          <div className='row' id='b-details'>
            <div className='col-md-6'>
              {/* Input */}
              <div className='js-form-message'>
                <label className='form-label'>
                  Name
                  <span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  className='form-control'
                  name='s_name'
                  // placeholder="Jack"
                  aria-label='Jack'
                  required=''
                  data-msg='Please enter your  name.'
                  data-error-class='u-has-error'
                  data-success-class='u-has-success'
                  autoComplete='off'
                  value={placeOrderData.s_name}
                  onChange={handleInputChange}
                />
                {validationErrors.s_name && (
                  <p className='err-text text-danger'>
                    {validationErrors.s_name}
                  </p>
                )}
              </div>
              {/* End Input */}
            </div>
            <div className='col-md-6'>
              <div className='js-form-message'>
                <label className='form-label'>
                  Email address
                  <span className='text-danger'>*</span>
                </label>
                <input
                  type='email'
                  className='form-control'
                  name='s_email'
                  // placeholder="jackwayley@gmail.com"
                  aria-label='jackwayley@gmail.com'
                  required=''
                  data-msg='Please enter a valid email address.'
                  data-error-class='u-has-error'
                  data-success-class='u-has-success'
                  value={placeOrderData.s_email}
                  onChange={handleInputChange}
                />
                {validationErrors.s_email && (
                  <p className='err-text text-danger'>
                    {validationErrors.s_email}
                  </p>
                )}
              </div>
            </div>
            <div className='col-md-12'>
              {/* Input */}
              <div className='js-form-message'>
                <label className='form-label'>Phone</label>
                <input
                  type='text'
                  name='s_phone'
                  className='form-control'
                  // placeholder="+1 (062) 109-9222"
                  aria-label='+1 (062) 109-9222'
                  data-msg='Please enter your last name.'
                  data-error-class='u-has-error'
                  data-success-class='u-has-success'
                  value={placeOrderData.s_phone}
                  onChange={handleInputChange}
                />
                {validationErrors.s_phone && (
                  <p className='err-text text-danger'>
                    {validationErrors.s_phone}
                  </p>
                )}
              </div>
              {/* End Input */}
            </div>
            <div className='w-100' />

            <div className='col-md-6'>
              {/* Input */}
              <div className='js-form-message'>
                <label className='form-label'>Company name (optional)</label>
                <input
                  type='text'
                  className='form-control'
                  name='s_companyName'
                  // placeholder="Company Name"
                  aria-label='Company Name'
                  data-msg='Please enter a company name.'
                  data-error-class='u-has-error'
                  data-success-class='u-has-success'
                  value={placeOrderData.s_companyName}
                  onChange={handleInputChange}
                />
              </div>
              {/* End Input */}
            </div>
            <div className='col-md-6'>
              {/* Input */}
              <div className='js-form-message'>
                <label className='form-label'>GST (optional)</label>
                <input
                  type='text'
                  className='form-control'
                  name='s_gst'
                  // placeholder="Enter GST Number"
                  data-error-class='u-has-error'
                  data-success-class='u-has-success'
                  value={placeOrderData.s_gst}
                  onChange={handleInputChange}
                />
              </div>
              {/* End Input */}
            </div>
            <div className='col-md-6'>
              {/* Input */}
              <div className='js-form-message'>
                <label className='form-label'>
                  Street address
                  <span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  className='form-control'
                  name='s_streetAddress'
                  // placeholder="470 Lucy Forks"
                  aria-label='470 Lucy Forks'
                  required=''
                  data-msg='Please enter a valid address.'
                  data-error-class='u-has-error'
                  data-success-class='u-has-success'
                  value={placeOrderData.s_streetAddress}
                  onChange={handleInputChange}
                />
                {validationErrors.s_streetAddress && (
                  <p className='err-text text-danger'>
                    {validationErrors.s_streetAddress}
                  </p>
                )}
              </div>
              {/* End Input */}
            </div>
            <div className='col-md-6'>
              <div
                className='js-form-message form-group'
                style={{ color: '#495057 !important' }}
              >
                <label className='form-label'>
                  State
                  <span className='text-danger'>*</span>
                </label>
                <Select
                  options={states}
                  placeholder=''
                  className='service_select'
                  name='s_state'
                  required
                  styles={isDarkMode ? darkTheme : lightTheme}
                  value={placeOrderData.s_state}
                  onChange={handleStateChange}
                />
                {validationErrors.s_state && (
                  <p className='err-text text-danger'>
                    {validationErrors.s_state}
                  </p>
                )}
              </div>
            </div>
            <div className='col-md-6'>
              {/* Input */}
              <div className='js-form-message'>
                <label className='form-label'>
                  City
                  <span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  className='form-control'
                  name='s_cityAddress'
                  // placeholder="London"
                  aria-label='London'
                  required=''
                  data-msg='Please enter a valid address.'
                  data-error-class='u-has-error'
                  data-success-class='u-has-success'
                  autoComplete='off'
                  value={placeOrderData.s_cityAddress}
                  onChange={handleInputChange}
                />
                {validationErrors.s_cityAddress && (
                  <p className='err-text text-danger'>
                    {validationErrors.s_cityAddress}
                  </p>
                )}
              </div>
              {/* End Input */}
            </div>
            <div className='col-md-6'>
              {/* Input */}
              <div className='js-form-message'>
                <label className='form-label'>
                  Postcode/Zip
                  <span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  className='form-control'
                  name='s_postcode'
                  // placeholder={99999}
                  aria-label='99999'
                  required=''
                  data-msg='Please enter a postcode or zip code.'
                  data-error-class='u-has-error'
                  data-success-class='u-has-success'
                  value={placeOrderData.s_postcode}
                  onChange={handleInputChange}
                />
                {validationErrors.s_postcode && (
                  <p className='err-text text-danger'>
                    {validationErrors.s_postcode}
                  </p>
                )}
              </div>
              {/* End Input */}
            </div>
            <div className='w-100' />
          </div>
        </div>
      </div>
      {/* End Card */}
    </div>
  );
};

export default ShippingDetail;
