import React, { useContext } from "react";
import Select from "react-select";
import { ThemeModeContext } from "../../context/ThemeModeContext";
import { lightTheme, darkTheme } from "../../components/SelectTheme";
const BillingDetail = ({
  placeOrderData,
  setPlaceOrderData,
  setValidationErrors,
  validationErrors,
}) => {
  const { isDarkMode } = useContext(ThemeModeContext);
  const states = [
    {
      value: "Tamil Nadu",
      label: `Tamil Nadu`,
    },
  ];
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPlaceOrderData((prevData) => ({ ...prevData, [name]: value }));
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
  };
  const handleStateChange = (selectOption) => {
    setPlaceOrderData((pre) => ({
      ...pre,
      b_state: selectOption?.label,
    }));
    setValidationErrors((prevErrors) => ({ ...prevErrors, b_state: null }));
  };
  return (
    <div className="row" id="b-details">
      <div className="col-md-6">
        {/* Input */}
        <div className="js-form-message">
          <label className="form-label">
            Name
            <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            name="b_name"
            // // placeholder='Jack'
            aria-label="Jack"
            required=""
            data-msg="Please enter your  name."
            data-error-class="u-has-error"
            data-success-class="u-has-success"
            autoComplete="off"
            value={placeOrderData.b_name}
            onChange={handleInputChange}
          />
          {validationErrors.b_name && (
            <p className="err-text text-danger">{validationErrors.b_name}</p>
          )}
        </div>
        {/* End Input */}
      </div>
      <div className="col-md-6">
        <div className="js-form-message">
          <label className="form-label">
            Email address
            <span className="text-danger">*</span>
          </label>
          <input
            type="email"
            className="form-control"
            name="b_email"
            // placeholder='jackwayley@gmail.com'
            aria-label="jackwayley@gmail.com"
            required=""
            data-msg="Please enter a valid email address."
            data-error-class="u-has-error"
            data-success-class="u-has-success"
            value={placeOrderData.b_email}
            onChange={handleInputChange}
          />
          {validationErrors.b_email && (
            <p className="err-text text-danger">{validationErrors.b_email}</p>
          )}
        </div>
      </div>
      <div className="col-md-12">
        {/* Input */}
        <div className="js-form-message">
          <label className="form-label">Phone</label>
          <input
            type="text"
            name="b_phone"
            className="form-control"
            // placeholder='+1 (062) 109-9222'
            aria-label="+1 (062) 109-9222"
            data-msg="Please enter your last name."
            data-error-class="u-has-error"
            data-success-class="u-has-success"
            value={placeOrderData.b_phone}
            onChange={handleInputChange}
          />
          {validationErrors.b_phone && (
            <p className="err-text text-danger">{validationErrors.b_phone}</p>
          )}
        </div>
        {/* End Input */}
      </div>
      <div className="w-100" />

      <div className="col-md-6">
        {/* Input */}
        <div className="js-form-message">
          <label className="form-label">Company name (optional)</label>
          <input
            type="text"
            className="form-control"
            name="b_companyName"
            // placeholder='Company Name'
            aria-label="Company Name"
            data-msg="Please enter a company name."
            data-error-class="u-has-error"
            data-success-class="u-has-success"
            value={placeOrderData.b_companyName}
            onChange={handleInputChange}
          />
        </div>
        {/* End Input */}
      </div>
      <div className="col-md-6">
        {/* Input */}
        <div className="js-form-message">
          <label className="form-label">GST (optional)</label>
          <input
            type="text"
            className="form-control"
            name="b_gst"
            // placeholder='Enter GST Number'
            data-error-class="u-has-error"
            data-success-class="u-has-success"
            value={placeOrderData.b_gst}
            onChange={handleInputChange}
          />
        </div>
        {/* End Input */}
      </div>
      <div className="col-md-6">
        {/* Input */}
        <div className="js-form-message">
          <label className="form-label">
            Street address
            <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            name="b_streetAddress"
            // placeholder='470 Lucy Forks'
            aria-label="470 Lucy Forks"
            required=""
            data-msg="Please enter a valid address."
            data-error-class="u-has-error"
            data-success-class="u-has-success"
            value={placeOrderData.b_streetAddress}
            onChange={handleInputChange}
          />
          {validationErrors.b_streetAddress && (
            <p className="err-text text-danger">
              {validationErrors.b_streetAddress}
            </p>
          )}
        </div>
        {/* End Input */}
      </div>
      <div className="col-md-6">
        <div
          className="js-form-message form-group"
          style={{ color: "#495057 !important" }}
        >
          <label className="form-label">
            State
            <span className="text-danger">*</span>
          </label>
          <Select
            options={states}
            placeholder=""
            className="service_select"
            name="b_state"
            required
            styles={isDarkMode ? darkTheme : lightTheme}
            onChange={handleStateChange}
          />
          {validationErrors.b_state && (
            <p className="err-text text-danger">{validationErrors.b_state}</p>
          )}
        </div>
      </div>
      <div className="col-md-6">
        {/* Input */}
        <div className="js-form-message">
          <label className="form-label">
            City
            <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            name="b_cityAddress"
            // placeholder='London'
            aria-label="London"
            required=""
            data-msg="Please enter a valid address."
            data-error-class="u-has-error"
            data-success-class="u-has-success"
            autoComplete="off"
            value={placeOrderData.b_cityAddress}
            onChange={handleInputChange}
          />
          {validationErrors.b_cityAddress && (
            <p className="err-text text-danger">
              {validationErrors.b_cityAddress}
            </p>
          )}
        </div>
        {/* End Input */}
      </div>
      <div className="col-md-6">
        {/* Input */}
        <div className="js-form-message">
          <label className="form-label">
            Postcode/Zip
            <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            name="b_postcode"
            // placeholder={99999}
            aria-label="99999"
            required=""
            data-msg="Please enter a postcode or zip code."
            data-error-class="u-has-error"
            data-success-class="u-has-success"
            value={placeOrderData.b_postcode}
            onChange={handleInputChange}
          />
          {validationErrors.b_postcode && (
            <p className="err-text text-danger">
              {validationErrors.b_postcode}
            </p>
          )}
        </div>
        {/* End Input */}
      </div>
      <div className="w-100" />
    </div>
  );
};

export default BillingDetail;
