import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Api from "../../../helpers/Api";
import EachAccessoryProduct from "./EachAccessoryProduct";
import { LoaderContext } from "../../../context/LoaderContext";
import PreLoader from "../../../components/PreLoader";
import { ThemeModeContext } from "../../../context/ThemeModeContext";
import { darkTheme, lightTheme } from "../../../components/SelectTheme";
import Select from "react-select";
const AccessoryList = () => {
  const { AccessoryID } = useParams();
  const { isDarkMode } = useContext(ThemeModeContext);
  const { isLoading, setIsLoading } = useContext(LoaderContext);
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState({});
  const [types, setTypes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [devices, setDevices] = useState([]);
  const filterInitial = {
    type_id: null,
    brand_id: null,
    device_id: null,
    category_id: AccessoryID,
  };
  const [selectedFilters, setSelectedFilters] = useState(filterInitial);
  useEffect(() => {
    setIsLoading(true);
    setSelectedFilters(filterInitial);
    Api.AccessoryCategoryDetail(AccessoryID)
      .then((res) => {
        // setProducts(res.data.products);
        setCategory(res.data.category);
        setTypes(
          res.data.types.map((t) => ({
            value: t.id,
            label: t.type_name,
          }))
        );
        setBrands(
          res.data.brands.map((b) => ({
            value: b.id,
            label: b.brand_name,
          }))
        );
        setDevices(
          res.data.devices.map((d) => ({
            value: d.id,
            label: d.model_no,
          }))
        );
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setIsLoading(false));
  }, [AccessoryID]);
  useEffect(() => {
    // console.log("yes");
    setIsLoading(true);
    Api.AccessoryProductList(selectedFilters)
      .then((res) => {
        setProducts(res.data.products);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setIsLoading(false));
  }, [selectedFilters, setIsLoading]);
  const handleTypeChange = (selectedOption) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      type_id: selectedOption?.value,
    }));
  };
  const handleBrandChange = (selectedOption) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,

      brand_id: selectedOption?.value,
    }));
  };
  const handleDeviceChange = (selectedOption) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      device_id: selectedOption?.value,
    }));
  };
  return (
    <>
      {isLoading ? (
        <PreLoader />
      ) : (
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-2 col-6  my-2">
              <Select
                options={types}
                placeholder="Select Type"
                onChange={handleTypeChange}
                styles={isDarkMode ? darkTheme : lightTheme}
              />
            </div>
            {category.is_skin_related === 1 && (
              <>
                {" "}
                <div className="col-md-2 col-6  my-2">
                  <Select
                    options={brands}
                    placeholder="Select Brand"
                    onChange={handleBrandChange}
                    styles={isDarkMode ? darkTheme : lightTheme}
                  />
                </div>
                <div className="col-md-2 col-6  my-2">
                  <Select
                    options={devices}
                    placeholder="Select Device"
                    onChange={handleDeviceChange}
                    styles={isDarkMode ? darkTheme : lightTheme}
                  />
                </div>
              </>
            )}
          </div>
          <div className="row each_product_row mt-3">
            {products.map((product) => (
              <EachAccessoryProduct
                {...product}
                key={product.id}
                category_name={category?.category_name}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default AccessoryList;
