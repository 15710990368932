import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LoaderContext } from "../../context/LoaderContext";
import { ThemeModeContext } from "../../context/ThemeModeContext";
import Api from "../../helpers/Api";
import PreLoader from "../../components/PreLoader";
import Select from "react-select";
import { darkTheme, lightTheme } from "../../components/SelectTheme";
import EachPhoneCaseProduct from "./EachPhoneCaseProduct";

const PhoneCasesList = () => {
  const { TypeId } = useParams();
  const [resetCounter, setResetCounter] = useState(0);

  const { isLoading, setIsLoading } = useContext(LoaderContext);
  const filterInitial = {
    type_id: TypeId,
    brand_id: null,
    device_id: null,
  };
  const { isDarkMode } = useContext(ThemeModeContext);
  const [selectedFilters, setSelectedFilters] = useState(filterInitial);
  const [brands, setBrands] = useState([]);
  const [devices, setDevices] = useState([]);
  const [brandDevices, setBrandDevices] = useState([]);
  const [products, setProducts] = useState([]);
  const FetchCaseFiltersData = (TypeId) => {
    setIsLoading(true);
    Api.TypeBasedPhoneCaseFilters(TypeId)
      .then((res) => {
        setBrands(
          res.data.brands.map((b) => ({
            value: b.id,
            label: b.brand_name,
          }))
        );
        setDevices(res.data.devices);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    FetchCaseFiltersData(TypeId);
  }, [TypeId]);

  const fetchPhoneCases = (selectedFilters) => {
    // setIsLoading(true);
    Api.TypeBasedPhoneCasesList(selectedFilters)
      .then((res) => {
        setProducts(res.data.products);
      })
      .catch((e) => {
        console.log(e);
      });
    // .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    fetchPhoneCases(selectedFilters);
  }, [selectedFilters, TypeId]);
  const handleBrandChange = (selectedOption) => {
    const brandId = selectedOption.value;
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      brand_id: brandId,
      device_id: null,
    }));
    const FilteredDevices = devices.filter(
      (d) => parseInt(d.brand_id) === brandId
    );
    setBrandDevices(
      FilteredDevices.map((d) => ({ value: d.id, label: d.model_no }))
    );
  };
  const handleDeviceChange = (selectedOption) => {
    setSelectedFilters((prev) => ({
      ...prev,
      device_id: selectedOption.value,
    }));
  };
  return (
    <>
      {isLoading ? (
        <PreLoader />
      ) : (
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4 col-6  my-2">
              <p className="text-start font-color p_head ">Brands</p>
              <Select
                options={brands}
                placeholder="Select Brand"
                onChange={handleBrandChange}
                styles={isDarkMode ? darkTheme : lightTheme}
                key={`reset_${resetCounter}`}
              />
            </div>
            <div className="col-md-4 col-6  my-2">
              <p className="text-start font-color p_head ">Devices</p>
              <Select
                options={brandDevices}
                placeholder="Select Device"
                onChange={handleDeviceChange}
                styles={isDarkMode ? darkTheme : lightTheme}
                key={`reset_${resetCounter}`}
              />
            </div>
          </div>
          <div className="row each_product_row mt-3">
            {products.map((product) => (
              <EachPhoneCaseProduct {...product} key={product.id} />
            ))}
            {products.length < 1 && <p className="text-center">Not Found</p>}
          </div>
        </div>
      )}
    </>
  );
};

export default PhoneCasesList;
