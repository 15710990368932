import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Slide } from "react-slideshow-image";
import Api from "../../helpers/Api";
import { ToastError, ToastSuccess } from "../../helpers/Toast";
const NfcPreview = () => {
  const proprietes = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    arrows: false,
  };
  const { NfcId } = useParams();

  const [nfcCard, setNfcCard] = useState("");
  const [nfcMultiImages, setNfcMultiImages] = useState([]);
  const [nfcFields, setNfcFields] = useState([]);
  // const [formData, setFormData] = useState({});
  useEffect(() => {
    Api.NFCPreview(NfcId)
      .then((res) => {
        const { pro_multi_img, product, fields } = res.data;
        setNfcMultiImages(pro_multi_img);
        setNfcCard(product);
        setNfcFields(fields);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [NfcId]);
  // useEffect(() => {
  //   // Create a new formData object based on nfcFields whenever it changes
  //   const newFormData = nfcFields.reduce((accumulator, each_fields) => {
  //     const field_name = each_fields.field_name
  //       ?.replace(/\s/g, "-")
  //       .toLowerCase();
  //     accumulator[field_name] = "";
  //     return accumulator;
  //   }, {});

  //   setFormData(newFormData);
  // }, [nfcFields]);
  // const handleField = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //     price: nfcCard.price,
  //   });
  // };
  // File Name append in Choose file field
  const [fileNames, setFileNames] = useState({});
  const handleFileField = (e) => {
    const { name, files } = e.target;
    const file = files[0]; // Assuming only one file is allowed
    // Update the file name in the state
    setFileNames({
      ...fileNames,
      [name]: file ? file.name : "", // Set the file name or empty string if no file is selected
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Create a new FormData object from the form element
    const formSubmitData = new FormData(e.target);
    // Append the price from nfcCard
    formSubmitData.append("nfc_id", NfcId);

    try {
      const response = await Api.NFCAddToCart(formSubmitData);
      // console.log(response);
      if (response.status === 200) {
        ToastSuccess("Product Added to cart successfully!!!");
        formSubmitData.delete("nfc_id");
        e.target.reset();
        setFileNames({});
        setTimeout(() => {
          window.location.href = "/cart";
        }, 6000);
      } else {
        ToastError("Oops! Something went wrong.");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="container" id="nfc">
      <h3 className="font-color text-left">{nfcCard.product_name}</h3>
      <div className="row">
        <div className="col-md-5 col-12 pro_det_step mt-2">
          <div className="pro_det_img slide-container">
            {nfcMultiImages.length > 0 && (
              <Slide {...proprietes}>
                {nfcMultiImages.map((each_img) => (
                  <div className="each-slide-effect" key={each_img.id}>
                    <img src={each_img.img_path} alt="Skin Images" />
                  </div>
                ))}
              </Slide>
            )}
          </div>
          <div className="reltd_pro d-none">
            <div className="row">
              <div className="col-md-2 col-6">
                {/* <span className='font-color'>red</span> */}
                <div
                  style={{ width: "100px" }}
                  className="text-center"
                  //   onClick={() => handlePrevClick(id)}
                >
                  <div className="thumbnail_img">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/img/techskins_logo.png"
                      }
                      alt="skin"
                      // className={`img-fluid ${isActiveSkin ? 'active_skin' : ''}`}
                      className={`img-fluid active_skin`}
                    />
                    {/* {isActiveSkin ? ( */}
                    <span
                      className="position-absolute top-0 cart-count start-100 translate-middle rounded-circle"
                      // onClick={removeActSkin}
                    >
                      <span className="">
                        <i className="fa fa-pencil"></i>
                      </span>
                    </span>
                    {/* ) : (
                      ''
                    )} */}
                  </div>
                  {/* <h6 className='text-capitalize'>{skin_name}</h6> */}
                  <h6 className="font-color font-15">₹ 111</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-7 col-12">
          <div className="skin_box scroll p-5">
            <h4 className="font-color text-center pt-5">
              <strong>
                Please enter the details to be printed on the card
              </strong>
              {/* // 1=text,2=image,3=email,4=mobile */}
            </h4>
            <form
              encType="multipart/form-data"
              method="post"
              onSubmit={handleFormSubmit}
            >
              <div className="col-md-12 mx-md-5  my-md-5">
                <h6 className="font-color font-17">
                  Card Type :
                  <span>
                    <strong>{nfcCard.type_name}</strong>
                  </span>
                </h6>
              </div>
              <div className="row d-flex align-item-center mx-md-5  my-md-5">
                {nfcFields.map((each_fields) => {
                  const field_name = each_fields.field_name
                    ?.replace(/\s/g, "-")
                    .toLowerCase();
                  const field_store_name = `field_${each_fields.field_id}`;
                  return (
                    <div
                      className="col-md-6 col-12 nfc-field"
                      key={each_fields.id}
                    >
                      {each_fields.field_type !== 2 ? (
                        <div className="form-group">
                          <label htmlFor={field_name}>
                            {each_fields.field_name}
                          </label>
                          <input
                            type={
                              each_fields.field_type === 4
                                ? "number"
                                : each_fields.field_type === 3
                                ? "email"
                                : "text"
                            }
                            className="form-control p-3"
                            name={field_store_name}
                            id={field_name}
                            required={each_fields.is_required === 1}
                            // value={
                            //   formData[field_name] ? formData[field_name] : ''
                            // }
                            placeholder={each_fields.field_name}
                            // onChange={handleField}
                          />
                        </div>
                      ) : (
                        <div
                          className="slide_field custom-file"
                          id="nfc-img-field"
                        >
                          <input
                            type="file"
                            className="custom-file-input p-3"
                            name={field_store_name}
                            id={`customFile_${each_fields.id}`}
                            required={each_fields.is_required === 1}
                            placeholder={each_fields.field_name}
                            onChange={handleFileField}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor={`customFile_${each_fields.id}`}
                          >
                            {fileNames[field_store_name] ||
                              each_fields.field_name}
                          </label>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="row s-bottom cart-btn  align-items-center justify-content-between border-top border-color-1">
                <div className="col-md-6 col-7  ">
                  <h4 className="font-color ">
                    <strong> Price ₹ {nfcCard.price} </strong>
                  </h4>
                </div>
                <div className="col-md-6  col-5 text-right mb-2 mt-2 ">
                  <button
                    type="submit"
                    className="btn  theme_flt_btn btn-depth"
                    // onClick={handleSubmit}
                  >
                    Add To Cart
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {nfcCard.des !== null ? (
        <div className="row mx-5 mt-5 each-pro-desc">
          <h4 className="font-color">
            <strong>Product description</strong>
          </h4>
          <div
            className="col-md-12 "
            dangerouslySetInnerHTML={{ __html: nfcCard.des }}
          ></div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default NfcPreview;
