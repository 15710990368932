import { createContext, useState } from "react";

const AuthContext = createContext();
export default AuthContext;

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => ({
    apiToken: localStorage.getItem("apiToken")
      ? localStorage.getItem("apiToken")
      : null,
    // userName: localStorage.getItem("userName")
    //   ? localStorage.getItem("userName")
    //   : null,
    userId: localStorage.getItem("userId")
      ? localStorage.getItem("userId")
      : null,
    is_guest: localStorage.getItem("is_guest")
      ? localStorage.getItem("is_guest")
      : null,
  }));

  const login = (userData) => {
    // Assuming the userData contains the API token, you can extract it like this:
    // const { apiToken, userName, userId } = userData;

    // Update the user state with the API token
    setUser(userData);
  };
  const logout = () => {
    // Implement logout logic, e.g., clearing user data from the state.
    // Example:
    setUser(null);
    localStorage.removeItem("apiToken");
    localStorage.removeItem("userName");
    localStorage.removeItem("userId");
  };
  const contextData = {
    user,
    login,
    logout,
  };
  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};
