import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Slide } from "react-slideshow-image";
import Api from "../../helpers/Api";
import { ToastError, ToastSuccess } from "../../helpers/Toast";

const FramePreview = () => {
  const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    arrows: false,
  };
  const { FrameId } = useParams();

  const [frame, setFrame] = useState("");
  const [frameMultiImages, setFrameMultiImages] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (index, event) => {
    const newFiles = [...selectedFiles];
    newFiles[index] = event.target.files[0];
    setSelectedFiles(newFiles);
  };
  useEffect(() => {
    Api.FramePreview(FrameId)
      .then((res) => {
        const { frame_multi_img, frame } = res.data;
        setFrameMultiImages(frame_multi_img);
        setFrame(frame);
        // setNfcFields(fields);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [FrameId]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create a new FormData object from the form element
    const formSubmitData = new FormData(e.target);

    // Append the price from nfcCard
    formSubmitData.append("frame_id", FrameId);
    Api.FrameAddToCart(formSubmitData)
      .then((response) => {
        // console.log(response);
        if (response.status === 200) {
          ToastSuccess("Product Added to cart successfully!!!");
          formSubmitData.delete("frame_id"); // Remove the 'frame_id' field
          e.target.reset(); // Reset the form
          setTimeout(() => {
            window.location.href = "/cart";
          }, 6000);
        } else {
          ToastError("Oops! Something went wrong.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Input Fields
  const fileInputs = [];
  for (let i = 0; i < frame.grid_count; i++) {
    fileInputs.push(
      <div className="custom-file " key={i}>
        <input
          type="file"
          className="custom-file-input"
          id={`frame_${i}`}
          name={`frame_${i}`}
          onChange={(event) => handleFileChange(i, event)}
          required
        />
        <label className="custom-file-label" htmlFor={`frame_${i}`}>
          {selectedFiles[i]?.name || "Choose file"}
        </label>
      </div>
      // <div key={i} className="slide_field">
      //   <input
      //     type="file"
      //     className="form-control"
      //     name={`frame_${i}`}
      //     style={{ paddingLeft: "20px" }}
      //     // onChange={handleInputChange}
      //     required
      //   />
      // </div>
    );
  }
  return (
    <div className="container" id="nfc">
      <div className="row ">
        <div className="col-md-5 col-12 pro_det_step mt-2">
          <div className="pro_det_img slide-container">
            {frameMultiImages.length > 0 && (
              <Slide {...properties}>
                {frameMultiImages.map((each_img) => (
                  <div className="each-slide-effect" key={each_img.id}>
                    <img src={each_img.img_path} alt="Skin Images" />
                  </div>
                ))}
                {/* <div className='each-slide-effect'>
                  <img
                    src={
                      process.env.PUBLIC_URL + '/assets/img/techskins_logo.png'
                    }
                    alt='Skin Images'
                    className='img-fluid'
                  />
                </div>
                <div className='each-slide-effect'>
                  <img
                    src={
                      process.env.PUBLIC_URL + '/assets/img/techskins_logo.png'
                    }
                    className='img-fluid'
                    alt='Skin Images'
                  />
                </div> */}
              </Slide>
            )}
          </div>
        </div>
        <div className="col-md-7 col-12">
          <div className="skin_box scroll p-5">
            <h4 className="font-color text-left">{frame.product_name}</h4>
            <h4 className="font-color text-center pt-0">
              <strong>Please choose images</strong>
              {/* // 1=text,2=image,3=email,4=mobile */}
            </h4>
            <form
              encType="multipart/form-data"
              method="post"
              className="mt-2"
              onSubmit={handleSubmit}
            >
              <div className="form-group row mt-1">
                {fileInputs.map((input, index) => (
                  <div
                    key={index}
                    className="col-md-6 mb-3"
                    style={{ padding: "10px " }}
                  >
                    {input}
                  </div>
                ))}
              </div>
              <div className="row s-bottom cart-btn  align-items-center justify-content-between border-top border-color-1">
                <div className="col-md-6 col-7  ">
                  <h4 className="font-color ">
                    <strong> Price ₹ {frame.price} </strong>
                  </h4>
                </div>
                <div className="col-md-6  col-5 text-right mb-2 mt-2 ">
                  <button
                    type="submit"
                    className="btn  theme_flt_btn btn-depth"
                    // onClick={handleSubmit}
                  >
                    Add To Cart
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {frame.des && (
        <div className="row mx-5 mt-5 each-pro-desc">
          <h4 className="font-color">
            <strong>Product description</strong>
          </h4>
          <div
            className="col-md-12"
            dangerouslySetInnerHTML={{ __html: frame.des }}
          ></div>
        </div>
      )}
    </div>
  );
};

export default FramePreview;
