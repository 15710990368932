import axios from "axios";

// const baseURL = "http://127.0.0.1:8000/api";
// const baseURL = "https://demo-backend.techskins.in/api";

let baseURL = "";

// Check if the code is running in a local environment
if (process.env.NODE_ENV === "development") {
  baseURL = "http://127.0.0.1:8000/api";
} else {
  baseURL = "https://demo-backend.techskins.in/api";
}
const CustomAxios = (contentType) => {
  let apiToken = localStorage.getItem("apiToken");
  // console.log(apiToken);
  // if (!apiToken) {
  //   axios
  //     .get(`${baseURL}/get-token`)
  //     .then((response) => {
  //       const {
  //         token,
  //         is_guest,
  //       } = response.data;
  //       localStorage.setItem("apiToken", token);
  //       localStorage.setItem("is_guest", is_guest);
  //       apiToken = token;
  //       // console.log("Fetched and set API token:", apiToken);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching API token:", error);
  //     });
  // ;
  // }
  const axiosInstance = axios.create({
    baseURL,
    headers: {
      "Content-Type": contentType,
      // Add the authentication token to the request headers
      Authorization: apiToken ? `Bearer ${apiToken}` : null,
    },
  });
  return axiosInstance;
};

export default CustomAxios;
