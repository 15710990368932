import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Api from "../../helpers/Api";
import EachPartAccordion from "./EachPartAccordion";
import NormalSelect from "../../components/NormalSelect";
import RelatedProducts from "../detailpage/RelatedProducts";
import CategoryRelatedProducts from "../detailpage/CategoryRelatedProducts";
import CustomAddToCart from "./CustomAddToCart";
import { showSuccessAlert } from "../../helpers/SweetAlert";
import { LoaderContext } from "../../context/LoaderContext";
import PreLoader from "../../components/PreLoader";
const Custom = () => {
  let baseURL =
    process.env.NODE_ENV === "development"
      ? "http://localhost:8004"
      : "https://express.techskins.in";

  const { DeviceID } = useParams();
  const navigate = useNavigate();
  const { isLoading, setIsLoading } = useContext(LoaderContext);

  const [brands, setBrands] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [devices, setDevices] = useState([]);
  const [deviceParts, setDeviceParts] = useState([]);
  const [selectedPart, setSelectedPart] = useState(null);
  const [colorDevice, setColorDevice] = useState({});
  const [colorDevices, setColorDevices] = useState([]);
  const [mainThemes, setMainThemes] = useState([]);
  const [deviceRelatedKit, setDeviceRelatedKit] = useState([]);
  const [categoryRelatedKit, setCategoryRelatedKit] = useState([]);
  const [selectedPartSkin, setSelectedPartSkin] = useState({});
  const [partBasedSkins, setPartBasedSkins] = useState({});

  const addToCartDataInit = {
    device_id: DeviceID,
    part_based_skins: {},
    with_logo: 0,
    device_extra_items: [],
    category_extra_items: [],
  };
  const [addToCartData, setAddToCartData] = useState(addToCartDataInit);
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    setIsLoading(true);
    Api.CustomPage(DeviceID)
      .then((res) => {
        const {
          brands,
          custom_color_devices,
          category_related_kit_parts,
          device_related_kit_parts,
          main_themes,
          device_parts_relations,
          device,
        } = res.data;
        setCategoryId(device.category_id);
        setBrands(brands);
        setColorDevice(custom_color_devices[0] ? custom_color_devices[0] : {});
        setCategoryRelatedKit(category_related_kit_parts);
        setDeviceRelatedKit(device_related_kit_parts);
        setColorDevices(custom_color_devices);
        setMainThemes(main_themes);
        setDeviceParts(device_parts_relations);
        setSelectedPart(device_parts_relations[0]?.id || null);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setIsLoading(false));
  }, [DeviceID]);

  const nextStep = () => {
    let next_step = currentStep + 1;
    if (next_step === 2) {
      next_step =
        deviceRelatedKit.length > 0 || categoryRelatedKit.length > 0
          ? next_step
          : next_step + 1;
    }
    setCurrentStep(next_step);
  };
  const prevStep = () => {
    let prev_step = currentStep - 1;
    if (prev_step === 2) {
      prev_step =
        deviceRelatedKit.length > 0 || categoryRelatedKit.length > 0
          ? prev_step
          : prev_step - 1;
    }
    setCurrentStep(prev_step);
  };
  const handleBrandChange = (selectedOption) => {
    if (selectedOption) {
      Api.getDevicesByBrand(selectedOption.value)
        .then((res) => {
          // Filter out objects where d.is_custom is not equal to 1
          const filteredDevices = res.data.devices
            .filter((d) => d.is_custom === 1)
            .map((d) => ({
              value: d.id,
              label: d.model_no,
              is_custom: d.is_custom,
            }));

          setDevices(filteredDevices);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      // If no brand is selected, reset the devices list
      setDevices([]);
    }
  };
  const handleDeviceChange = (selectedOption) => {
    const newDeviceId = selectedOption.value;
    navigate(`/custom/${newDeviceId}`);
  };
  const handleColorChange = (selectedOption) => {
    const newColorDeviceId = selectedOption.value;
    const newColorDevice = colorDevices.find(
      (c_d) => c_d.id === newColorDeviceId
    );
    setColorDevice(newColorDevice);
  };
  const handleDeviceKit = (isChecked, itemId) => {
    setAddToCartData((prevData) => ({
      ...prevData,
      device_extra_items: isChecked
        ? [...prevData.device_extra_items, itemId]
        : prevData.device_extra_items.filter((id) => id !== itemId),
    }));
  };
  const handleCategoryKit = (isChecked, itemId) => {
    setAddToCartData((prevData) => ({
      ...prevData,
      category_extra_items: isChecked
        ? [...prevData.category_extra_items, itemId]
        : prevData.category_extra_items.filter((id) => id !== itemId),
    }));
  };
  const handleSkinSelection = (partId, skinId) => {
    setAddToCartData((prevAddToCartData) => ({
      ...prevAddToCartData,
      part_based_skins: {
        ...prevAddToCartData.part_based_skins,
        [partId]: skinId,
      },
    }));
    setSelectedPartSkin((prev) => ({ ...prev, [partId]: skinId }));
  };
  const handleSkinRemoval = (partId) => {
    setAddToCartData((prevAddToCartData) => {
      const updatedPartBasedSkins = { ...prevAddToCartData.part_based_skins };
      delete updatedPartBasedSkins[partId];
      return {
        ...prevAddToCartData,
        part_based_skins: updatedPartBasedSkins,
      };
    });
  };

  const handleAddCart = (e) => {
    // Convert to JSON string
    const requestBody = JSON.stringify(addToCartData);
    Api.ProdCustomAddToCart(requestBody)
      .then((res) => {
        showSuccessAlert("Skins Added In Your Cart SuccessFully");
        navigate("/cart");
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const renderSwitch = (param) => {
    switch (param) {
      case 1:
        return (
          <>
            {deviceParts &&
              deviceParts.map((part) =>
                selectedPart && selectedPart === part.id ? (
                  <EachPartAccordion
                    key={part.id}
                    {...part}
                    mainThemes={mainThemes}
                    device_id={DeviceID}
                    part_id={part.part_id}
                    handleSkinSelection={handleSkinSelection}
                    handleSkinRemoval={handleSkinRemoval}
                    addToCartData={addToCartData}
                    setPartBasedSkins={setPartBasedSkins}
                  />
                ) : null
              )}
          </>
        );
      case 2:
        return (
          <>
            <div className="col-md-12">
              <div className="row ">
                <div className="col-md-12">
                  <h6 className="font-color">Related Products</h6>
                </div>
                <RelatedProducts
                  prevStep={prevStep}
                  nextStep={nextStep}
                  deviceRelatedKit={deviceRelatedKit}
                  onCheckboxChange={handleDeviceKit}
                  addToCartData={addToCartData}
                />
                <CategoryRelatedProducts
                  prevStep={prevStep}
                  nextStep={nextStep}
                  categoryRelatedKit={categoryRelatedKit}
                  onCheckboxChange={handleCategoryKit}
                  addToCartData={addToCartData}
                />
              </div>
            </div>
          </>
        );
      case 3:
        return (
          <CustomAddToCart
            prevStep={prevStep}
            nextStep={nextStep}
            handleAddCart={handleAddCart}
            addToCartData={addToCartData}
            partBasedSkins={partBasedSkins}
            categoryRelatedKit={categoryRelatedKit}
            deviceRelatedKit={deviceRelatedKit}
            deviceParts={deviceParts}
          />
        );
      default:
        return <CustomAddToCart prevStep={prevStep} nextStep={nextStep} />;
    }
  };

  return (
    <>
      {isLoading ? (
        <PreLoader />
      ) : (
        <div className="container">
          <div className="row mt-1 d-flex">
            <div className="col-md-4 col-12 ">
              <div className="row mb-2">
                <div className="col-md-6 col-6 my-2">
                  <NormalSelect
                    options={brands.map((b) => ({
                      value: b.id,
                      label: b.brand_name,
                    }))}
                    placeholder="Select Brand"
                    onChange={handleBrandChange}
                  />
                </div>
                <div className="col-md-6 col-6 my-2">
                  <NormalSelect
                    options={devices}
                    placeholder="Select Model"
                    onChange={handleDeviceChange}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-6 col-6">
                  {/* <button className="btn back_btn" onClick={() => navigate(-1)}> */}
                  <Link
                    to={`/category/${categoryId}`}
                    className="btn back_btn"
                    // onClick={handleAsideClose}
                  >
                    <span
                    // style={{
                    //   padding: "11px 13px ",
                    //   margin: "0px 10px 0px -20px",
                    // }}
                    >
                      <i className="fa fa-backward" aria-hidden="true"></i>
                    </span>
                    Back
                  </Link>
                </div>
                <div className="col-md-6 col-6 my-2">
                  <NormalSelect
                    options={colorDevices.map((c_d) => ({
                      value: c_d.id,
                      label: c_d.cust_color_name,
                    }))}
                    placeholder="Select Colors"
                    onChange={handleColorChange}
                  />
                </div>
              </div>
            </div>
            {/* pd-r-90 */}
            <div className="col-md-8 col-12">
              <div
                className={`col-md-12 col-6 text-right p-0 ${
                  currentStep > 1 && currentStep < 3 ? "prev-step" : "next-step"
                }`}
              >
                {currentStep > 1 && (
                  <button
                    className="prev_btn btn btn-depth"
                    onClick={prevStep}
                    // disabled={currentStep === 1}
                  >
                    <i className="fa fa-angles-left"></i>
                    {/* Previous Step */}
                  </button>
                )}
                {currentStep < 3 && (
                  <button
                    className="next_btn btn btn-depth"
                    onClick={nextStep}
                    // disabled={currentStep === 4}
                  >
                    <span className="circle-animation"></span>
                    {/* Next Step */}
                    <i className="fa fa-angles-right"></i>
                  </button>
                )}
              </div>
              {currentStep === 1 && (
                <div className="row part_head custom-out-wrap">
                  {deviceParts &&
                    deviceParts.map((part) => (
                      <div className="cus-col-md-3 text-center" key={part.id}>
                        <button
                          className={`btn part_btns ${
                            selectedPart === part.id ? "part_btn" : ""
                          }`}
                          onClick={() => setSelectedPart(part.id)}
                        >
                          {addToCartData.part_based_skins[part.part_id]
                            ? "✔ "
                            : ""}{" "}
                          {part.part_name}
                        </button>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
          <div className="row mt-1">
            <div className="col-md-4 pro_det_step d-flex align-items-center">
              <div className="product-single__photo-wrapper ">
                <div className="product-container product-single__media-group">
                  <img
                    id="product-mock-up"
                    tabIndex={-1}
                    data-sizes="auto"
                    src={colorDevice?.cust_device_img}
                    alt={colorDevice?.cust_color_name}
                  />
                  {deviceParts &&
                    deviceParts.map(
                      (part) =>
                        part.mask_img && (
                          <div
                            className="layover-img-Back"
                            style={{
                              WebkitMaskImage: `url(${baseURL}/uploads/${part.mask_img})`,
                            }}
                            key={part.id}
                            id={`part_bg_${part.id}`}
                          ></div>
                        )
                    )}
                </div>
              </div>
            </div>

            <div className="col-md-8 pro_step">
              {/* <div
                className={`col-md-12 col-6 text-right ${
                  currentStep > 1 && currentStep < 3 ? 'prev-step' : 'next-step'
                }`}
              >
                {currentStep > 1 && (
                  <button
                    className='prev_btn btn btn-depth'
                    onClick={prevStep}
                    // disabled={currentStep === 1}
                  >
                    <i className='fa fa-angles-left'></i> Previous Step
                  </button>
                )}
                {currentStep < 3 && (
                  <button
                    className='next_btn btn btn-depth'
                    onClick={nextStep}
                    // disabled={currentStep === 4}
                  >
                    <span className='circle-animation'></span>
                    Next Step<i className='fa fa-angles-right'></i>
                  </button>
                )}
              </div> */}
              <div className="row skin_box scroll custom-pro">
                {renderSwitch(currentStep)}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Custom;
