import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Api from "../../helpers/Api";
import { Slide } from "react-slideshow-image";
import { ToastError, ToastSuccess } from "../../helpers/Toast";

const StickerProductDetail = () => {
  const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    arrows: false,
  };
  const { ProductId } = useParams();
  const [sticker, setSticker] = useState({});
  const [ProductOptions, setProductOptions] = useState([]);
  const [finalPrice, setFinalPrice] = useState(0);
  const [selectedChoices, setSelectedChoices] = useState({});
  useEffect(() => {
    Api.StickerProductDetail(ProductId)
      .then((res) => {
        const { sticker, applicable_options } = res.data;
        setSticker(sticker);
        setFinalPrice(parseInt(sticker?.price));

        setProductOptions(applicable_options);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [ProductId]);

  // Function to handle choice selection for a specific option
  const handleChoiceSelection = (optionId, choiceId, price) => {
    // Get the previously selected choice for the same option
    const previousChoiceId = selectedChoices[optionId];
    // Calculate and update the finalPrice based on the new choice and deducting the previous choice
    setFinalPrice((prevFinalPrice) => {
      // Get the option object
      const option = ProductOptions.find(
        (opt) => opt.id === parseInt(optionId)
      );

      let newPrice = prevFinalPrice;

      // Deduct the price of the previously selected choice if it exists
      if (previousChoiceId) {
        const previousChoice = option.choice_maps.find(
          (choice_map) => choice_map.id === previousChoiceId
        );
        if (previousChoice) {
          newPrice -= parseInt(previousChoice.price);
        }
      }

      // Add the price of the newly selected choice
      newPrice += parseInt(price);

      // Update the selected choice for the specified option
      setSelectedChoices((prevSelectedChoices) => ({
        ...prevSelectedChoices,
        [optionId]: choiceId,
      }));

      return newPrice;
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    // Create a new FormData object from the form element
    const formSubmitData = new FormData(e.target);
    // Convert selectedChoices to the desired format
    const selectedChoiceMap = {};
    for (const optionId in selectedChoices) {
      selectedChoiceMap[optionId] = selectedChoices[optionId];
    }
    // Append the price from nfcCard
    formSubmitData.append("product_id", ProductId);
    // Convert the selectedChoiceMap object to a JSON string
    const selectedChoiceMapJSON = JSON.stringify(selectedChoiceMap);
    // Append the JSON string to the FormData object
    formSubmitData.append("selected_choices", selectedChoiceMapJSON);
    Api.StickerAddToCart(formSubmitData)
      .then((response) => {
        // console.log(response);
        if (response.status === 200) {
          ToastSuccess("Product Added to cart successfully!!!");
          formSubmitData.delete("product_id"); // Remove the 'frame_id' field
          e.target.reset(); // Reset the form
          setTimeout(() => {
            window.location.href = "/cart";
          }, 6000);
        } else {
          ToastError("Oops! Something went wrong.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="container" id="nfc">
      <div className="row ">
        <div className="col-md-5 col-12 pro_det_step mt-2">
          <div className="pro_det_img slide-container">
            {sticker?.images?.length > 0 && (
              <Slide {...properties}>
                {sticker?.images.map((each_img) => (
                  <div className="each-slide-effect" key={each_img.id}>
                    <img src={each_img.img_path} alt="Skin Images" />
                  </div>
                ))}
              </Slide>
            )}
          </div>
        </div>
        <div className="col-md-7 col-12">
          <div className="skin_box scroll p-5">
            <h4 className="font-color text-left">{sticker?.product_name}</h4>
            <form
              encType="multipart/form-data"
              method="post"
              className="mt-5"
              onSubmit={handleSubmit}
            >
              {ProductOptions.map((each_option) => (
                <div className="my-5" key={each_option.id}>
                  <h6 className="font-color">
                    <strong>{each_option.option.option_name}</strong>
                  </h6>

                  <div className="row">
                    {each_option.choice_maps.map((each_choice) => (
                      <div className="col-md-2 col-6" key={each_choice.id}>
                        <div
                          style={{ width: "100px" }}
                          className="text-center shadow-soft"
                        >
                          <div
                            className={`text-center thumbnail_img border ${
                              selectedChoices[each_option.id] === each_choice.id
                                ? "border-success"
                                : "border-primary"
                            }`}
                            onClick={() =>
                              handleChoiceSelection(
                                each_option.id,
                                each_choice.id,
                                each_choice.price
                              )
                            }
                          >
                            {each_choice.choice.choice_img && (
                              <img
                                src={each_choice.choice.choice_img}
                                alt="skin"
                                className={`img-fluid active_skin`}
                              />
                            )}

                            <div className={`border`}>
                              <p>{each_choice.choice.choice_name}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              <div className="row s-bottom cart-btn  align-items-center justify-content-between border-top border-color-1">
                <div className="col-md-6 col-7  ">
                  <h4 className="font-color ">
                    <strong> Price ₹ {finalPrice} </strong>
                  </h4>
                </div>
                <div className="col-md-6  col-5 text-right mb-2 mt-2 ">
                  {sticker && (
                    <button
                      type="submit"
                      className="btn  theme_flt_btn btn-depth"
                      // onClick={handleSubmit}
                    >
                      Add To Cart
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* {frame.des && (
        <div className="row mx-5 mt-5 each-pro-desc">
          <h4 className="font-color">
            <strong>Product description</strong>
          </h4>
          <div
            className="col-md-12"
            dangerouslySetInnerHTML={{ __html: frame.des }}
          ></div>
        </div>
      )} */}
    </div>
  );
};

export default StickerProductDetail;
