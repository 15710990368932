import React from "react";
import { Link } from "react-router-dom";

const EachProductCard = ({ skin_name, skin_img, category_name, id ,reg_price,ofr_price}) => {
   // Replace whitespace with "-" and convert to lowercase
   const formattedCategoryName = category_name.replace(/\s+/g, "-").toLowerCase();
  return (
    <li className='col-md-2 col-lg-2 col-6 mt-3'>
      <div className='product-item__outer h-100 product-i'>
        <div className='product-item__inner'>
          <div className='product-item__body'>
            {/* <div className='mb-2'>
              <Link
                to={`/${formattedCategoryName}/${id}`}
                className='font-size-12 text-gray-5'
              >
                {formattedCategoryName}
              </Link>
            </div> */}

            <div className=' pro_img'>
              <Link
                to={`/${formattedCategoryName}/${id}`}
                className='font-size-12 text-gray-5'
              >
                <img className='img-fluid ' src={skin_img} alt='' />
              </Link>
            </div>

            <div className='prodcut-price d-flex justify-content-between align-items-center'>
              <div>
                <Link
                  to={`/${formattedCategoryName}/${id}`}
                  className='font-color font-weight-bold text-center skin-title'
                >
                  {skin_name}
                </Link>
              </div>
              <div className='text-gray-100 reg_price text-decoration-line-through'>
                ₹ {ofr_price}
              </div>
              <div className='text-gray-100 ofr_price'>₹ {reg_price}</div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default EachProductCard;
