import React from "react";
import { Link } from "react-router-dom";

const EachSlide = ({ title, sub_title, img_path, main_img, link }) => {
  return (
    <Link to={link}>
      <div
        className="js-slide bg-img-hero-center"
        style={{ backgroundImage: `url(${main_img})` }}
      >
        <div className="row height-410-xl py-7 py-md-0 mx-0">
          <div className="d-none d-wd-block offset-1" />
          <div className="col-xl col-6 col-md-6 mt-md-8">
            <h1
              className="font-size-64 text-lh-57 font-weight-light"
              data-scs-animation-in="fadeInUp"
            >
              THE NEW <span className="d-block font-size-55">{title}</span>
            </h1>
            <h6
              className="font-size-15 font-weight-bold mb-3"
              data-scs-animation-in="fadeInUp"
              data-scs-animation-delay={200}
            >
              {sub_title}
            </h6>
            <div
              className="mb-4"
              data-scs-animation-in="fadeInUp"
              data-scs-animation-delay={300}
            >
              <span className="font-size-13">FROM</span>
              <div className="font-size-50 font-weight-bold text-lh-45">
                <sup>$</sup>749<sup>99</sup>
              </div>
            </div>
            <button

              className="btn btn-primary transition-3d-hover rounded-lg font-weight-normal py-2 px-md-7 px-3 font-size-16"
              data-scs-animation-in="fadeInUp"
              data-scs-animation-delay={400}
            >
              Start Buying
            </button>
          </div>
          <div
            className="col-xl-7 col-6 d-flex align-items-center ml-auto ml-md-0"
            data-scs-animation-in="zoomIn"
            data-scs-animation-delay={500}
          >
            <img className="img-fluid" src={img_path} alt="" />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default EachSlide;
