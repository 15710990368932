import React, { createContext, useState } from 'react';

export const LeftAsideContext = createContext();

export const LeftAsideProvider = ({ children }) => {
  const [isLeftAsideActive, setLeftAsideActive] = useState(false);

  // Wrap the children components with the provider and pass the state and updater function
  return (
    <LeftAsideContext.Provider
      value={{ isLeftAsideActive, setLeftAsideActive }}
    >
      {children}
    </LeftAsideContext.Provider>
  );
};
