import React, { useState } from "react";

const ShareButton = () => {
  // Create a state to manage the visibility of icons
  const [areIconsVisible, setIconsVisibility] = useState(false);

  // Function to show each icon with a delay
  // const showIconWithDelay = (icon, delay) => {
  //   setTimeout(() => {
  //     icon.style.opacity = 1;
  //   }, delay);
  // };

  // Handle mouse enter event
  const handleMouseEnter = () => {
    setIconsVisibility(true);
  };

  // Handle mouse leave event
  const handleMouseLeave = () => {
    setIconsVisibility(false);
  };

  // CSS styles for the icons
  const iconStyle = {
    opacity: areIconsVisible ? 1 : 0,
    transition: "opacity 0.3s ease",
  };

  return (
    <button
      className="btn share_btn btn-depth"
      id="social"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <i className="fa fa-share-alt" aria-hidden="true"></i>Follow us
      <ul className="list hidden">
        <li style={{ ...iconStyle, transitionDelay: "0.8s" }}>
          <a
            href="https://www.youtube.com/@techskins9630"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="font-size-16 fab fa-youtube"></i>
          </a>
        </li>
        <li style={{ ...iconStyle, transitionDelay: "1s" }}>
          <a
            href="https://twitter.com/TechSkinsind"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="font-size-16 fab fa-twitter"></i>
          </a>
        </li>
        <li style={{ ...iconStyle, transitionDelay: "0.8s" }}>
          <a
            href="https://www.facebook.com/people/Tech-Skins/100089350340224/?mibextid=LQQJ4d"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="font-size-16 fab fa-facebook-f"></i>
          </a>
        </li>

        <li style={{ ...iconStyle, transitionDelay: "0.6s" }}>
          <a
            href="https://www.threads.net/@techskins.in"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="font-size-16 fab fa-threads"></i>
          </a>
        </li>
        <li style={{ ...iconStyle, transitionDelay: "0.4s" }}>
          <a
            href="https://www.instagram.com/techskins.in/?igshid=OGQ5ZDc2ODk2ZA%3D%3D"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="font-size-16 fab fa-instagram"></i>
          </a>
        </li>

        <li style={{ ...iconStyle, transitionDelay: "0.2s" }}>
          <a
            href="https://api.whatsapp.com/send?phone=+919940255602"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="font-size-16 fab fa-whatsapp"></i>
          </a>
        </li>
      </ul>
    </button>
  );
};

export default ShareButton;
