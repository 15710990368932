import React from "react";

const CouponAccordion = ({
  handleCouponValidate,
  setCoupon,
  coupon,
  couponApplied,
}) => {
  const handleInputChange = (e) => {
    const { value } = e.target;
    setCoupon(value);
  };
  return (
    <>
      <div id='shopCartTwo'>
        <div className='input-group input-group-pill max-width-xl'>
          <input
            type='text'
            className='form-control'
            name='name'
            placeholder='Coupon code'
            aria-label='Promo code'
            value={coupon}
            onChange={handleInputChange}
          />
          {}

          <div className='input-group-append'>
            {coupon !== '' && (
              <button
                type='button'
                onClick={handleCouponValidate}
                className='btn btn-block font-weight-normal btn-pill px-4'
              >
                <i className='fas fa-tags d-md-none' />

                <span className='d-none d-md-inline'>Apply coupon</span>
              </button>
            )}
          </div>
        </div>
        {couponApplied && <span>Coupon Applied SuccessFully</span>}
      </div>
      {/* <div id='shopCartAccordion1' className='accordion rounded mb-6 d-none'>
        <div className='card border-0 '>
          <div
            id='shopCartHeadingTwo'
            className='alert alert-primary mb-0 font-color'
            role='alert'
          >
            Have a coupon?
            <a
              href='javascript:0;'
              className='alert-link'
              data-toggle='collapse'
              data-target='#shopCartTwo'
              aria-expanded='false'
              aria-controls='shopCartTwo'
            >
              Click here to enter your code
            </a>
          </div>
          <div
            id='shopCartTwo'
            className='collapse border border-top-0'
            aria-labelledby='shopCartHeadingTwo'
            data-parent='#shopCartAccordion1'
            style={{}}
          >
            <form className='js-validate p-3' noValidate='novalidate'>
            <div className='row d-flex align-items-center'>
              <div className='col-md-6'>
                <p className='w-100 text-gray-90'>
                  If you have a coupon code, please apply it below.
                </p>
              </div>
              <div className='col-md-6 col-12'>
                <div className='input-group input-group-pill max-width-xl'>
                  <input
                    type='text'
                    className='form-control'
                    name='name'
                    placeholder='Coupon code'
                    aria-label='Promo code'
                    value={coupon}
                    onChange={handleInputChange}
                  />
                  {}

                  <div className='input-group-append'>
                    {coupon !== '' && (
                      <button
                        type='button'
                        onClick={handleCouponValidate}
                        className='btn btn-block font-weight-normal btn-pill px-4'
                      >
                        <i className='fas fa-tags d-md-none' />

                        <span className='d-none d-md-inline'>Apply coupon</span>
                      </button>
                    )}
                  </div>
                </div>
                {couponApplied && <span>Coupon Applied SuccessFully</span>}
              </div>
            </div>
            </form>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default CouponAccordion;
