import React, { useContext, useEffect, useState } from "react";
import EachAcrylicProduct from "./EachAcrylicProduct";
import Api from "../../../helpers/Api";
import PreLoader from "../../../components/PreLoader";
import { LoaderContext } from "../../../context/LoaderContext";
const AcrylicListPage = () => {
  const [products, setProducts] = useState([]);
  const { isLoading, setIsLoading } = useContext(LoaderContext);
  useEffect(() => {
    setIsLoading(true);
    Api.AcrylicProductList()
      .then((res) => {
        setProducts(res.data.products);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setIsLoading(false));
  }, [setIsLoading]);
  return (
    <>
      {isLoading ? (
        <PreLoader />
      ) : (
        <div className="container">
          <div className="row each_product_row mt-3">
            {products.map((product) => (
              <EachAcrylicProduct {...product} key={product.id} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default AcrylicListPage;
