import React, { useEffect, useState, useContext } from "react";
// import SelectWithCheckBox from "../filterpage/SelectWithCheckBox";
import { useParams } from "react-router-dom";
import Api from "../../helpers/Api";
import EachCoupleCard from "./EachCoupleCard";
import { LoaderContext } from "../../context/LoaderContext";
import PreLoader from "../../components/PreLoader";
import Select from "react-select";
import { ThemeModeContext } from "../../context/ThemeModeContext";
import { darkTheme, lightTheme } from "../../components/SelectTheme";

const CoupleFilterPage = () => {
  const { isDarkMode } = useContext(ThemeModeContext);
  const { isLoading, setIsLoading } = useContext(LoaderContext);
  const { categoryID } = useParams();
  const [themesOptions, setThemesOptions] = useState([]);
  const [subThemesOptions, setSubThemesOptions] = useState([]);
  const [resetCounter, setResetCounter] = useState(0);
  const filterInitial = {
    skin_theme_id: null,
    sub_theme_id: null,
    category_id: categoryID,
  };
  const [selectedFilters, setSelectedFilters] = useState(filterInitial);
  const [skins, setSkins] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    Api.CoupleFilterPage(categoryID)
      .then((res) => {
        setThemesOptions(
          res.data.themes.map((theme) => ({
            value: theme.id,
            label: theme.theme_name,
          }))
        );
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setIsLoading(false));
  }, [categoryID, setIsLoading]);
  useEffect(() => {
    // const requestBody = JSON.stringify(selectedFilters);
    Api.makeCoupleFilterPost(selectedFilters)
      .then((res) => {
        const { skins, sub_themes } = res.data;
        setSkins(skins);
        setSubThemesOptions(
          sub_themes.map((sub_theme) => ({
            value: sub_theme.sub_theme_id,
            label: sub_theme.sub_theme_name,
          }))
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }, [selectedFilters]);
  const handleThemeChange = (selectedOption) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      skin_theme_id: selectedOption?.value,
    }));
  };
  const handleSubThemeChange = (selectedOption) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      sub_theme_id: selectedOption?.value,
    }));
  };
  const handleReset = () => {
    setSelectedFilters(filterInitial);
    setResetCounter((prevCounter) => prevCounter + 1);
  };
  return (
    <>
      {isLoading ? (
        <PreLoader />
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-md-2 col-6  my-2">
              <Select
                options={themesOptions}
                placeholder="Select Theme"
                onChange={handleThemeChange}
                styles={isDarkMode ? darkTheme : lightTheme}
                key={`reset_${resetCounter}`}
              />
            </div>

            {subThemesOptions.length > 0 && (
              <div className="col-md-2 col-6  my-2">
                <Select
                  options={subThemesOptions}
                  placeholder="Select Sub Theme"
                  onChange={handleSubThemeChange}
                  styles={isDarkMode ? darkTheme : lightTheme}
                  key={`reset_${resetCounter}`}
                />
              </div>
            )}
            <div className="col-md-2 col-6 reset-btn my-2">
              <button
                type="button"
                className="btn  theme_flt_btn btn-depth"
                onClick={handleReset}
                style={{ width: "100%", padding: "8px" }}
              >
                Reset <i className="fa fa-rotate" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div className="row each_product_row mt-3">
            {skins.map((product) => (
              <EachCoupleCard {...product} key={product.id} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default CoupleFilterPage;
