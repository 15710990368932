import React from "react";

const CategoryRelatedProducts = ({
  categoryRelatedKit,
  onCheckboxChange,
  addToCartData,
}) => {
  return (
    <>
      {categoryRelatedKit.map((each_kit_item, k) => (
        <div
          className='row col-md-12 mt-2 mb-3 d-flex align-items-center'
          key={each_kit_item.id}
        >
          <div className='col-md-3 col-4 d-flex justify-content-center'>
            <div className='skin_box_img'>
              <img
                src={each_kit_item.img_path}
                alt={each_kit_item.heading}
                className='img-fluid p-2'
                // style={{ width: '100px' }}
              />
            </div>
          </div>
          <div className='col-md-7 col-6 pro-desc'>
            <h4 className='font-color'>{each_kit_item.heading}</h4>
            <p className='font-17 font-color text-left desc'>
              {each_kit_item.desc}
            </p>
            <p className='font-17 font-color text-left'>
              ₹ {each_kit_item.price}
            </p>
          </div>
          <div
            className='col-md-2 col-1 d-flex justify-content-center'
            id='customize'
          >
            <div className='form-check'>
              <input
                className='form-check-input'
                type='checkbox'
                name='category_extra_items[]'
                id={`cat-rel-${each_kit_item.id}`}
                value={each_kit_item.id}
                checked={addToCartData.category_extra_items.includes(
                  each_kit_item.id
                )}
                onChange={(e) =>
                  onCheckboxChange(e.target.checked, each_kit_item.id)
                }
              />{' '}
              <label
                className='form-check-label'
                htmlFor={`cat-rel-${each_kit_item.id}`}
              ></label>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default CategoryRelatedProducts;
