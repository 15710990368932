import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { LoginAsideContext } from "../context/LoginAsideContext";
import Api from "../helpers/Api";
import AuthContext from "../context/AuthContext";
import { Link } from "react-router-dom";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";
import { ToastError, ToastSuccess } from "../helpers/Toast";
// https://www.npmjs.com/package/reactjs-social-login

// import {
//   FacebookLoginButton,
// } from 'react-social-login-buttons';
const SideBar = ({ logo }) => {
  const loginInitial = {
    name: "",
    email: "",
    password: "",
  };
  const recoverPassword = {
    email: "",
  };

  // Add this line to access the login function from AuthContext
  const { login } = useContext(AuthContext);
  const formReducer = (state, action) => {
    switch (action.type) {
      case 1:
        return 1;
      case 2:
        return 2;
      case 3:
        return 3;
      default:
        return state;
    }
  };
  const [validationErrors, setValidationErrors] = useState({});
  const [loginData, setLoginData] = useState(loginInitial);
  const [recoverEmail, setRecoverEmail] = useState(recoverPassword);
  const [activeForm, dispatch] = useReducer(formReducer, 1);
  const { isLoginAsideActive, setLoginAsideActive } =
    useContext(LoginAsideContext);
  const asideRef = useRef(null);
  const handleClickOutside = (event) => {
    if (asideRef.current && !asideRef.current.contains(event.target)) {
      setLoginAsideActive(false);
    }
  };
  useEffect(() => {
    if (isLoginAsideActive) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isLoginAsideActive]);

  const handleFormChange = (formType) => {
    dispatch({ type: formType }); // Dispatch action to switch to the specified form
  };
  const handleAsideClose = (e) => {
    e.stopPropagation();
    setLoginAsideActive(!isLoginAsideActive);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
  };
  const handleRecoverPassword = (e) => {
    const { name, value } = e.target;
    setRecoverEmail({
      ...recoverEmail,
      [name]: value,
    });
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
  };

  const handleLogin = async (e) => {
    const requiredFields = ["email", "password"];
    // Define custom error messages for each field
    const customErrorMessages = {
      email: "Please enter email",
      password: "Please enter password",
    };
    let hasValidationErrors = false;
    const newValidationErrors = {};
    for (const field of requiredFields) {
      if (!loginData[field]) {
        const errorMessage = customErrorMessages[field];
        newValidationErrors[field] = errorMessage;
        hasValidationErrors = true;
      }
    }
    setValidationErrors(newValidationErrors);
    if (hasValidationErrors) {
      return;
    }
    e.preventDefault();
    const requestBody = JSON.stringify(loginData);
    Api.Login(requestBody)
      .then((res) => {
        const responseData = res.data;
        // Store the API token, user name, and user ID in the local storage
        localStorage.setItem("apiToken", responseData.token);
        localStorage.setItem("userName", responseData.name);
        localStorage.setItem("userId", responseData.user_id);
        localStorage.setItem("is_guest", 0);
        setLoginAsideActive(false);
        login({
          apiToken: responseData.token,
          userName: responseData.name,
          userId: responseData.user_id,
          is_guest: 0,
        });
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleReg = async (e) => {
    const requiredFields = [
      "name",
      "email",
      "password",
      "password_confirmation",
    ];
    // Define custom error messages for each field
    const customErrorMessages = {
      name: "Please enter your name",
      email: "Please enter email",
      password: "Please enter password",
      password_confirmation: "Please re-enter password",
    };
    let hasValidationErrors = false;
    const newValidationErrors = {};
    for (const field of requiredFields) {
      if (!loginData[field]) {
        const errorMessage = customErrorMessages[field];
        newValidationErrors[field] = errorMessage;
        hasValidationErrors = true;
      }
    }
    setValidationErrors(newValidationErrors);
    if (hasValidationErrors) {
      return;
    }
    e.preventDefault();
    const requestBody = JSON.stringify(loginData);
    Api.Register(requestBody)
      .then((res) => {
        const responseData = res.data;
        // Store the API token, user name, and user ID in the local storage
        localStorage.setItem("apiToken", responseData.token);
        localStorage.setItem("is_guest", 0);
        setLoginAsideActive(false);
        login({
          apiToken: responseData.token,
          is_guest: 0,
        });
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleForgotPwd = () => {
    const requiredFields = ["email"];
    // Define custom error messages for each field
    const customErrorMessages = {
      email: "Please enter email",
    };
    let hasValidationErrors = false;
    const newValidationErrors = {};
    for (const field of requiredFields) {
      if (!recoverEmail[field]) {
        const errorMessage = customErrorMessages[field];
        newValidationErrors[field] = errorMessage;
        hasValidationErrors = true;
      }
    }
    setValidationErrors(newValidationErrors);
    if (hasValidationErrors) {
      return;
    }
    if (recoverEmail.email !== "") {
      const requestBody = JSON.stringify(recoverEmail);
      Api.RecoverEmail(requestBody)
        .then((res) => {
          if (res.data.status === 200) {
            ToastSuccess("Reset Password Mail Sent Successfully");
            setTimeout(() => {
              setLoginAsideActive(!isLoginAsideActive);
            }, 3000);
          } else {
            alert(res.data.error);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  /* Validation error timeout */
  setTimeout(() => {
    setValidationErrors({});
  }, 3000);

  /* Social Login */
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState();
  const handleFacebookLogin = (data) => {
    setProvider("facebook");
    setProfile(data);
    // console.log(data, "data");

    const requestBody = JSON.stringify(data);
    Api.FbLogin(requestBody)
      .then((res) => {
        if (res.data.status === 200) {
          localStorage.setItem("apiToken", res.data.token);
          localStorage.setItem("is_guest", 0);
          login({
            apiToken: res.data.token,
            is_guest: 0,
          });
          setLoginAsideActive(false);
          ToastSuccess("Log In Successfully");
          setTimeout(() => {
            window.location.reload();
          }, 6000);
        } else {
          // console.log(res.data.error);
          ToastError(res.data.error);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleGoogleLogin = (data) => {
    setProvider("google");
    setProfile(data);
    console.log(data, "data");

    const requestBody = JSON.stringify(data);
    Api.GLogin(requestBody)
      .then((res) => {
        if (res.data.status === 200) {
          localStorage.setItem("apiToken", res.data.token);
          localStorage.setItem("is_guest", 0);
          login({
            apiToken: res.data.token,
            is_guest: 0,
          });
          setLoginAsideActive(false);
          ToastSuccess("Log In Successfully");
          setTimeout(() => {
            window.location.reload();
          }, 6000);
        } else {
          // console.log(res.data.error);
          ToastError(res.data.error);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleLogout = (e) => {
    setProvider("");
    setProfile("");

    window.location.href = "/"; // Replace "/" with your base URL or desired route
  };

  return (
    <aside
      id="sidebarbox"
      ref={asideRef}
      className={`u-sidebar u-sidebar__lg  tester ${
        isLoginAsideActive ? "is-active" : ""
      }`}
      // aria-labelledby="sidebarNavToggler"
    >
      <div className="u-sidebar__scroller">
        <div className="u-sidebar__container">
          <div className="js-scrollbar u-header-sidebar__footer-offset pb-3">
            {/* Toggle Button */}
            <div className="d-flex align-items-center pt-4 px-7">
              <Link
                className="navbar-brand u-header__navbar-brand u-header__navbar-brand-center"
                to="/"
                aria-label="Tech Skin"
                onClick={handleAsideClose}
              >
                <img
                  src={
                    logo
                    // process.env.PUBLIC_URL + "/assets/img/techskins_logo.png"
                  }
                  alt="Techskins Logo"
                  className="img-fluid"
                />
              </Link>
              <button
                type="button"
                className="close ml-auto"
                onClick={handleAsideClose}
              >
                <i className="ec ec-close-remove" />
              </button>
            </div>
            {/* End Toggle Button */}
            {/* Content */}
            <div className="js-scrollbar u-sidebar__body">
              <div className="u-sidebar__content u-header-sidebar__content">
                <form className="js-validate">
                  {/* Login */}
                  {activeForm === 1 && (
                    <div id="login" data-target-group="idForm">
                      {/* Title */}
                      <header className="text-center mb-7">
                        <h2 className="h4 mb-0 font-color">Welcome Back!</h2>
                        <p className="font-color">
                          Login to manage your account.
                        </p>
                      </header>
                      {/* End Title */}
                      {/* Form Group */}
                      <div className="form-group">
                        <div className="slide_field">
                          <span className="fas fa-envelope" />
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            id="signinEmail"
                            value={loginData.email} // Use loginData.password as the value for the input field
                            onChange={handleInputChange} // Use handleInputChange to update loginData
                            placeholder="Email"
                            aria-label="Email"
                            aria-describedby="signinEmailLabel"
                            required
                            data-msg="Please enter a valid email address."
                            data-error-class="u-has-error"
                            data-success-class="u-has-success"
                          />

                          {/* <label>Email Id</label> */}
                        </div>
                        {validationErrors.email && (
                          <p className="text-left text-danger">
                            {validationErrors.email}
                          </p>
                        )}
                        <div className="slide_field">
                          <span className="fas fa-lock" />
                          <input
                            type="password"
                            className="form-control"
                            name="password"
                            id="signinPassword"
                            autoComplete="true"
                            placeholder="Password"
                            aria-label="Password"
                            value={loginData.password} // Use loginData.password as the value for the input field
                            onChange={handleInputChange} // Use handleInputChange to update loginData
                            aria-describedby="signinPasswordLabel"
                            required
                            data-msg="Your password is invalid. Please try again."
                            data-error-class="u-has-error"
                            data-success-class="u-has-success"
                          />

                          <label className="sr-only" htmlFor="signinPassword">
                            Password
                          </label>
                        </div>
                        {validationErrors.password && (
                          <p className="text-left text-danger">
                            {validationErrors.password}
                          </p>
                        )}
                      </div>
                      {/* End Form Group */}

                      <div
                        className="d-flex justify-content-between align-items-center mb-4"
                        id="customize"
                      >
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="rem_me"
                            id="rem_check"
                          />{" "}
                          <label
                            className="form-check-label font-17 font-color"
                            htmlFor="rem_check"
                          >
                            Remember Me
                          </label>
                        </div>
                        <button
                          type="button"
                          // className="js-animation-lin k small link-muted forgot_btn font-color"
                          className="js-animation-link small link-muted btn btn-depth forgot_btn font-color"
                          data-target="#forgotPassword"
                          data-link-group="idForm"
                          data-animation-in="slideInUp"
                          onClick={(e) => {
                            e.preventDefault(); // Prevent the default link behavior
                            e.stopPropagation(); // Stop the event from propagating to the aside element
                            handleFormChange(3);
                          }}
                        >
                          Forgot Password?
                        </button>
                      </div>
                      <div className="mb-2">
                        <button
                          type="button"
                          className="btn btn-block btn-depth btn-sm  transition-3d-hover lgn_btn"
                          onClick={handleLogin}
                        >
                          Login
                        </button>
                      </div>
                      <div className="text-center mb-4">
                        <span className="font-17 font-color">
                          Do not have an account?
                        </span>
                        <button
                          type="button"
                          className="js-animation-link small  btn btn-depth signin_btn font-color"
                          data-target="#signup"
                          data-link-group="idForm"
                          data-animation-in="slideInUp"
                          onClick={(e) => {
                            e.preventDefault(); // Prevent the default link behavior
                            e.stopPropagation(); // Stop the event from propagating to the aside element
                            handleFormChange(2);
                          }}
                        >
                          Signup
                        </button>
                      </div>
                      <div className="text-center">
                        <span className="u-divider u-divider--xs u-divider--text mb-4">
                          OR
                        </span>
                      </div>
                      {/* Login Buttons */}
                      <div
                        className={`d-flex justify-content-between${
                          provider && profile ? "hide" : ""
                        }`}
                      >
                        <LoginSocialFacebook
                          appId="637116931850898"
                          onResolve={({ provider, data }) => {
                            handleFacebookLogin(data);
                          }}
                          onReject={(err) => {
                            console.log(err);
                          }}
                        >
                          <button
                            type="button"
                            className="btn btn-block btn-sm btn-facebook-cus lgn_btn transition-3d-hover mr-1"
                          >
                            <span className="fab fa-facebook-square mr-1" />
                            Facebook
                          </button>
                        </LoginSocialFacebook>
                        <LoginSocialGoogle
                          client_id="223187416068-hafokug646hhki928f45h1lolsss8rm7.apps.googleusercontent.com"
                          onLogoutSuccess={handleLogout}
                          scope="openid profile email"
                          onResolve={({ provider, data }) => {
                            handleGoogleLogin(data);
                          }}
                          onReject={(err) => {
                            console.log(err);
                          }}
                        >
                          <button
                            type="button"
                            className="btn btn-block btn-sm btn-google-cus lgn_btn transition-3d-hover ml-1 mt-0"
                          >
                            <span className="fab fa-google mr-1" />
                            Google
                          </button>
                        </LoginSocialGoogle>
                      </div>
                      {/* End Login Buttons */}
                    </div>
                  )}
                  {/* Signup */}
                  {activeForm === 2 && (
                    <div id="signup" data-target-group="idForm">
                      {/* Title */}
                      <header className="text-center mb-7">
                        <h2 className="h4 mb-0 font-color">
                          Welcome to TechSkins 🧑🏻
                        </h2>
                        <p className="font-color">
                          Fill out the form to get started.
                        </p>
                      </header>
                      {/* End Title */}
                      {/* Form Group */}
                      <div className="form-group">
                        <div className="js-form-message js-focus-state">
                          <div className="slide_field">
                            <span className="fas fa-user" />
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              id="signupName"
                              placeholder="Name"
                              aria-label="Name"
                              aria-describedby="signupNameLabel"
                              required
                              data-msg="Please enter your name"
                              data-error-class="u-has-error"
                              data-success-class="u-has-success"
                              value={loginData.name} // Use loginData.password as the value for the input field
                              onChange={handleInputChange} // Use handleInputChange to update loginData
                            />
                            {/* <label>Email Id</label> */}
                          </div>
                          {validationErrors.name && (
                            <p className="text-left text-danger">
                              {validationErrors.name}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="js-form-message js-focus-state">
                          <div className="slide_field">
                            <span className="fas fa-envelope" />
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              id="signupEmail"
                              placeholder="Email"
                              aria-label="Email"
                              aria-describedby="signupEmailLabel"
                              required
                              data-msg="Please enter a valid email address."
                              data-error-class="u-has-error"
                              data-success-class="u-has-success"
                              value={loginData.email} // Use loginData.password as the value for the input field
                              onChange={handleInputChange} // Use handleInputChange to update loginData
                            />
                            {/* <label>Email Id</label> */}
                          </div>
                          {validationErrors.email && (
                            <p className="text-left text-danger">
                              {validationErrors.email}
                            </p>
                          )}
                        </div>
                      </div>
                      {/* End Input */}
                      {/* Form Group */}
                      <div className="form-group">
                        <div className="js-form-message js-focus-state">
                          <div className="slide_field">
                            <span className="fas fa-lock" />
                            <input
                              type="password"
                              className="form-control"
                              name="password"
                              id="signupPassword"
                              placeholder="Password"
                              aria-label="Password"
                              aria-describedby="signupPasswordLabel"
                              required
                              data-msg="Your password is invalid. Please try again."
                              data-error-class="u-has-error"
                              data-success-class="u-has-success"
                              value={loginData.password} // Use loginData.password as the value for the input field
                              onChange={handleInputChange} // Use handleInputChange to update loginData
                            />
                            <label className="sr-only" htmlFor="signupPassword">
                              Password
                            </label>
                          </div>
                          {validationErrors.password && (
                            <p className="text-left text-danger">
                              {validationErrors.password}
                            </p>
                          )}
                        </div>
                      </div>
                      {/* End Input */}
                      {/* Form Group */}
                      <div className="form-group">
                        <div className="js-form-message js-focus-state">
                          <div className="slide_field">
                            <span className="fas fa-key" />
                            <input
                              type="password"
                              className="form-control"
                              name="password_confirmation"
                              id="signupConfirmPassword"
                              placeholder="Confirm Password"
                              aria-label="Confirm Password"
                              aria-describedby="signupConfirmPasswordLabel"
                              required
                              data-msg="Password does not match the confirm password."
                              data-error-class="u-has-error"
                              data-success-class="u-has-success"
                              value={loginData.password_confirmation}
                              onChange={handleInputChange}
                            />
                            <label
                              className="sr-only"
                              htmlFor="signupConfirmPassword"
                            >
                              Confirm Password
                            </label>
                          </div>
                          {validationErrors.password_confirmation && (
                            <p className="text-left text-danger">
                              {validationErrors.password_confirmation}
                            </p>
                          )}
                        </div>
                      </div>
                      <div
                        id="customize"
                        className="d-flex align-items-center my-4"
                      >
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="rem_me"
                            id="rem_check"
                          />{" "}
                          <label
                            className="form-check-label font-17 font-color"
                            htmlFor="rem_check"
                          >
                            {" "}
                            I Accept the{" "}
                            <Link to={"/terms-and-condition"}>
                              <strong>Terms and Conditions</strong>
                            </Link>{" "}
                            and{" "}
                            <Link to={"/privacy-policy"}>
                              <strong>Privacy Policy</strong>{" "}
                            </Link>
                          </label>
                        </div>
                      </div>
                      {/* End Input */}
                      <div className="mb-2">
                        <button
                          type="button"
                          className="btn btn-block btn-depth btn-sm lgn_btn transition-3d-hover"
                          onClick={handleReg}
                        >
                          Get Started
                        </button>
                      </div>
                      <div className="text-center mb-4">
                        <span className="small font-17 font-color">
                          Already have an account?
                        </span>
                        <button
                          type="button"
                          className="js-animation-link small  font-17 btn btn-depth signin_btn"
                          data-target="#login"
                          data-link-group="idForm"
                          data-animation-in="slideInUp"
                          onClick={(e) => {
                            e.preventDefault(); // Prevent the default link behavior
                            e.stopPropagation(); // Stop the event from propagating to the aside element
                            handleFormChange(1);
                          }}
                        >
                          SignIn
                        </button>
                      </div>
                      {/* <div className='text-center'>
                        <span className='u-divider u-divider--xs u-divider--text mb-4'>
                          OR
                        </span>
                      </div> */}
                      {/* Login Buttons */}
                      {/* <div
                        className={`d-flex justify-content-between d-none${
                          provider && profile ? 'hide' : ''
                        }`}
                      >
                        <LoginSocialFacebook
                          appId='637116931850898'
                          onResolve={({ provider, data }) => {
                            handleFacebookLogin(data);
                          }}
                          onReject={(err) => {
                            console.log(err);
                          }}
                        >
                          <button
                            type='button'
                            className='btn btn-block btn-sm btn-facebook-cus lgn_btn transition-3d-hover mr-1'
                          >
                            <span className='fab fa-facebook-square mr-1' />
                            Facebook
                          </button>
                        </LoginSocialFacebook>
                        <LoginSocialGoogle
                          client_id='223187416068-hafokug646hhki928f45h1lolsss8rm7.apps.googleusercontent.com'
                          onLogoutSuccess={handleLogout}
                          scope='openid profile email'
                          onResolve={({ provider, data }) => {
                            handleGoogleLogin(data);
                          }}
                          onReject={(err) => {
                            console.log(err);
                          }}
                        >
                          <button
                            type='button'
                            className='btn btn-block btn-sm btn-google-cus lgn_btn transition-3d-hover ml-1 mt-0'
                          >
                            <span className='fab fa-google mr-1' />
                            Google
                          </button>
                        </LoginSocialGoogle>

                      </div> */}
                      {/* End Login Buttons */}
                    </div>
                  )}
                  {/* End Signup */}
                  {/* Forgot Password */}
                  {activeForm === 3 && (
                    <div id="forgotPassword" data-target-group="idForm">
                      {/* Title */}
                      <header className="text-center mb-7">
                        <h2 className="h4 mb-0 font-color">Recover Account.</h2>
                        <p className="font-color">
                          Enter your email address and an email with
                          instructions will be sent to you.
                        </p>
                      </header>
                      {/* End Title */}
                      {/* Form Group */}

                      <div className="form-group">
                        <div className="js-form-message js-focus-state">
                          <div className="slide_field">
                            <span className="fas fa-user" />
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              id="email"
                              placeholder="Email"
                              aria-label="Email"
                              aria-describedby="email"
                              required
                              data-msg="Please enter a valid email address."
                              data-error-class="u-has-error"
                              data-success-class="u-has-success"
                              value={recoverEmail.email}
                              onChange={handleRecoverPassword}
                            />
                            {/* <label>Email Id</label> */}
                          </div>
                          {validationErrors.email && (
                            <p className="text-left text-danger">
                              {validationErrors.email}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="mb-2">
                        <button
                          type="button"
                          className="btn btn-block btn-sm btn-depth transition-3d-hover lgn_btn"
                          onClick={(e) => {
                            e.preventDefault(); // Prevent the default link behavior
                            e.stopPropagation(); // Stop the event from propagating to the aside element
                            handleForgotPwd();
                          }}
                        >
                          Recover Password
                        </button>
                      </div>
                      <div className="text-center mb-4">
                        <span className="small font-color font-17">
                          Remember your password?
                        </span>
                        <button
                          type="button"
                          className="js-animation-link small  btn btn-depth signin_btn font-color"
                          data-target="#signup"
                          data-link-group="idForm"
                          data-animation-in="slideInUp"
                          onClick={(e) => {
                            e.preventDefault(); // Prevent the default link behavior
                            e.stopPropagation(); // Stop the event from propagating to the aside element
                            handleFormChange(1);
                          }}
                        >
                          SignIn
                        </button>
                      </div>
                    </div>
                  )}
                  {/* End Forgot Password */}
                </form>
              </div>
            </div>
            {/* End Content */}
          </div>
        </div>
      </div>
    </aside>
  );
};

export default SideBar;
