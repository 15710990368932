import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Api from "../../../helpers/Api";
import { Slide } from "react-slideshow-image";
import { ToastError, ToastSuccess } from "../../../helpers/Toast";

const AccessoryProductPreview = () => {
  const { ProductId, AccessoryName } = useParams();
  const [ProductImages, setProductImages] = useState([]);
  const [ProductOptions, setProductOptions] = useState([]);
  const [product, setProduct] = useState({});
  const [isSkinBrandRelated, setIsSkinBrandRelated] = useState(false);
  const [formattedCategoryName, setFormattedCategoryName] = useState("");
  const [defaultOption, setDefaultOption] = useState({});
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [finalPrice, setFinalPrice] = useState(0);
  const [selectedChoices, setSelectedChoices] = useState({});
  useEffect(() => {
    Api.AccessoryProductPreview(ProductId)
      .then((res) => {
        const {
          product_images,
          options_with_choices,
          product,
          category,
          default_option,
          default_choices_with_related_products,
        } = res.data;
        setProductImages(product_images);
        setProduct(product);
        setFinalPrice(parseInt(product.price));
        setIsSkinBrandRelated(category.is_skin_related === 1 ? true : false);
        setDefaultOption(default_option);
        setProductOptions(options_with_choices);
        setRelatedProducts(default_choices_with_related_products);
        setFormattedCategoryName(
          category.category_name?.replace(/\s/g, "-").toLowerCase()
        );
        // Initialize selectedChoices with default choices from the API data
        const initialSelectedChoices = {};
        options_with_choices.forEach((option) => {
          const defaultChoice = option.choices.find(
            (choice) => choice.is_default === 1
          );
          if (defaultChoice) {
            initialSelectedChoices[option.option_id] = defaultChoice.id;
            // Add the price of the default choice to the finalPrice
            const defaultChoicePrice = parseInt(defaultChoice.price) || 0;
            setFinalPrice(
              (prevFinalPrice) => prevFinalPrice + defaultChoicePrice
            );
          }
        });
        setSelectedChoices(initialSelectedChoices);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally();
  }, [ProductId, AccessoryName]);
  const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    arrows: false,
  };
  // Function to handle choice selection for a specific option
  const handleChoiceSelection = (optionId, choiceId, price) => {
    // Get the previously selected choice for the same option
    const previousChoiceId = selectedChoices[optionId];
    // Calculate and update the finalPrice based on the new choice and deducting the previous choice
    setFinalPrice((prevFinalPrice) => {
      // Get the option object
      const option = ProductOptions.find(
        (opt) => opt.option_id === parseInt(optionId)
      );

      let newPrice = prevFinalPrice;

      // Deduct the price of the previously selected choice if it exists
      if (previousChoiceId) {
        const previousChoice = option.choices.find(
          (choice) => choice.id === previousChoiceId
        );
        if (previousChoice) {
          newPrice -= parseInt(previousChoice.price);
        }
      }

      // Add the price of the newly selected choice
      newPrice += parseInt(price);

      // Update the selected choice for the specified option
      setSelectedChoices((prevSelectedChoices) => ({
        ...prevSelectedChoices,
        [optionId]: choiceId,
      }));

      return newPrice;
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Create a new FormData object from the form element
    const formSubmitData = new FormData(e.target);
    // const selectedChoiceMapIds = Object.values(selectedChoices);
    // Convert selectedChoices to the desired format
    const selectedChoiceMap = {};
    for (const optionId in selectedChoices) {
      selectedChoiceMap[optionId] = selectedChoices[optionId];
    }
    formSubmitData.append("product_id", ProductId);
    // Convert the selectedChoiceMap object to a JSON string
    const selectedChoiceMapJSON = JSON.stringify(selectedChoiceMap);

    // Append the JSON string to the FormData object
    formSubmitData.append("selected_choices", selectedChoiceMapJSON);
    Api.AccessoryProductAddToCart(formSubmitData)
      .then((response) => {
        if (response.status === 200) {
          ToastSuccess("Product Added to cart successfully!!!");
          setTimeout(() => {
            window.location.href = "/cart";
          }, 6000);
        } else {
          ToastError("Oops! Something went wrong.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="container">
      <div className="row my-5">
        <div className="col-md-5 col-12 pro_det_step mt-5">
          <div className="pro_det_img slide-container">
            {ProductImages.length > 0 && (
              <Slide {...properties}>
                {ProductImages.map((ProductImage) => (
                  <div className="each-slide-effect" key={ProductImage.id}>
                    <img
                      src={ProductImage.img_path}
                      alt="Skin Images"
                      className="img-fluid"
                    />
                  </div>
                ))}
              </Slide>
            )}
          </div>
        </div>

        <div className="col-md-7 col-12">
          <form method="post" className="mt-5" onSubmit={handleSubmit}>
            <div className="skin_box scroll px-5">
              <h4 className=" font-color ">{product?.product_name}</h4>
              {defaultOption && (
                <>
                  <h6 className="font-color">
                    <strong>{defaultOption.option_name}</strong>
                  </h6>
                  <div className="row my-5">
                    {relatedProducts.map((each_choice) => (
                      <div
                        className="col-md-2 col-6"
                        key={each_choice.choice_id}
                      >
                        <div style={{ width: "100px" }} className="text-center">
                          <div className="thumbnail_img">
                            <Link
                              to={`/accessories/${formattedCategoryName}/${each_choice.product_id}`}
                              className="font-size-12 text-gray-5"
                            >
                              {each_choice.img_path !== null && (
                                <img
                                  src={each_choice.img_path}
                                  alt="skin"
                                  className={`img-fluid ${
                                    each_choice.product_id ===
                                    parseInt(ProductId)
                                      ? "active_skin"
                                      : ""
                                  }`}
                                />
                              )}
                              <div className="border border-primary">
                                <p>{each_choice.choice_name}</p>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
              {ProductOptions.map((each_option) => (
                <div className="my-5" key={each_option.option_id}>
                  <h6 className="font-color">
                    <strong>{each_option.option_name}</strong>
                  </h6>

                  <div className="row">
                    {each_option.choices.map((each_choice) => (
                      <div className="col-md-2 col-6" key={each_choice.id}>
                        <div style={{ width: "100px" }} className="text-center">
                          <div
                            className={`text-center thumbnail_img border ${
                              selectedChoices[each_option.option_id] ===
                              each_choice.id
                                ? "border-success"
                                : "border-primary"
                            }`}
                            onClick={() =>
                              handleChoiceSelection(
                                each_option.option_id,
                                each_choice.id,
                                each_choice.price
                              )
                            }
                          >
                            {each_choice.choice_img !== null ? (
                              <img
                                src={each_choice.choice_img}
                                alt="skin"
                                className={`img-fluid active_skin`}
                              />
                            ) : (
                              <div className={`border`}>
                                <p>{each_choice.choice_name}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              <div className="row s-bottom cart-btn  align-items-center justify-content-between border-top border-color-1">
                <div className="col-md-6 col-7  ">
                  <h4 className="font-color ">
                    <strong> Price ₹ {finalPrice} </strong>
                  </h4>
                </div>
                <div className="col-md-6  col-5 text-right mb-2 mt-2 ">
                  <button
                    type="submit"
                    className="btn  theme_flt_btn btn-depth"
                    // onClick={handleSubmit}
                  >
                    Add To Cart
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {product.des !== null ? (
        <div className="row mx-5 mt-5 each-pro-desc">
          <h4 className="font-color">
            <strong>Product description</strong>
          </h4>
          <div
            className="col-md-12"
            dangerouslySetInnerHTML={{ __html: product.des }}
          ></div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default AccessoryProductPreview;
