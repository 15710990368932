import React from 'react';

const AddToCart = ({
  handleAddCart,
  addToCartData,
  skinRelatedKit,
  deviceRelatedKit,
  categoryRelatedKit,
  finishes,
  activeSkin,
  thumbnail,
}) => {
  const skinProduct = skinRelatedKit.find(
    (product) => product.id === addToCartData.skin_related_kit
  );
  const Finish = finishes.find(
    (finish) => finish.id === addToCartData.finish_id
  );
  const calculateTotalPrice = () => {
    let totalPrice = 0;
    totalPrice = parseInt(Finish.reg_price);
    // Calculate the total price of skin-related kit
    // if (skinProduct) {
    //   totalPrice = parseInt(skinProduct.price);
    // }

    // Calculate the total price of device-related kit
    addToCartData.device_extra_items.forEach((d_related_item_id) => {
      const product = deviceRelatedKit.find(
        (product) => product.id === d_related_item_id
      );
      if (product) {
        totalPrice += parseInt(product.price);
      }
    });

    // Calculate the total price of category-related kit
    addToCartData.category_extra_items.forEach((c_related_item_id) => {
      const product = categoryRelatedKit.find(
        (product) => product.id === c_related_item_id
      );
      if (product) {
        totalPrice += parseInt(product.price);
      }
    });

    return totalPrice; // Format the total price to two decimal places
  };

  return (
    <>
      <div className='col-md-12 add-cart'>
        <div className=' products-details mr-5'>
          <h6 className='font-color'>Products</h6>

          {Finish && (
            <>
              <div className='row  align-items-center '>
                <div className='col-md-4 col-4 d-flex justify-content-around'>
                  <div className='skin_box_img  '>
                    <img
                      src={activeSkin.skin_img}
                      alt={thumbnail.skin_name}
                      className='img-fluid p-2'
                    />
                  </div>
                </div>
                <div className='col-md-5 col-4'>
                  <h6 className='font-color text-center'>
                    {thumbnail.skin_name}
                  </h6>
                </div>
                <div className='col-md-3 col-5 py-sm-5 d-none'>
                  <div className='js-quantity row align-items-center justify-content-center'>
                    {/* <h6 className='font-color text-center'>Quantity</h6> */}
                    <div className='d-flex cart_col_value m-0'>
                      <button
                        className='qty_btn btn  btn-depth text-secondary btn-depth'
                        // onClick={handleDecrement}
                      >
                        <small className='fas fa-minus btn-icon__inner' />
                      </button>

                      <div className='qty_count ml-2'>
                        <input
                          className='qty_input js-result form-control h-auto border-0 rounded p-0 shadow-none'
                          type='text'
                          // value={quantity}
                          readOnly
                        />
                      </div>
                      <button
                        className=' ml-2 btn  btn-depth text-secondary qty_btn btn-depth'
                        // onClick={handleIncrement}
                      >
                        <small className='fas fa-plus btn-icon__inner_1' />
                      </button>
                    </div>
                  </div>
                </div>
                <div className='col-md-3 col-4 text-center'>
                  {/* <h6 className='font-color text-center'>Total</h6> */}
                  {/* <p className='cart_col_value'>{quantity * reg_price}</p> */}
                  <span className='font-color font-17'>
                    (Item Price : <strong>₹ {Finish.reg_price}</strong> )
                  </span>
                </div>
                <div className='col-md-1 col-2 detail-page-remove d-none'>
                  <span
                    className='text-gray-32 font-size-26 cart-remove'
                    // onClick={handleRemove}
                  >
                    <i className='fa fa-trash-alt'> </i>
                  </span>
                </div>
              </div>
              <hr />
            </>
          )}
          {skinProduct && (
            <>
              <div className='row  align-items-center '>
                <div className='col-md-4 col-4 d-flex justify-content-around'>
                  <div className='skin_box_img '>
                    <img
                      src={skinProduct.img_path}
                      alt={skinProduct.heading}
                      className='img-fluid p-2'
                    />
                  </div>
                </div>
                <div className='col-md-5 col-4'>
                  <h6 className='font-color text-center'>
                    {skinProduct.heading}
                    {/* <br /> */}
                  </h6>
                </div>
                <div className='col-md-3 col-5 py-sm-5 d-none'>
                  <div className='js-quantity row align-items-center justify-content-center'>
                    {/* <h6 className='font-color text-center'>Quantity</h6> */}
                    <div className='d-flex cart_col_value m-0'>
                      <button
                        className='qty_btn btn  btn-depth text-secondary btn-depth'
                        // onClick={handleDecrement}
                      >
                        <small className='fas fa-minus btn-icon__inner' />
                      </button>

                      <div className='qty_count ml-2'>
                        <input
                          className='qty_input js-result form-control h-auto border-0 rounded p-0 shadow-none'
                          type='text'
                          // value={quantity}
                          readOnly
                        />
                      </div>
                      <button
                        className=' ml-2 btn  btn-depth text-secondary qty_btn btn-depth'
                        // onClick={handleIncrement}
                      >
                        <small className='fas fa-plus btn-icon__inner_1' />
                      </button>
                    </div>
                  </div>
                </div>
                <div className='col-md-3 col-4 text-center'>
                  <span className='font-color font-17'>
                    (Item Price : <strong>₹ {skinProduct.price}</strong> )
                  </span>
                </div>
                <div className='col-md-1 col-2 detail-page-remove d-none'>
                  <span
                    className='text-gray-32 font-size-26 cart-remove'
                    // onClick={handleRemove}
                  >
                    <i className='fa fa-trash-alt'> </i>
                  </span>
                </div>
              </div>
              <hr />
            </>
          )}
          {addToCartData.device_extra_items.map((d_related_item_id) => {
            const product = deviceRelatedKit.find(
              (product) => product.id === d_related_item_id
            );
            // Make sure a valid product was found before proceeding
            if (!product) {
              return null; // Skip this iteration if product is not found
            }

            return (
              <>
                <div
                  className='row  align-items-center'
                  key={d_related_item_id}
                >
                  <div className='col-md-4 col-4 d-flex justify-content-around'>
                    <div className='skin_box_img '>
                      <img
                        src={product.img_path}
                        alt={product.heading}
                        className='img-fluid p-2'
                      />
                    </div>
                  </div>
                  <div className='col-md-5 col-4'>
                    <h6 className='font-17 font-color text-center'>
                      {product.heading}
                    </h6>
                  </div>
                  <div className='col-md-3 col-5 py-sm-5 d-none'>
                    <div className='js-quantity row align-items-center justify-content-center'>
                      {/* <h6 className='font-color text-center'>Quantity</h6> */}
                      <div className='d-flex cart_col_value m-0'>
                        <button
                          className='qty_btn btn  btn-depth text-secondary btn-depth'
                          // onClick={handleDecrement}
                        >
                          <small className='fas fa-minus btn-icon__inner' />
                        </button>

                        <div className='qty_count ml-2'>
                          <input
                            className='qty_input js-result form-control h-auto border-0 rounded p-0 shadow-none'
                            type='text'
                            // value={quantity}
                            readOnly
                          />
                        </div>
                        <button
                          className=' ml-2 btn  btn-depth text-secondary qty_btn btn-depth'
                          // onClick={handleIncrement}
                        >
                          <small className='fas fa-plus btn-icon__inner_1' />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-3 col-4 text-center'>
                    {/* <h6 className='font-color text-center'>Total</h6> */}
                    {/* <p className='cart_col_value'>{quantity * reg_price}</p> */}
                    <span className='font-color font-17'>
                      (Item Price : <strong>₹ {product.price}</strong> )
                    </span>
                  </div>
                  <div className='col-md-1 col-2 detail-page-remove d-none'>
                    <span
                      className='text-gray-32 font-size-26 cart-remove'
                      // onClick={handleRemove}
                    >
                      <i className='fa fa-trash-alt'> </i>
                    </span>
                  </div>
                </div>
                <hr />
              </>
            );
          })}
          {addToCartData.category_extra_items.map((c_related_item_id) => {
            const product = categoryRelatedKit.find(
              (product) => product.id === c_related_item_id
            );
            if (!product) {
              return null;
            }

            return (
              <>
                <div className='row align-items-center' key={c_related_item_id}>
                  <div className='col-md-4 col-4 d-flex justify-content-around'>
                    <div className='skin_box_img'>
                      <img
                        src={product.img_path}
                        alt={product.heading}
                        className='img-fluid p-2'
                      />
                    </div>
                  </div>
                  <div className='col-md-5 col-4'>
                    <h6 className='font-17 font-color text-center'>
                      {product.heading}
                    </h6>
                  </div>
                  <div className='col-md-3 col-5 py-sm-5 d-none'>
                    <div className='js-quantity row align-items-center justify-content-center'>
                      {/* <h6 className='font-color text-center'>Quantity</h6> */}
                      <div className='d-flex cart_col_value m-0'>
                        <button
                          className='qty_btn btn  btn-depth text-secondary btn-depth'
                          // onClick={handleDecrement}
                        >
                          <small className='fas fa-minus btn-icon__inner' />
                        </button>

                        <div className='qty_count ml-2'>
                          <input
                            className='qty_input js-result form-control h-auto border-0 rounded p-0 shadow-none'
                            type='text'
                            // value={quantity}
                            readOnly
                          />
                        </div>
                        <button
                          className=' ml-2 btn  btn-depth text-secondary qty_btn btn-depth'
                          // onClick={handleIncrement}
                        >
                          <small className='fas fa-plus btn-icon__inner_1' />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-3 col-4 text-center'>
                    <span className='font-color font-17'>
                      (Item Price : <strong>₹ {product.price}</strong> )
                    </span>
                  </div>
                  <div className='col-md-1 col-2 detail-page-remove d-none'>
                    <span
                      className='text-gray-32 font-size-26 cart-remove'
                      // onClick={handleRemove}
                    >
                      <i className='fa fa-trash-alt'> </i>
                    </span>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
      <div className='row align-items-center justify-content-between ac-btn border-top border-color-1 '>
        <div className='col-md-6 col-6'>
          <h6 className='font-color'>
            Total Price <strong>₹ {calculateTotalPrice()}</strong>
          </h6>
        </div>
        <div className='col-md-6 col-6 mb-2 mt-2 text-right '>
          <button
            type='button'
            className='btn  theme_flt_btn btn-depth'
            onClick={handleAddCart}
          >
            Add To Cart
          </button>
        </div>
      </div>
    </>
  );
};

export default AddToCart;
