import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Api from "../../../helpers/Api";
import { ToastError, ToastSuccess } from "../../../helpers/Toast";
import { Slide } from "react-slideshow-image";

const AcrylicPreviewPage = () => {
  const { AcrylicId } = useParams();
  const [ProductImages, setProductImages] = useState([]);
  const [ProductOptions, setProductOptions] = useState([]);
  const [product, setProduct] = useState({});
  const [finalPrice, setFinalPrice] = useState(0);
  const [selectedChoices, setSelectedChoices] = useState({});
  useEffect(() => {
    Api.AcrylicProductPreview(AcrylicId)
      .then((res) => {
        const { product_images, options_with_choices, product } = res.data;
        setProductImages(product_images);
        setProduct(product);
        setFinalPrice(parseInt(product.price));
        setProductOptions(options_with_choices);
        // Initialize selectedChoices with default choices from the API data
        const initialSelectedChoices = {};
        // options_with_choices.forEach((option) => {
        //   const defaultChoice = option.choices.find(
        //     (choice) => choice.is_default === 1
        //   );
        //   if (defaultChoice) {
        //     initialSelectedChoices[option.option_id] = defaultChoice.id;
        //     // Add the price of the default choice to the finalPrice
        //     const defaultChoicePrice = parseInt(defaultChoice.price) || 0;
        //     setFinalPrice(
        //       (prevFinalPrice) => prevFinalPrice + defaultChoicePrice
        //     );
        //   }
        // });
        setSelectedChoices(initialSelectedChoices);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally();
  }, [AcrylicId]);
  const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    arrows: false,
  };
  // Function to handle choice selection for a specific option
  const handleChoiceSelection = (optionId, choiceId, price) => {
    // Get the previously selected choice for the same option
    const previousChoiceId = selectedChoices[optionId];
    // Calculate and update the finalPrice based on the new choice and deducting the previous choice
    setFinalPrice((prevFinalPrice) => {
      // Get the option object
      const option = ProductOptions.find(
        (opt) => opt.option_id === parseInt(optionId)
      );

      let newPrice = prevFinalPrice;

      // Deduct the price of the previously selected choice if it exists
      if (previousChoiceId) {
        const previousChoice = option.choices.find(
          (choice) => choice.id === previousChoiceId
        );
        if (previousChoice) {
          newPrice -= parseInt(previousChoice.price);
        }
      }

      // Add the price of the newly selected choice
      newPrice += parseInt(price);

      // Update the selected choice for the specified option
      setSelectedChoices((prevSelectedChoices) => ({
        ...prevSelectedChoices,
        [optionId]: choiceId,
      }));

      return newPrice;
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Create a new FormData object from the form element
    const formSubmitData = new FormData(e.target);
    // Append the price from nfcCard
    formSubmitData.append("product_id", AcrylicId);
    // Append the selected choices to the FormData
    // console.log(selectedChoices);
    // for (const optionId in selectedChoices) {
    //   formSubmitData.append(
    //     `selected_choices[${optionId}]`,
    //     selectedChoices[optionId]
    //   );
    // }
    // Convert the selectedChoiceMap object to a JSON string
    const selectedChoiceMapJSON = JSON.stringify(selectedChoices);
    // Append the JSON string to the FormData object
    formSubmitData.append("selected_choices", selectedChoiceMapJSON);
    Api.AcrylicProductAddToCart(formSubmitData)
      .then((response) => {
        if (response.status === 200) {
          ToastSuccess("Product Added to cart successfully!!!");
          window.location.href = "/cart";
        } else {
          ToastError("Oops! Something went wrong.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="container">
      <div className="row my-2">
        <div className="col-md-5 col-12 pro_det_step mt-5">
          <div className="pro_det_img slide-container">
            {ProductImages.length > 0 && (
              <Slide {...properties}>
                {ProductImages.map((ProductImage) => (
                  <div className="each-slide-effect" key={ProductImage.id}>
                    <img
                      src={ProductImage.img_path}
                      alt="Skin Images"
                      className="img-fluid"
                    />
                  </div>
                ))}
              </Slide>
            )}
          </div>
        </div>
        <div className="col-md-7 col-12">
          <form method="post" className="mt-5" onSubmit={handleSubmit}>
            <div className="skin_box scroll px-5">
              <h4 className=" font-color ">{product?.product_name}</h4>
              <div className="custom-file ">
                <input
                  type="file"
                  className="custom-file-input"
                  id="uploaded_img"
                  name="uploaded_img"
                  required
                />
                <label className="custom-file-label" htmlFor="uploaded_img">
                  Choose file
                </label>
              </div>
              {/* <div className="slide_field col-md-12">
                <input
                  type="file"
                  className="form-control"
                  name="uploaded_img"
                  required
                />
              </div> */}
              {ProductOptions.map((each_option) => (
                <div
                  className="row align-items-center mx-5 my-5"
                  key={each_option.option_id}
                >
                  <div className="col-md-2 col-2">
                    <h6 className="font-color">
                      <strong>{each_option.option_name}</strong>
                    </h6>
                  </div>
                  <div className="col-md-10 col-10">
                    <div className="row justify-content-between acrylic-img">
                      {each_option.choices.map((each_choice) => (
                        <div className="col-md-2 col-6 " key={each_choice.id}>
                          <div
                            style={{ width: "100px" }}
                            className="text-center "
                          >
                            <div
                              className={`text-center thumbnail_img border ${
                                selectedChoices[each_option.option_id] ===
                                each_choice.id
                                  ? "border-success"
                                  : "border-primary"
                              }`}
                              onClick={() =>
                                handleChoiceSelection(
                                  each_option.option_id,
                                  each_choice.id,
                                  each_choice.price
                                )
                              }
                            >
                              {each_choice.choice_img !== null ? (
                                <img
                                  src={each_choice.choice_img}
                                  alt="skin"
                                  className={`img-fluid active_skin`}
                                />
                              ) : (
                                <div className="">
                                  <p className="mt-3">
                                    {each_choice.choice_name}
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
              <div className="row s-bottom cart-btn  align-items-center justify-content-between border-top border-color-1">
                <div className="col-md-6 col-7  ">
                  <h4 className="font-color ">
                    <strong> Price ₹ {finalPrice} </strong>
                  </h4>
                </div>
                <div className="col-md-6  col-5 text-right mb-2 mt-2 ">
                  <button
                    type="submit"
                    className="btn  theme_flt_btn btn-depth"
                    // onClick={handleSubmit}
                  >
                    Add To Cart
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {product.des !== null ? (
        <div className="row mx-5 mt-5 each-pro-desc">
          <h4 className="font-color">
            <strong>Product description</strong>
          </h4>
          <div
            className="col-md-12 col-12"
            dangerouslySetInnerHTML={{ __html: product.des }}
          ></div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default AcrylicPreviewPage;
