import React from "react";
import { Link } from "react-router-dom";
const EachProductCard = ({
  skin_img,
  skin_name,
  category_name,
  id,
  reg_price,
  ofr_price,
}) => {
  // Replace whitespace with "-" and convert to lowercase
  const formattedCategoryName = category_name
    ?.replace(/\s/g, "-")
    .toLowerCase();
  return (
    <div className="col-md-2 col-6">
      <div className="p-card ">
        <Link
          to={`/${formattedCategoryName}/${id}`}
          className="font-size-12 text-gray-5"
        >
          <div className="content">
            <div className="front cat_img">
              <img src={skin_img} alt={skin_name} className="img-fluid " />
            </div>

            <div className="back from-bottom">
              <div className="row pl-3 mt-3  d-flex justify-content-between align-items-center price-card p-2">
                {/* <div className=" mb-3  product-btn d-flex align-items-center"> */}
                <div className="price-c" style={{ cursor: "pointer" }}>
                  {skin_name}
                </div>
                <div className=" price-c">₹ {reg_price}</div>
                <div>
                  <i className="fa fa-magnifying-glass-arrow-right text-secondary"></i>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default EachProductCard;
