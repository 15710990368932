import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Slide } from "react-slideshow-image";
import Api from "../../helpers/Api";
import { ToastError, ToastSuccess } from "../../helpers/Toast";

const PhoneCasePreview = () => {
  const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    arrows: false,
  };
  const { ProductId } = useParams();
  const [phoneCase, setPhoneCase] = useState({});
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [finalPrice, setFinalPrice] = useState(0);
  useEffect(() => {
    Api.PhoneCaseProductDetail(ProductId)
      .then((res) => {
        const { phoneCase, related_products } = res.data;
        setPhoneCase(phoneCase);
        setRelatedProducts(related_products);
        setFinalPrice(parseInt(phoneCase?.price));
      })
      .catch((e) => {
        console.log(e);
      });
  }, [ProductId]);
  const handleSubmit = (e) => {
    e.preventDefault();

    // Create a new FormData object from the form element
    const formSubmitData = new FormData(e.target);

    // Append the price from nfcCard
    formSubmitData.append("product_id", ProductId);

    Api.PhoneCaseAddToCart(formSubmitData)
      .then((response) => {
        // console.log(response);
        if (response.status === 200) {
          ToastSuccess("Product Added to cart successfully!!!");
          formSubmitData.delete("product_id"); // Remove the 'frame_id' field
          e.target.reset(); // Reset the form
          setTimeout(() => {
            window.location.href = "/cart";
          }, 6000);
        } else {
          ToastError("Oops! Something went wrong.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="container" id="nfc">
      <div className="row ">
        <div className="col-md-5 col-12 pro_det_step mt-2">
          <div className="pro_det_img slide-container">
            {phoneCase?.images?.length > 0 && (
              <Slide {...properties}>
                {phoneCase?.images.map((each_img) => (
                  <div className="each-slide-effect" key={each_img.id}>
                    <img src={each_img.img_path} alt="Skin Images" />
                  </div>
                ))}
              </Slide>
            )}
          </div>
        </div>
        <div className="col-md-7 col-12">
          <div className="skin_box scroll p-5">
            <h4 className="font-color text-left">
              {phoneCase?.device?.model_no}({phoneCase?.brand?.brand_name})
            </h4>

            {relatedProducts.length > 1 && (
              <h4 className="font-color text-left">Colors</h4>
            )}

            <div className="row my-5">
              {relatedProducts.map((relatedProduct) => (
                <div className="col-md-2 col-6" key={relatedProduct.id}>
                  <div style={{ width: "100px" }} className="text-center">
                    <div className="thumbnail_img">
                      <Link
                        to={`/phone-case/${relatedProduct.id}`}
                        className="font-size-12 text-gray-5"
                      >
                        <img
                          src={relatedProduct.default_image.img_path}
                          alt="skin"
                          className={`img-fluid ${
                            relatedProduct.id === parseInt(ProductId)
                              ? "active_skin"
                              : ""
                          }`}
                        />
                        <div className="">
                          <p>{relatedProduct.product_name}</p>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <form
              encType="multipart/form-data"
              method="post"
              className="mt-5"
              onSubmit={handleSubmit}
            >
              <div className="row s-bottom cart-btn  align-items-center justify-content-between border-top border-color-1">
                <div className="col-md-6 col-7  ">
                  <h4 className="font-color ">
                    <strong> Price ₹ {finalPrice} </strong>
                  </h4>
                </div>
                <div className="col-md-6  col-5 text-right mb-2 mt-2 ">
                  {phoneCase && (
                    <button
                      type="submit"
                      className="btn  theme_flt_btn btn-depth"
                      // onClick={handleSubmit}
                    >
                      Add To Cart
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneCasePreview;
