import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Api from '../../helpers/Api';
import NormalSelect from '../../components/NormalSelect';
import AuthContext from '../../context/AuthContext';
import { showSuccessAlert } from '../../helpers/SweetAlert';
import CategoryRelatedProducts from '../detailpage/CategoryRelatedProducts';
import { LoaderContext } from '../../context/LoaderContext';
import PreLoader from '../../components/PreLoader';

const CouplePreview = () => {
  const { isLoading, setIsLoading } = useContext(LoaderContext);
  const { user } = useContext(AuthContext);
  const { skinId } = useParams();
  const navigate = useNavigate();
  const [brands, setBrands] = useState([]);
  const [devices, setDevices] = useState([]);
  const [finishes, setFinishes] = useState([]);
  const [activeSkin, setActiveSkin] = useState(null);
  const [brand1BasedDevices, setBrand1BasedDevices] = useState([]);
  const [brand2BasedDevices, setBrand2BasedDevices] = useState([]);
  const [categoryRelatedKit, setCategoryRelatedKit] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const addToCartDataInit = {
    skin_id: activeSkin?.id,
    device1_id: null,
    device1_name: null,
    device2_id: null,
    device2_name: null,
    finish_id: null,
    category_extra_items: [],
  };
  const [addToCartData, setAddToCartData] = useState(addToCartDataInit);
  useEffect(() => {
    setIsLoading(true);
    Api.CouplePreviewPage(skinId)
      .then((res) => {
        const { skin, brands, devices, finishes, category_related_kit_parts } =
          res.data;
        setActiveSkin(skin);
        setBrands(brands);
        setDevices(devices);
        setFinishes(finishes);
        setCategoryRelatedKit(category_related_kit_parts);
        setAddToCartData((prev) => ({ ...prev, skin_id: skin.id }));
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setIsLoading(false));
  }, [skinId, setIsLoading]);

  const nextStep = () => {
    let next_step = currentStep + 1;
    if (next_step === 2) {
      next_step = categoryRelatedKit.length > 0 ? next_step : next_step + 1;
    }
    setCurrentStep(next_step);
  };
  const prevStep = () => {
    let prev_step = currentStep - 1;
    if (prev_step === 2) {
      prev_step = categoryRelatedKit.length > 0 ? prev_step : prev_step - 1;
    }
    setCurrentStep(prev_step);
  };
  const handleBrandChange = (selectedOption, setBrandBasedDevices) => {
    if (selectedOption) {
      const filteredDevices = devices
        .filter((d) => d.brand_id === selectedOption.value)
        .map((d) => ({
          value: d.id,
          label: d.model_no,
          is_custom: d.is_custom,
          skin_id: d.skin_id,
        }));
      setBrandBasedDevices(filteredDevices);
    } else {
      setBrandBasedDevices([]);
    }
  };
  const handleDeviceChange = (selectedOption, deviceType) => {
    if (selectedOption) {
      setAddToCartData((prev) => ({
        ...prev,
        [`${deviceType}_id`]: selectedOption.value,
        [`${deviceType}_name`]: selectedOption.label,
      }));
    } else {
      setAddToCartData((prev) => ({
        ...prev,
        [`${deviceType}_id`]: null,
        [`${deviceType}_name`]: null,
      }));
    }
  };
  const handleCategoryKit = (isChecked, itemId) => {
    setAddToCartData((prevData) => ({
      ...prevData,
      category_extra_items: isChecked
        ? [...prevData.category_extra_items, itemId]
        : prevData.category_extra_items.filter((id) => id !== itemId),
    }));
  };
  const handleFinishType = (itemId) => {
    setAddToCartData((prevData) => ({
      ...prevData,
      finish_id: itemId,
    }));
  };

  const handleAddCart = (e) => {
    const requestBody = JSON.stringify(addToCartData);
    Api.CoupleSkinAddToCart(requestBody)
      .then((res) => {
        showSuccessAlert('Couple Skin Added In Your Cart SuccessFully');
        if (!user.apiToken) {
          localStorage.setItem('apiToken', res.data.token);
          localStorage.setItem('userName', 'Guest User');
          localStorage.setItem('userId', '0');
          window.location.href = '/cart';
        } else {
          navigate('/cart');
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const Finish = finishes.find(
    (finish) => finish.id === addToCartData.finish_id
  );
  const calculateTotalPrice = () => {
    let totalPrice = 0;
    totalPrice = parseInt(Finish.reg_price);

    // Calculate the total price of category-related kit
    addToCartData.category_extra_items.forEach((c_related_item_id) => {
      const product = categoryRelatedKit.find(
        (product) => product.id === c_related_item_id
      );
      if (product) {
        totalPrice += parseInt(product.price);
      }
    });

    return totalPrice; // Format the total price to two decimal places
  };
  return (
    <>
      {isLoading ? (
        <PreLoader />
      ) : (
        <div className='container p-2'>
          <div className='row'>
            <div className='col-md-2 col-2'>
              <button
                className='btn back_btn btn-depth'
                onClick={() => navigate(-1)}
              >
                <span
                  style={{
                    padding: '11px 13px ',
                    margin: '0px 10px 0px -20px',
                  }}
                >
                  <i className='fa fa-backward' aria-hidden='true'></i>
                </span>
                Back
              </button>
            </div>

            <div className='col-md-10 col-10 text-right'>
              {currentStep > 1 && (
                <button className='prev_btn btn btn-depth' onClick={prevStep}>
                  <i className='fa fa-angles-left'></i>
                </button>
              )}
              {currentStep < 3 && (
                <button className='next_btn btn btn-depth' onClick={nextStep}>
                  <span className='circle-animation'></span>
                  <i className='fa fa-angles-right'></i>
                </button>
              )}
            </div>
          </div>
          <div className='row '>
            <div className='col-md-4 '>
              <div className='row '>
                <div className='col-md-6 col-6 '>
                  <div className='mt-2'>
                    <img src={activeSkin?.part1_skin_img} alt='Couples Skins' />
                  </div>
                </div>
                <div className='col-md-6 col-6'>
                  <div className='mt-2 '>
                    <img src={activeSkin?.part2_skin_img} alt='Couples Skins' />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-8 col-12'>
              <div className=' skin_box scroll' style={{ fontSize: 14 }}>
                {currentStep === 1 ? (
                  <>
                    <h4 className='font-color  my-1'>Select Devices</h4>

                    <div className='row  my-1'>
                      <div className='col-md-2 col-12'>
                        <p> select Device 1</p>
                      </div>

                      <div className='col-md-5 col-6 my-2'>
                        <NormalSelect
                          options={brands.map((b) => ({
                            value: b.id,
                            label: b.brand_name,
                          }))}
                          placeholder='Select Brand'
                          onChange={(selectedOption) =>
                            handleBrandChange(
                              selectedOption,
                              setBrand1BasedDevices
                            )
                          }
                        />
                      </div>
                      <div className='col-md-5 col-6 my-2'>
                        <NormalSelect
                          options={brand1BasedDevices}
                          placeholder='Select Model'
                          onChange={(selectedOption) =>
                            handleDeviceChange(selectedOption, 'device1')
                          }
                        />
                      </div>
                    </div>
                    <div className='row  my-1'>
                      <div className='col-md-2 col-12'>
                        <p> select Device 2</p>
                      </div>
                      <div className='col-md-5 col-6 my-2'>
                        <NormalSelect
                          options={brands.map((b) => ({
                            value: b.id,
                            label: b.brand_name,
                          }))}
                          placeholder='Select Brand'
                          onChange={(selectedOption) =>
                            handleBrandChange(
                              selectedOption,
                              setBrand2BasedDevices
                            )
                          }
                        />
                      </div>
                      <div className='col-md-5 col-6 my-2'>
                        <NormalSelect
                          options={brand2BasedDevices}
                          placeholder='Select Model'
                          onChange={(selectedOption) =>
                            handleDeviceChange(selectedOption, 'device2')
                          }
                        />
                      </div>
                    </div>
                    {/* <div className='mt-5'>
                      <h4 className='font-color'>Choose Finish Types</h4>

                      {finishes.map((finish) => (
                        <div
                          className='row d-flex align-items-center'
                          key={finish.id}
                        >
                          <div className='col-md-7'>
                            <p className='font-17 font-color text-left'>
                              {finish.finish_name}
                            </p>
                          </div>
                          <div className='col-md-3'>
                            <p className='font-17 font-color text-left'>
                              ₹{finish.reg_price}
                            </p>
                          </div>
                          <div className='col-md-1' id='customize'>
                            <div className='form-check'>
                              <input
                                className='form-check-input'
                                type='radio'
                                name='finish_id'
                                id='custom-radio'
                                value={finish.id}
                                onChange={(e) => handleFinishType(finish.id)}
                              />{' '}
                              <label
                                className='form-check-label'
                                htmlFor='custom-radio'
                              ></label>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div> */}
                    <div className=''>
                      <h4 className='font-color mt-5 mx-4'>
                        Choose Finish Types
                      </h4>
                      <div className='row mx-4 d-flex align-items-center'>
                        {finishes.map((finish) => (
                          <div className='col-md-6 col-12 ' key={finish.id}>
                            <div className='row '>
                              <div className='col-md-3 col-5'>
                                <p className='font-17 font-color '>
                                  {finish.finish_name}
                                </p>
                              </div>
                              <div className='col-md-3 col-5'>
                                <p className='font-17 font-color text-center'>
                                  ₹{finish.reg_price}
                                </p>
                              </div>
                              <div
                                className='col-md-1 col-2 mt-2'
                                id='customize'
                              >
                                <div className='form-check'>
                                  <input
                                    className='form-check-input'
                                    type='radio'
                                    name='finish_id'
                                    id={`custom-radio-${finish.id}`}
                                    value={finish.id}
                                    onChange={(e) =>
                                      handleFinishType(finish.id)
                                    }
                                  />
                                  <label
                                    className='form-check-label'
                                    htmlFor={`custom-radio-${finish.id}`}
                                  ></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                ) : currentStep === 2 ? (
                  <CategoryRelatedProducts
                    prevStep={prevStep}
                    nextStep={nextStep}
                    categoryRelatedKit={categoryRelatedKit}
                    onCheckboxChange={handleCategoryKit}
                    addToCartData={addToCartData}
                  />
                ) : currentStep === 3 ? (
                  <>
                    <div className='row'>
                      <div className='col-md-12 col-12'>
                        {Finish && (
                          <>
                            <div className='row  align-items-center '>
                              <div className='col-md-4 col-4 couples-device'>
                                <p className='font-color font-15 '>
                                  <strong>{addToCartData.device1_name}</strong>
                                </p>
                                <div className='skin_box_img  '>
                                  <img
                                    src={activeSkin.part1_skin_img}
                                    alt={activeSkin.skin_name}
                                    className='img-fluid p-2'
                                  />
                                </div>
                              </div>
                              <div className='col-md-4 col-4 couples-device'>
                                <p className='font-color font-15 '>
                                  <strong>{addToCartData.device2_name}</strong>
                                </p>
                                <div className='skin_box_img  '>
                                  <img
                                    src={activeSkin.part2_skin_img}
                                    alt={activeSkin.skin_name}
                                    className='img-fluid p-2'
                                  />
                                </div>
                              </div>

                              <div className='col-md-4 col-4 text-center'>
                                <span className='font-color font-17'>
                                  <strong>{activeSkin.skin_name}</strong>
                                </span>
                                <br />
                                <span className='font-color font-17'>
                                  (<strong>₹ {Finish.reg_price}</strong>)
                                </span>
                              </div>
                            </div>
                            <hr />
                          </>
                        )}
                      </div>
                      <div className='col-md-12 col-12'>
                        {addToCartData.category_extra_items.map(
                          (c_related_item_id) => {
                            const product = categoryRelatedKit.find(
                              (product) => product.id === c_related_item_id
                            );
                            if (!product) {
                              return null;
                            }

                            return (
                              <div key={c_related_item_id}>
                                <div className='row align-items-center'>
                                  <div className='col-md-4 col-4 d-flex justify-content-center'>
                                    <div className='skin_box_img'>
                                      <img
                                        src={product.img_path}
                                        alt={product.heading}
                                        className='img-fluid p-2'
                                      />
                                    </div>
                                  </div>
                                  <div className='col-md-4 col-4'>
                                    <h6 className='font-17 font-color text-center'>
                                      {product.heading}
                                    </h6>
                                  </div>

                                  <div className='col-md-4 col-4 text-center'>
                                    <span className='font-color font-17'>
                                      (<strong>₹ {product.price}</strong> )
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                    <div className='row align-items-center justify-content-between ac-btn border-top border-color-1'>
                      <div className='col-md-6 col-6'>
                        <h6 className='font-color'>
                          Total Price :{' '}
                          <strong>₹ {calculateTotalPrice()}</strong>
                        </h6>
                      </div>
                      <div className='col-md-6 col-6 mb-2 mt-2 text-right '>
                        <button
                          type='button'
                          className='btn  theme_flt_btn btn-depth'
                          onClick={handleAddCart}
                        >
                          Add To Cart
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CouplePreview;
