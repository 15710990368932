import React, { useEffect, useState } from "react";
import ProfileBasicTab from "./components/profile";
import ChangePasswordTab from "./components/changePassword";
import Api from "../../helpers/Api";

const MyAccount = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [userDetail, setUserDetail] = useState({});
  // const [formSubmitted, setFormSubmitted] = useState(true);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    Api.MyAccount().then((res) => {
      const { user } = res.data;
      setUserDetail(user);
    });
  }, []);
  return (
    <div className="container mt-5 profile">
      <div className="contact-box mt-5">
        <ul
          className="nav nav-pills user-profile-tab px-5"
          id="pills-tab"
          role="tablist"
        >
          <li className="nav-item tab-button" role="presentation">
            <button
              className={` btn ${activeTab === 1 ? "active" : ""} `}
              onClick={() => handleTabClick(1)}
            >
              <i className="ti ti-user-circle me-2 fs-6" />
              <span className="d-none d-md-block">Account</span>
            </button>
          </li>
          <li className="nav-item tab-button" role="presentation">
            <button
              className={`btn ${activeTab === 2 ? "active" : ""}`}
              onClick={() => handleTabClick(2)}
            >
              <i className="ti ti-lock me-2 fs-6" />
              <span className="d-none d-md-block">Password</span>
            </button>
          </li>
          {/* <li className="nav-item" role="presentation">
            <button
              className={`nav-link position-relative rounded-0 ${
                activeTab === 3 ? "active" : ""
              } d-flex align-items-center justify-content-center bg-transparent fs-3 py-4`}
              onClick={() => handleTabClick(3)}
            >
              <i className="ti ti-lock me-2 fs-6" />
              <span className="d-none d-md-block">Company Details</span>
            </button>
          </li> */}
        </ul>
        <div className="card-body py-2">
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-account"
              role="tabpanel"
              aria-labelledby="pills-account-tab"
              tabIndex={0}
            >
              {activeTab === 1 && <ProfileBasicTab user={userDetail} />}
              {activeTab === 2 && <ChangePasswordTab {...userDetail} />}
              {/* {activeTab === 3 && (
                <CompanyDetailTab
                  company={userDetail.company}
                  countries={countries}
                  setFormSubmitted={setFormSubmitted}
                />
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
