import React from "react";

const PhoneCaseProduct = ({
  phone_case,
  quantity,
  updateQuantity,
  removeItem,
  id,
}) => {
  const handleIncrement = () => {
    updateQuantity(id, quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      updateQuantity(id, quantity - 1);
    }
  };

  const handleRemove = () => {
    removeItem(id);
  };
  const totalPrice = quantity * phone_case?.price;
  return (
    <div className="row align-items-center" id="checkout-product">
      <div className="col-md-2 col-6 p-2 text-center">
        <img
          className="img-fluid"
          src={phone_case?.default_image?.img_path}
          alt={phone_case?.product_name}
          style={{ width: "80px" }}
        />
      </div>
      <div className="col-md-5 col-6 p-2 text-center">
        <span className="font-color font-15">{phone_case?.product_name}</span>{" "}
        <br />
        <span className="font-12 font-color">
          {" "}
          (Item Price : ₹{phone_case?.price})
        </span>
        <br />
        <span className="font-17 font-color">
          {" "}
          <strong>Price : ₹{totalPrice}</strong>
        </span>
      </div>
      <div className="col-md-3 col-7 p-2 d-flex justify-content-center">
        {" "}
        <div className="js-quantity row align-items-center justify-content-center">
          <div className="d-flex cart_col_value m-0">
            <button
              className="qty_btn btn  btn-depth text-secondary btn-depth"
              onClick={handleDecrement}
            >
              <small className="fas fa-minus btn-icon__inner" />
            </button>

            <div className="qty_count ml-2">
              <input
                className="qty_input js-result form-control h-auto border-0 rounded p-0 shadow-none"
                type="text"
                value={quantity}
                readOnly
              />
            </div>
            <button
              className=" ml-2 btn  btn-depth text-secondary qty_btn btn-depth"
              onClick={handleIncrement}
            >
              <small className="fas fa-plus btn-icon__inner_1" />
            </button>
          </div>
        </div>
      </div>
      <div className="col-md-2 col-5 p-2 d-flex justify-content-center">
        {" "}
        <span
          className="text-gray-32 font-size-26 cart-remove"
          onClick={handleRemove}
        >
          <i className="fa fa-trash-alt"> </i>
        </span>
      </div>
    </div>
  );
};

export default PhoneCaseProduct;
