import React, { useState, useRef, useEffect, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { LoginAsideContext } from "../context/LoginAsideContext";
import AuthContext from "../context/AuthContext";
import Api from "../helpers/Api";
import EachSkinCartItem from "./EachSkinCartItem";
import EachCustomSkinCartItem from "./EachCustomSkinCartItem";
import EachDeviceKitCartItem from "./EachDeviceKitCartItem";
import EachCommonKitCartItem from "./EachCommonKitCartItem";
import EachCoupleCartItem from "./EachCoupleCartItem";
import { LeftAsideContext } from "../context/LeftAsideContext";
import EachAccessoryCartItem from "./EachAccessoryCartItem";
import Navbar from "./Navbar";
import SideBar from "./SideBar";
import EachStickerItem from "./EachStickerItem";
import EachPhoneCaseItem from "./EachPhoneCaseItem";

const Header = () => {
  const navigate = useNavigate();
  const { isLoginAsideActive, setLoginAsideActive } =
    useContext(LoginAsideContext);
  const { isLeftAsideActive, setLeftAsideActive } =
    useContext(LeftAsideContext);

  const { user, login } = useContext(AuthContext);
  const [cartBtnActive, setCartBtnActive] = useState(false);
  const [logo, setLogo] = useState("");
  const [cartCount, setCartCount] = useState(0);
  const [skinsCartNotify, setSkinsCartNotify] = useState([]);
  const [customSkinsCartNotify, setCustomSkinsCartNotify] = useState([]);
  const [coupleSkinsCartNotify, setCoupleSkinsCartNotify] = useState([]);
  const [commonKitsInCartNotify, setCommonKitsInCartNotify] = useState([]);
  const [deviceKitsInCartNotify, setDeviceKitsInCartNotify] = useState([]);

  const [accessoryProductInCartNotify, setAccessoryProductInCartNotify] =
    useState([]);
  const [acrylicProductInCartNotify, setAcrylicProductInCartNotify] = useState(
    []
  );
  const [NfcProductInCartNotify, setNfcProductInCartNotify] = useState([]);
  const [FramesProductInCartNotify, setFramesProductInCartNotify] = useState(
    []
  );
  const [accessoriesMenu, setAccessoriesMenu] = useState([]);
  const [categoryMenu, setCategoryMenu] = useState([]);
  const [stickers, setStickers] = useState([]);
  const [phoneCases, setPhoneCases] = useState([]);
  const cartRef = useRef();
  const dropdownRef = useRef();
  const location = useLocation();

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        cartBtnActive &&
        cartRef.current &&
        !cartRef.current.contains(e.target) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target)
      ) {
        setCartBtnActive(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    // if (user.apiToken) {
    //   Api.CartHeader()
    //     .then((res) => {
    //       const { skins_in_cart } = res.data;
    //       setSkinsCartNotify(skins_in_cart);
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [cartBtnActive]);
  const handleCartButtonClick = (e) => {
    e.stopPropagation(); // Prevent click event from propagating
    setCartBtnActive((prev) => !prev);
  };
  useEffect(() => {
    Api.CartHeader()
      .then((res) => {
        const {
          skins_in_cart,
          custom_skins_in_cart,
          couple_skins_in_cart,
          device_kits_in_cart,
          common_kits_in_cart,
          accessories_products_in_cart,
          acrylic_products_in_cart,
          nfc_products_in_cart,
          frame_products_in_cart,
          stickers,
          phone_cases,
          s_categories,
          token,
          is_guest,
          categories,
          logo,
        } = res.data;
        setLogo(logo);
        setCategoryMenu(categories);
        setAccessoriesMenu(s_categories);
        setSkinsCartNotify(skins_in_cart);
        setCustomSkinsCartNotify(custom_skins_in_cart);
        setCoupleSkinsCartNotify(couple_skins_in_cart);
        setCommonKitsInCartNotify(common_kits_in_cart);
        setDeviceKitsInCartNotify(device_kits_in_cart);
        setAccessoryProductInCartNotify(accessories_products_in_cart);
        setAcrylicProductInCartNotify(acrylic_products_in_cart);
        setNfcProductInCartNotify(nfc_products_in_cart);
        setFramesProductInCartNotify(frame_products_in_cart);
        setStickers(stickers);
        setPhoneCases(phone_cases);
        const TotalCartCount =
          skins_in_cart.length +
          custom_skins_in_cart.length +
          couple_skins_in_cart.length +
          common_kits_in_cart.length +
          accessories_products_in_cart.length +
          acrylic_products_in_cart.length +
          nfc_products_in_cart.length +
          frame_products_in_cart.length +
          stickers.length +
          phone_cases.length +
          device_kits_in_cart.length;
        setCartCount(TotalCartCount);
        setCartBtnActive(false);
        let apiToken = localStorage.getItem("apiToken");
        if (!apiToken) {
          localStorage.setItem("apiToken", token);
          localStorage.setItem("is_guest", is_guest);
          // localStorage.setItem("userName", "Guest User");
          // localStorage.setItem("userId", "0");
          login({
            apiToken: token,
            is_guest: is_guest,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [location, login]);
  // Update the state when the button is clicked
  const handleClick = (e) => {
    e.stopPropagation();
    setLoginAsideActive(!isLoginAsideActive);
  };
  const handleLeftClick = (e) => {
    e.stopPropagation();
    setLeftAsideActive((prev) => !prev);
  };
  const handleLogout = (e) => {
    e.stopPropagation();
    // Api.Logout()
    //   .then((response) => {

    //   })
    //   .catch((error) => {
    //     // Handle any errors that occurred during the logout process
    //     console.error('Logout failed:', error);
    //   });
    // If the logout was successful, clear the authentication token from local storage
    localStorage.removeItem("apiToken");
    // localStorage.removeItem("userName");
    // localStorage.removeItem("userId");
    localStorage.removeItem("is_guest");
    window.location.href = "/"; // Replace "/" with your base URL or desired route
  };
  const handleViewCart = () => {
    setCartBtnActive(false);
    navigate("/cart");
  };
  return (
    <header id="header" className="u-header u-header-left-aligned-nav mb-3">
      <div className="u-header__section">
        {/* <div className="bg-primary-down-lg"> */}
        <div className="u-header-topbar py-2 d-none d-xl-block">
          <div className="container">
            <div className="d-flex align-items-center">
              <div className="topbar-left">
                <Link
                  className="order-1 order-xl-0 navbar-brand u-header__navbar-brand u-header__navbar-brand-center"
                  to="/"
                  aria-label="Tech Skin"
                >
                  <img
                    src={
                      logo
                      // process.env.PUBLIC_URL + "/assets/img/techskins_logo.png"
                    }
                    alt="Techskins Logo"
                    className="img-fluid"
                  />
                </Link>
              </div>
              <div className="col d-none d-xl-block">
                <nav className="js-mega-menu navbar navbar-expand-md u-header__navbar u-header__navbar--no-space">
                  <div
                    id="navBar"
                    className="collapse navbar-collapse u-header__navbar-collapse"
                  >
                    <ul className="navbar-nav u-header__navbar-nav">
                      <li
                        className="nav-item dropdown  u-header__nav-item "
                        id="side-navbar"
                      >
                        <a
                          className="nav-link dropdown-toggle font-color font-17"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <span className="font-17"> Skins</span>
                        </a>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <ul className="pl-0">
                            {categoryMenu.map((each_menu) => (
                              <li
                                className="nav-item   u-header__nav-item"
                                key={each_menu.id}
                              >
                                <Link
                                  to={`/category/${each_menu.id}`}
                                  className="dropdown-item  font-color font-15"
                                  // onClick={handleAsideClose}
                                >
                                  <i className="fa fa-chevron-right font-color mr-1"></i>{" "}
                                  {each_menu.category_name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </li>
                      {accessoriesMenu.map((each_menu) => (
                        <li
                          className="nav-item   u-header__nav-item"
                          key={each_menu.id}
                        >
                          <Link
                            to={`/accessories/${each_menu.id}`}
                            className="nav-link  font-color font-17"
                          >
                            {each_menu.category_name}
                          </Link>
                        </li>
                      ))}
                      <li className="nav-item   u-header__nav-item">
                        <Link
                          to={`/nfc`}
                          className="nav-link  font-color font-17"
                        >
                          NFC
                        </Link>
                      </li>
                      <li className="nav-item   u-header__nav-item">
                        <Link
                          to={`/photo-frame`}
                          className="nav-link  font-color font-17"
                        >
                          Photo Frame
                        </Link>
                      </li>
                      <li className="nav-item   u-header__nav-item">
                        <Link
                          to={`/acrylic`}
                          className="nav-link  font-color font-17"
                        >
                          Acrylic
                        </Link>
                      </li>
                      <li className="nav-item   u-header__nav-item">
                        <Link
                          to={`/sticker-types`}
                          className="nav-link  font-color font-17"
                        >
                          Stickers
                        </Link>
                      </li>
                      <li className="nav-item   u-header__nav-item">
                        <Link
                          to={`/phone-cases-types`}
                          className="nav-link  font-color font-17"
                        >
                          Phone Cases
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>

              <div className="topbar-right ml-auto mt-3 mb-3">
                <ul className="list-inline mb-0 d-flex">
                  <div className="row">
                    {user.is_guest && (
                      <li className="col-md-6 pr-xl-0 px-2 px-sm-3 d-none d-xl-block  ">
                        <button
                          className={`btn  text-secondary cart_button btn-depth drop_btn text-gray-90 position-relative d-flex  theme_flt_btn${
                            cartBtnActive ? "clicked" : ""
                          }`}
                          ref={cartRef}
                          onClick={handleCartButtonClick}
                        >
                          <i className="font-size-22 fa fa-shopping-basket" />
                          <span className="position-absolute top-0 cart-count start-100 translate-middle rounded-circle">
                            <span className="">{cartCount}</span>
                          </span>
                        </button>
                        {cartBtnActive && (
                          <div
                            className="cart-dropdown d-block  dropdown-menu mt-3 border-left-0 border-right-0 border-bottom-0 left-auto right-0 "
                            ref={dropdownRef}
                          >
                            <div className="scroll">
                              <h5 className="border-bottom text-gray-5 py-2 pl-2 border-color-1">
                                Products In Cart
                              </h5>
                              <ul className="list-unstyled cart-ul px-3 pt-3">
                                {skinsCartNotify.map((each) => (
                                  <EachSkinCartItem {...each} key={each.id} />
                                ))}

                                {customSkinsCartNotify.map((each) => (
                                  <EachCustomSkinCartItem
                                    {...each}
                                    key={each.id}
                                  />
                                ))}
                                {deviceKitsInCartNotify.map((each) => (
                                  <EachDeviceKitCartItem
                                    {...each}
                                    key={each.id}
                                  />
                                ))}
                                {commonKitsInCartNotify.map((each) => (
                                  <EachCommonKitCartItem
                                    {...each}
                                    key={each.id}
                                  />
                                ))}
                                {coupleSkinsCartNotify.map((each) => (
                                  <EachCoupleCartItem {...each} key={each.id} />
                                ))}
                                {accessoryProductInCartNotify.map((each) => (
                                  <EachAccessoryCartItem
                                    {...each}
                                    key={each.id}
                                  />
                                ))}
                                {acrylicProductInCartNotify.map((each) => (
                                  <EachAccessoryCartItem
                                    {...each}
                                    key={each.id}
                                  />
                                ))}
                                {NfcProductInCartNotify.map((each) => (
                                  <EachAccessoryCartItem
                                    {...each}
                                    key={each.id}
                                  />
                                ))}
                                {FramesProductInCartNotify.map((each) => (
                                  <EachAccessoryCartItem
                                    {...each}
                                    key={each.id}
                                  />
                                ))}
                                {stickers.map((each) => (
                                  <EachStickerItem {...each} key={each.id} />
                                ))}
                                {phoneCases.map((each) => (
                                  <EachPhoneCaseItem {...each} key={each.id} />
                                ))}
                              </ul>

                              <div className="flex-center-between px-4 py-2 border-top border-color-1 header-cart">
                                {cartCount > 0 ? (
                                  <>
                                    <Link
                                      to="/cart"
                                      // onClick={handleViewCart}
                                      className="btn btn-depth theme_flt_btn mb-3 mb-md-0 px-5 px-md-4 px-lg-5 "
                                    >
                                      View cart
                                    </Link>

                                    <Link
                                      to="/checkout"
                                      className="btn btn-depth theme_flt_btn ml-md-2 px-5 px-md-4 px-lg-5"
                                    >
                                      Checkout
                                    </Link>
                                  </>
                                ) : (
                                  <p>Items Not Found</p>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </li>
                    )}
                    <div className="col-md-6 dropdown">
                      <button
                        className={` btn  text-secondary cart_button btn-depth text-gray-90 position-relative d-flex acc_btn theme_flt_btn`}
                        // style={{
                        //   borderRadius: "2px",
                        //   padding: "6px 10px",
                        // }}
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="font-size-22 ec ec-user" />
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <ul className="list-unstyled">
                          <li>
                            <Link to="/orders">
                              <button className="btn logout_btn">
                                <i className="ec ec-add-to-cart mr-1" /> My
                                Orders
                              </button>
                            </Link>
                          </li>

                          <li>
                            {user.is_guest === "0" ? (
                              <div>
                                <ul>
                                  <li>
                                    <Link to="/my-account">
                                      <button className="btn logout_btn">
                                        <i className=" mr-1 ec ec-user" />
                                        My Account
                                      </button>
                                    </Link>
                                  </li>
                                </ul>
                                <button
                                  className="btn logout_btn"
                                  onClick={handleLogout}
                                >
                                  <i className="fa fa-arrow-right-from-bracket mr-1" />
                                  Logout
                                </button>
                              </div>
                            ) : (
                              <button
                                onClick={handleClick}
                                className={`btn logout_btn ${
                                  isLoginAsideActive ? "active" : ""
                                }`}
                                id="login_btn"
                              >
                                <i className="ec ec-user mr-1" /> SIGN IN
                              </button>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container my-0dot5 my-xl-0">
          <div className="row align-items-center py-3">
            <div className="col col-xl-auto text-right text-xl-left pl-0 pl-xl-3 position-static">
              <div className="d-flex justify-content-between">
                <div className="topbar-left d-flex d-sm-none row align-items-center">
                  <Link
                    className="order-1 order-xl-0 navbar-brand u-header__navbar-brand u-header__navbar-brand-center"
                    to="/"
                    aria-label="Tech Skin"
                  >
                    <img
                      src={
                        logo
                        // process.env.PUBLIC_URL +
                        // "/assets/img/techskins_logo.png"
                      }
                      alt="Techskins Logo"
                      className="img-fluid"
                    />
                  </Link>
                  <button
                    onClick={handleLeftClick}
                    type="button"
                    className={`navbar-toggler d-block btn u-hamburger ml-5 mr-2  btn btn-depth text-secondary drop_btn text-gray-90 position-relative d-flex  theme_flt_btn ${
                      isLeftAsideActive ? "active" : ""
                    }`}
                    // aria-controls='sidebarHeader'
                    // aria-haspopup='true'
                    // aria-expanded='false'
                    // data-unfold-event='click'
                    // data-unfold-hide-on-scroll='false'
                    // data-unfold-target='#sidebarHeader1'
                    // data-unfold-type='css-animation'
                    // data-unfold-animation-in='fadeInLeft'
                    // data-unfold-animation-out='fadeOutLeft'
                    // data-unfold-duration='500'
                  >
                    <span
                      id="hamburgerTriggerMenu"
                      className="u-hamburger__box"
                    >
                      <span className="u-hamburger__inner font-color"></span>
                    </span>
                  </button>
                  <Navbar logo={logo} />
                </div>
                <ul className="d-flex list-unstyled mb-0 align-items-center">
                  {user.is_guest !== null && (
                    <li
                      className="col pr-xl-0  px-sm-3  d-xl-none"
                      ref={cartRef}
                    >
                      <button
                        className={`btn btn-depth text-secondary drop_btn text-gray-90 position-relative d-flex  theme_flt_btn${
                          cartBtnActive ? "clicked" : ""
                        }`}
                        onClick={() => setCartBtnActive((prev) => !prev)}
                      >
                        <i className="font-size-22 icon-color ec ec-shopping-bag" />
                        <span className="position-absolute top-0 cart-count start-100 translate-middle rounded-circle">
                          <span className="">4</span>
                        </span>
                      </button>
                      {cartBtnActive && (
                        <div className="cart-dropdown d-block dropdown dropdown-menu mt-3 border-left-0 border-right-0 border-bottom-0 left-auto right-0 ">
                          <div className="scroll">
                            <h5 className="border-bottom text-gray-5 py-2 pl-2 border-color-1">
                              Products In Cart
                            </h5>
                            <ul className="list-unstyled cart-ul px-3 pt-3">
                              {skinsCartNotify.map((each) => (
                                <EachSkinCartItem {...each} key={each.id} />
                              ))}

                              {customSkinsCartNotify.map((each) => (
                                <EachCustomSkinCartItem
                                  {...each}
                                  key={each.id}
                                />
                              ))}
                              {deviceKitsInCartNotify.map((each) => (
                                <EachDeviceKitCartItem
                                  {...each}
                                  key={each.id}
                                />
                              ))}
                              {commonKitsInCartNotify.map((each) => (
                                <EachCommonKitCartItem
                                  {...each}
                                  key={each.id}
                                />
                              ))}
                              {coupleSkinsCartNotify.map((each) => (
                                <EachCoupleCartItem {...each} key={each.id} />
                              ))}
                            </ul>

                            <div className="flex-center-between px-4 py-2 border-top border-color-1 header-cart">
                              {cartCount > 0 ? (
                                <div className="mob-btn">
                                  <Link
                                    to="/cart"
                                    // onClick={handleViewCart}
                                    className="btn btn-depth theme_flt_btn mb-md-0 px-5 px-md-4 px-lg-5 mr-r1"
                                  >
                                    View cart
                                  </Link>

                                  <Link
                                    to="/checkout"
                                    className="btn btn-depth theme_flt_btn ml-md-2 px-5 px-md-4 px-lg-5"
                                  >
                                    Checkout
                                  </Link>
                                </div>
                              ) : (
                                <p>Items Not Found</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </li>
                  )}
                  <div className="col-md-6 dropdown d-xl-none" id="m-header">
                    <button
                      className={` btn  text-secondary cart_button btn-depth text-gray-90 position-relative d-flex acc_btn theme_flt_btn`}
                      // style={{
                      //   borderRadius: "2px",
                      //   padding: "6px 10px",
                      // }}
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="font-size-22 ec ec-user" />
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <ul>
                        <li>
                          <Link to="/orders">
                            <button className="btn logout_btn">
                              <i className="ec ec-add-to-cart mr-1" /> My Orders
                            </button>
                          </Link>
                        </li>
                        <li>
                          {user.is_guest === "0" ? (
                            <button
                              className="btn logout_btn"
                              onClick={handleLogout}
                            >
                              <i className="fa fa-arrow-right-from-bracket mr-1" />
                              Logout
                            </button>
                          ) : (
                            <button
                              onClick={handleClick}
                              className={`btn logout_btn ${
                                isLoginAsideActive ? "active" : ""
                              }`}
                              id="login_btn"
                            >
                              <i className="ec ec-user mr-1" /> SIGN IN
                            </button>
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Logo-Search-header-icons */}
      {/* </div> */}
      <SideBar logo={logo} />
    </header>
  );
};
export default Header;
