import React, { useRef, useContext, useEffect, useState } from "react";
import { LeftAsideContext } from "../context/LeftAsideContext";
import Api from "../helpers/Api";
import { Link } from "react-router-dom";

const Navbar = ({ logo }) => {
  const { isLeftAsideActive, setLeftAsideActive } =
    useContext(LeftAsideContext);
  const navRef = useRef(null);
  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      setLeftAsideActive(false);
    }
  };
  const [accessoriesMenu, setAccessoriesMenu] = useState([]);
  const [categoryMenu, setCategoryMenu] = useState([]);
  useEffect(() => {
    Api.CartHeader()
      .then((res) => {
        const { s_categories, categories } = res.data;
        setAccessoriesMenu(s_categories);
        setCategoryMenu(categories);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    if (isLeftAsideActive) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isLeftAsideActive]);
  const handleAsideClose = () => {
    setLeftAsideActive(!isLeftAsideActive);
  };
  return (
    <aside
      id="sidebarbox"
      ref={navRef}
      className={`u-sidebar u-sidebar__lg  tester  ${
        isLeftAsideActive ? "is-active" : ""
      }`}
      // aria-labelledby="sidebarNavToggler"
    >
      <div className="u-sidebar__scroller">
        <div className="u-sidebar__container">
          <div className="js-scrollbar u-header-sidebar__footer-offset pb-3">
            {/* Toggle Button */}
            <div className="d-flex align-items-center pt-4 px-7">
              <Link
                className="navbar-brand u-header__navbar-brand u-header__navbar-brand-center"
                to="/"
                aria-label="Tech Skin"
                onClick={handleAsideClose}
              >
                <img
                  src={
                    // process.env.PUBLIC_URL + "/assets/img/techskins_logo.png"
                    logo
                  }
                  alt="Techskins Logo"
                  className="img-fluid"
                />
              </Link>
              <button
                type="button"
                className="close ml-auto"
                onClick={handleAsideClose}
              >
                <i className="ec ec-close-remove" />
              </button>
            </div>
            {/* End Toggle Button */}
            {/* Content */}
            <div className="js-scrollbar u-sidebar__body">
              <div className="u-sidebar__content u-header-sidebar__content">
                <ul className="text-left">
                  <li
                    className="nav-item dropdown  u-header__nav-item "
                    id="side-navbar"
                  >
                    <a
                      className="nav-link dropdown-toggle font-color font-17"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fa fa-chevron-right font-color mr-1"></i>{" "}
                      <span className="font-17"> Skins</span>
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <ul className="mob-menu">
                        {categoryMenu.map((each_menu) => (
                          <li
                            className="nav-item   u-header__nav-item"
                            key={each_menu.id}
                          >
                            <Link
                              to={`/category/${each_menu.id}`}
                              className="dropdown-item  font-color font-17"
                              onClick={handleAsideClose}
                            >
                              <i className="fa fa-chevron-right font-color mr-1"></i>{" "}
                              {each_menu.category_name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                  {accessoriesMenu.map((each_menu) => (
                    <li
                      className="nav-item   u-header__nav-item"
                      key={each_menu.id}
                    >
                      <Link
                        to={`/accessories/${each_menu.id}`}
                        className="nav-link  font-color font-17"
                        onClick={handleAsideClose}
                      >
                        <i className="fa fa-chevron-right font-color mr-1"></i>{" "}
                        {each_menu.category_name}
                      </Link>
                    </li>
                  ))}
                  <li className="nav-item   u-header__nav-item">
                    <Link
                      to={`/nfc`}
                      className="nav-link  font-color font-17"
                      onClick={handleAsideClose}
                    >
                      <i className="fa fa-chevron-right font-color mr-1"></i>
                      NFC
                    </Link>
                  </li>
                  <li className="nav-item   u-header__nav-item">
                    <Link
                      to={`/photo-frame`}
                      className="nav-link  font-color font-17"
                      onClick={handleAsideClose}
                    >
                      <i className="fa fa-chevron-right font-color mr-1"></i>
                      Photo Frame
                    </Link>
                  </li>
                  <li className="nav-item   u-header__nav-item">
                    <Link
                      to={`/acrylic`}
                      className="nav-link  font-color font-17"
                      onClick={handleAsideClose}
                    >
                      <i className="fa fa-chevron-right font-color mr-1"></i>
                      Acrylic
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* End Content */}
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Navbar;
