import React, { createContext, useState } from "react";

// Create a new context
export const LoginAsideContext = createContext();

// Create a context provider component
export const LoginAsideProvider = ({ children }) => {
  const [isLoginAsideActive, setLoginAsideActive] = useState(false);

  // Wrap the children components with the provider and pass the state and updater function
  return (
    <LoginAsideContext.Provider
      value={{ isLoginAsideActive, setLoginAsideActive }}
    >
      {children}
    </LoginAsideContext.Provider>
  );
};
