import React, { useState, useEffect, useContext } from "react";
import { LoaderContext } from "../context/LoaderContext";

const PreLoader = () => {
  const { isLoading } = useContext(LoaderContext);
  return (
    <>
      {isLoading ? (
        <div className="bouncing-loader">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default PreLoader;
