import React, { useEffect, useState } from "react";
import { ToastError, ToastSuccess } from "../../../helpers/Toast";
import Api from "../../../helpers/Api";
import { Link } from "react-router-dom";

const ProfileBasicTab = ({ user }) => {
  const [formData, setFormData] = useState({
    name: "",
  });

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      name: user?.name,
    }));
  }, [user]);
  const handleInputField = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const SubmitForm = async (e) => {
    e.preventDefault();

    try {
      const data = new FormData(e.target);
      const validationErrors = [];
      if (!formData.name) {
        validationErrors.push(`Name Required`);
      }

      if (validationErrors.length > 0) {
        validationErrors.forEach((error) => ToastError(error));
        return;
      }
      Api.ProfileUpdate(data).then((response) => {
        if (response.status === 200) {
          ToastSuccess("Basic Details Updated Successfully!!!");
        } else {
          ToastError("Oops! Something went wrong.");
        }
      });

      // Handle the response as needed
    } catch (error) {
      console.error("Error:", error.message);
      ToastError(`Oops! Something went wrong. ${error.message}`);
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        {/* <div className="card w-100 position-relative overflow-hidden mb-0"> */}
        <div className="card-body p-4">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h5 className="card-title fw-semibold">My profile</h5>
            </div>
            <div className="col-md-6 d-flex justify-content-end">
              <Link to="/orders">
                <p className="btn theme_flt_btn">
                  <i className="fa fa-shopping-bag mr-2"></i>My Orders
                </p>
              </Link>
            </div>
          </div>
          <form onSubmit={SubmitForm} id="contact-form">
            <div className="row">
              <div className="col-lg-4">
                <div className="mb-4">
                  <label
                    htmlFor="exampleInputPassword1"
                    className="form-label fw-semibold"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Mathew Anderson"
                    value={formData.name || ""}
                    onChange={handleInputField}
                    name="name"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="mb-4">
                  <label
                    htmlFor="exampleInputPassword1"
                    className="form-label fw-semibold"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    value={user?.email}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="mb-4">
                  <label
                    htmlFor="exampleInputPassword1"
                    className="form-label fw-semibold"
                  >
                    Phone
                  </label>
                  <input
                    type="phone"
                    className="form-control"
                    value={user?.phone}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-end mt-4">
                  <button className="btn theme_flt_btn mr-3" type="submit">
                    Save <i className="fa fa-upload ml-2"></i>
                  </button>
                  {/* <button className="btn theme_flt_btn text-danger">
                    Cancel
                  </button> */}
                </div>
              </div>
            </div>
          </form>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default ProfileBasicTab;
