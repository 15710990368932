import React from "react";
import { Link } from "react-router-dom";

const EachAccessoryProduct = ({
  product_name,
  id,
  price,
  d_price,
  img_path,
  category_name,
}) => {
  // Replace whitespace with "-" and convert to lowercase
  const formattedCategoryName = category_name
    ?.replace(/\s/g, "-")
    .toLowerCase();
  return (
    <div className="col-md-2 col-6 ">
      <div className=" p-card mx-2">
        <div className="content">
          <Link
            to={`/accessories/${formattedCategoryName}/${id}`}
            className="font-size-12 text-gray-5"
          >
            <div className="front cat_img">
              <img src={img_path} alt={product_name} className="img-fluid " />
            </div>
          </Link>
          <div className="back from-bottom">
            <div className="row pl-3 mt-2 mb-3 price-card d-flex justify-content-between align-items-center">
              <h4 className="text-center pt-2 price-c"> ₹ {price}</h4> |
              <h4 className="text-center pt-2 price-c"> ₹ {d_price}</h4>
              <div className="product-btn d-flex align-items-center">
                <button className="btn btn-link text-secondary ">
                  <span>
                    <i className="fa fa-magnifying-glass-arrow-right"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EachAccessoryProduct;
