import React, { useContext, useEffect, useState } from "react";
import Api from "../../helpers/Api";
import EachSkinItem from "./EachSkinItem";
import { showConfirmAlert } from "../../helpers/SweetAlert";
import { useNavigate } from "react-router-dom";
import EachDeviceKitItem from "./EachDeviceKitItem";
import EachCommonKitItem from "./EachCommonKitItem";
import EachCustomSkinItem from "./EachCustomSkinItem";
import EachCoupleSkinItem from "./EachCoupleSkinItem";
// import AuthContext from "../../context/AuthContext";
import { LoaderContext } from "../../context/LoaderContext";
import PreLoader from "../../components/PreLoader";
import EachAccessoryItem from "./EachAccessoryItem";
import EachStickerItem from "./EachStickerItem";
import EachPhoneCaseItem from "./EachPhoneCaseItem";

const Cart = () => {
  const [skins, setSkins] = useState([]);
  const [commonKitsInCart, setCommonKitsInCart] = useState([]);
  const [deviceKitsInCart, setDeviceKitsInCart] = useState([]);
  const [customSkinsInCart, setCustomSkinsInCart] = useState([]);
  const [coupleSkinsInCart, setCoupleSkinsInCart] = useState([]);
  const [accessoryProductInCart, setAccessoryProductInCart] = useState([]);
  const [acrylicProductInCart, setAcrylicProductInCart] = useState([]);
  const [NfcProductInCart, setNfcProductInCart] = useState([]);
  const [FramesProductInCart, setFramesProductInCart] = useState([]);
  const [stickerProductsInCart, setStickerProductsInCart] = useState([]);
  const [phoneCases, setPhoneCases] = useState([]);

  const [updateBtn, setUpdateBtn] = useState(false);
  const navigate = useNavigate();
  // const { login } = useContext(AuthContext);
  const { isLoading, setIsLoading } = useContext(LoaderContext);

  useEffect(() => {
    setIsLoading(true);
    // let baseURL = "";

    // // Check if the code is running in a local environment
    // if (process.env.NODE_ENV === "development") {
    //   baseURL = "http://127.0.0.1:8000/api";
    // } else {
    //   baseURL = "https://demo-backend.techskins.in/api";
    // }
    // let apiToken = localStorage.getItem("apiToken");
    // if (!apiToken) {
    //   axios
    //     .get(`${baseURL}/get-token`)
    //     .then((response) => {
    //       const { token, is_guest } = response.data;
    //       localStorage.setItem("apiToken", token);
    //       localStorage.setItem("is_guest", is_guest);
    //       apiToken = token;
    //       console.log("Fetched and set API token:", apiToken);
    //       login({
    //         apiToken: token,
    //         is_guest: is_guest,
    //       });
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching API token:", error);
    //     });
    // }
    Api.CartPage()
      .then((res) => {
        const {
          skins_in_cart,
          device_kits_in_cart,
          common_kits_in_cart,
          custom_skins_in_cart,
          couple_skins_in_cart,
          accessories_products_in_cart,
          acrylic_products_in_cart,
          nfc_products_in_cart,
          frame_products_in_cart,
          stickers,
          phone_cases,
        } = res.data;
        setStickerProductsInCart(stickers);
        setSkins(skins_in_cart);
        setCommonKitsInCart(common_kits_in_cart);
        setDeviceKitsInCart(device_kits_in_cart);
        setCustomSkinsInCart(custom_skins_in_cart);
        setCoupleSkinsInCart(couple_skins_in_cart);
        setAccessoryProductInCart(accessories_products_in_cart);
        setAcrylicProductInCart(acrylic_products_in_cart);
        setFramesProductInCart(frame_products_in_cart);
        setNfcProductInCart(nfc_products_in_cart);
        setPhoneCases(phone_cases);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setIsLoading(false));
  }, [setIsLoading]);
  const handleUpdateQuantity = (itemId, newQuantity, setterFun, items_name) => {
    const updatedData = items_name.map((item) => {
      if (item.id === itemId) {
        return { ...item, quantity: newQuantity };
      }
      return item;
    });
    setterFun(updatedData);

    setUpdateBtn(true);
  };
  const handleRemoveItem = (itemId, setterFun, items) => {
    showConfirmAlert(
      "Are You Sure To Remove This Item From Cart",
      "Yes",
      "Cancel"
    ).then((result) => {
      if (result.isConfirmed) {
        const updatedItems = items.filter((item) => item.id !== itemId);
        setterFun(updatedItems);
        setUpdateBtn(true);
      }
    });
  };
  const handleUpdate = () => {
    // showConfirmAlert("Do you want to save the changes?", "Save", "cancel").then(
    //   (result) => {
    //     if (result.isConfirmed) {

    //     } else if (result.isDenied) {
    //       Swal.fire("Changes are not saved", "", "info");
    //     }
    //   }
    // );
    // Extracting the necessary data from state variables
    const cartItems = [
      { name: "skins", items: skins },
      { name: "customSkinsInCart", items: customSkinsInCart },
      { name: "coupleSkinsInCart", items: coupleSkinsInCart },
      { name: "commonKitsInCart", items: commonKitsInCart },
      { name: "deviceKitsInCart", items: deviceKitsInCart },
      { name: "accessoryProductInCart", items: accessoryProductInCart },
      { name: "acrylicProductInCart", items: acrylicProductInCart },
      { name: "FramesProductInCart", items: FramesProductInCart },
      { name: "NfcProductInCart", items: NfcProductInCart },
      { name: "stickerProductsInCart", items: stickerProductsInCart },
      { name: "phoneCases", items: phoneCases },
    ];

    const updatedData = Object.fromEntries(
      cartItems.map(({ name, items }) => [
        name,
        items.map(({ id, quantity }) => ({ id, quantity })),
      ])
    );
    updatedData.updateBtn = updateBtn;
    // console.log(updatedData);
    // Convert to JSON string
    const requestBody = JSON.stringify(updatedData);
    Api.UpdateCart(requestBody)
      .then((res) => {
        // console.log(res.data);
        // ToastSuccess("Cart Updated SuccessFully");
        navigate("/checkout");
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <div className="container pt-4" id="cart-page">
      {isLoading ? (
        <PreLoader />
      ) : (
        <>
          <div className="mb-10 cart-table ">
            {skins.map((skin) => (
              <EachSkinItem
                {...skin}
                key={skin.id}
                updateQuantity={(itemId, newQuantity) =>
                  handleUpdateQuantity(itemId, newQuantity, setSkins, skins)
                }
                removeItem={(itemId) =>
                  handleRemoveItem(itemId, setSkins, skins)
                }
              />
            ))}
            {customSkinsInCart.map((each_custom_skin) => (
              <EachCustomSkinItem
                key={each_custom_skin.id}
                {...each_custom_skin}
                updateQuantity={(itemId, newQuantity) =>
                  handleUpdateQuantity(
                    itemId,
                    newQuantity,
                    setCustomSkinsInCart,
                    customSkinsInCart
                  )
                }
                removeItem={(itemId) =>
                  handleRemoveItem(
                    itemId,
                    setCustomSkinsInCart,
                    customSkinsInCart
                  )
                }
              />
            ))}
            {coupleSkinsInCart.map((each_couple_skin) => (
              <EachCoupleSkinItem
                key={each_couple_skin.id}
                {...each_couple_skin}
                updateQuantity={(itemId, newQuantity) =>
                  handleUpdateQuantity(
                    itemId,
                    newQuantity,
                    setCoupleSkinsInCart,
                    coupleSkinsInCart
                  )
                }
                removeItem={(itemId) =>
                  handleRemoveItem(
                    itemId,
                    setCoupleSkinsInCart,
                    coupleSkinsInCart
                  )
                }
              />
            ))}
            {deviceKitsInCart.map((device_kit) => (
              <EachDeviceKitItem
                key={device_kit.id}
                {...device_kit}
                updateQuantity={(itemId, newQuantity) =>
                  handleUpdateQuantity(
                    itemId,
                    newQuantity,
                    setDeviceKitsInCart,
                    deviceKitsInCart
                  )
                }
                removeItem={(itemId) =>
                  handleRemoveItem(
                    itemId,
                    setDeviceKitsInCart,
                    deviceKitsInCart
                  )
                }
              />
            ))}
            {commonKitsInCart.map((common_kit) => (
              <EachCommonKitItem
                key={common_kit.id}
                {...common_kit}
                updateQuantity={(itemId, newQuantity) =>
                  handleUpdateQuantity(
                    itemId,
                    newQuantity,
                    setCommonKitsInCart,
                    commonKitsInCart
                  )
                }
                removeItem={(itemId) =>
                  handleRemoveItem(
                    itemId,
                    setCommonKitsInCart,
                    commonKitsInCart
                  )
                }
              />
            ))}
            {accessoryProductInCart.map((product) => (
              <EachAccessoryItem
                {...product}
                key={product.id}
                updateQuantity={(itemId, newQuantity) =>
                  handleUpdateQuantity(
                    itemId,
                    newQuantity,
                    setAccessoryProductInCart,
                    accessoryProductInCart
                  )
                }
                removeItem={(itemId) =>
                  handleRemoveItem(
                    itemId,
                    setAccessoryProductInCart,
                    accessoryProductInCart
                  )
                }
              />
            ))}
            {stickerProductsInCart.map((product) => (
              <EachStickerItem
                {...product}
                key={product.id}
                updateQuantity={(itemId, newQuantity) =>
                  handleUpdateQuantity(
                    itemId,
                    newQuantity,
                    setStickerProductsInCart,
                    stickerProductsInCart
                  )
                }
                removeItem={(itemId) =>
                  handleRemoveItem(
                    itemId,
                    setAccessoryProductInCart,
                    accessoryProductInCart
                  )
                }
              />
            ))}
            {acrylicProductInCart.map((product) => (
              <EachAccessoryItem
                {...product}
                key={product.id}
                updateQuantity={(itemId, newQuantity) =>
                  handleUpdateQuantity(
                    itemId,
                    newQuantity,
                    setAcrylicProductInCart,
                    acrylicProductInCart
                  )
                }
                removeItem={(itemId) =>
                  handleRemoveItem(
                    itemId,
                    setAcrylicProductInCart,
                    acrylicProductInCart
                  )
                }
              />
            ))}
            {FramesProductInCart.map((product) => (
              <EachAccessoryItem
                {...product}
                key={product.id}
                updateQuantity={(itemId, newQuantity) =>
                  handleUpdateQuantity(
                    itemId,
                    newQuantity,
                    setFramesProductInCart,
                    FramesProductInCart
                  )
                }
                removeItem={(itemId) =>
                  handleRemoveItem(
                    itemId,
                    setFramesProductInCart,
                    FramesProductInCart
                  )
                }
              />
            ))}
            {NfcProductInCart.map((product) => (
              <EachAccessoryItem
                {...product}
                key={product.id}
                updateQuantity={(itemId, newQuantity) =>
                  handleUpdateQuantity(
                    itemId,
                    newQuantity,
                    setNfcProductInCart,
                    NfcProductInCart
                  )
                }
                removeItem={(itemId) =>
                  handleRemoveItem(
                    itemId,
                    setNfcProductInCart,
                    NfcProductInCart
                  )
                }
              />
            ))}
            {phoneCases.map((product) => (
              <EachPhoneCaseItem
                {...product}
                key={product.id}
                updateQuantity={(itemId, newQuantity) =>
                  handleUpdateQuantity(
                    itemId,
                    newQuantity,
                    setPhoneCases,
                    phoneCases
                  )
                }
                removeItem={(itemId) =>
                  handleRemoveItem(itemId, setPhoneCases, phoneCases)
                }
              />
            ))}
          </div>
          <div className="mb-10 cart-table">
            <div className="row ">
              <div className="col-md-8"></div>
              <div className="col-md-4  d-flex justify-content-end">
                {skins.length +
                  customSkinsInCart.length +
                  commonKitsInCart.length +
                  coupleSkinsInCart.length +
                  accessoryProductInCart.length +
                  acrylicProductInCart.length +
                  FramesProductInCart.length +
                  NfcProductInCart.length +
                  deviceKitsInCart.length >
                0 ? (
                  <div className="btn cart_checkout ">
                    <button
                      className="btn back_btn cart_btn"
                      onClick={handleUpdate}
                      style={{ textAlign: "end" }}
                    >
                      Proceed to checkout
                      <span
                        style={{
                          padding: "7px 10px ",
                          position: "relative",
                          left: "2%",
                        }}
                      >
                        <i className="fa fa-forward" aria-hidden="true"></i>
                      </span>
                    </button>
                  </div>
                ) : (
                  <p>No Items In Cart</p>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Cart;
