import React, { useContext, useEffect, useState } from "react";
import Api from "../../helpers/Api";
import EachFrame from "./EachFrame";
import PreLoader from "../../components/PreLoader";
import { LoaderContext } from "../../context/LoaderContext";

const FrameList = () => {
  const { isLoading, setIsLoading } = useContext(LoaderContext);
  const [frames, setFrames] = useState([]);
  useEffect(() => {
    setIsLoading(true);

    Api.FrameList()
      .then((res) => {
        setFrames(res.data.frames);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setIsLoading(false));
  }, [setIsLoading]);
  return (
    <>
      {isLoading ? (
        <PreLoader />
      ) : (
        <div className="container">
          <div className="row each_product_row mt-3">
            {frames.map((frame) => (
              <EachFrame {...frame} key={frame.id} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default FrameList;
