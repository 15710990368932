import React from "react";

const EachCustomSkinCartItem = ({
  skin_img,
  model_no,
  part_price,
  quantity,
  skin_name,
  part_name,
}) => {
  const totalPrice = quantity * part_price;

  return (
    <li className='border-bottom pb-3 mb-3'>
      <div>
        <ul className='list-unstyled row mx-n2 d-flex align-items-center'>
          <li className='px-2 col-auto cart_img'>
            <img className='img-fluid' src={skin_img} alt='Techskins - Skins' />
          </li>
          <li className='px-2 col'>
            <h5 className='text-gray-5 font-size-14 font-weight-bold'>
              {skin_name}
            </h5>
            <span className='text-gray-5 font-size-14'>
              {quantity} x {part_price}
            </span>
            <span className='text-gray-5 font-size-14'>
              {model_no} ({part_name})
            </span>
          </li>
          <li className='px-2 col-auto'>
            <h5 className='font-color font-size-14 font-weight-bold'>
              ₹ {totalPrice}
            </h5>
          </li>
        </ul>
      </div>
    </li>
  );
};

export default EachCustomSkinCartItem;
