import React, { useContext, useState } from "react";
import Select from "react-select";
import { ThemeModeContext } from "../context/ThemeModeContext";
import { lightTheme, darkTheme } from "../components/SelectTheme";
import { ToastError, ToastSuccess } from "../helpers/Toast";
import Api from "../helpers/Api";
const ContactUs = () => {
  const { isDarkMode } = useContext(ThemeModeContext);

  const [disable, setDisable] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const services = [
    {
      value: "Order Replacement",
      label: `Order Replacement`,
    },
    {
      value: "Order Cancel",
      label: `Order Cancel`,
    },
    {
      value: "Suggestions & Ideas",
      label: `Suggestions & Ideas`,
    },
    {
      value: "Where is my Order?",
      label: `Where is my Order?`,
    },
    {
      value: "Checkout or payment issue",
      label: `Checkout or payment issue`,
    },
    {
      value: "Issue with the order received",
      label: `Issue with the order received`,
    },
    {
      value: "Others",
      label: `Others`,
    },
  ];
  let startData = {
    name: "",
    email: "",
    phone: "",
    order_id: "",
    service: "",
    emessage: "",
  };

  const [formData, setFormData] = useState(startData);

  const handleInput = (event) => {
    const latestData = { ...formData };
    latestData[event.target.name] = event.target.value;
    setFormData(latestData);
  };

  const handleServiceChange = (selectOption) => {
    setFormData((pre) => ({
      ...pre,
      service: selectOption?.label,
    }));
  };

  const SubmitForm = (event) => {
    event.preventDefault();
    setSpinner(true);
    setDisable(true);
    const data = new FormData(event.target);
    Api.Contact(data)
      .then((response) => {
        if (response.status === 200) {
          ToastSuccess(
            "Enquiry submitted successfully, we will reach you soon!!!"
          );
          setTimeout(() => {
            setDisable(false);
            setSpinner(false);
          }, 3000);
          setFormData(startData);
        } else {
          // You can use ToastError here if you want to display an error toast for non-200 responses
          ToastError("Oops! Something went wrong.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <main id="main">
      <div className="container">
        <section className="mt-5">
          <div className="section-header mt-5">
            <h2 className="text-center font-color">Get in Touch!</h2>
          </div>
        </section>
        <section id="contact" className="contact-area ptb_100 ">
          <div className="container mt-5">
            <div className="row justify-content-between align-items-center ">
              <div className="col-12 col-lg-6  contact-box ">
                <div className=" text-center">
                  <form id="contact-form" method="POST" onSubmit={SubmitForm}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control border-left1"
                            id="name"
                            name="name"
                            placeholder="Name"
                            required="required"
                            autoComplete="off"
                            value={formData.name}
                            onChange={handleInput}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control border-left1"
                            id="email"
                            name="email"
                            placeholder="Email"
                            required="required"
                            autoComplete="off"
                            value={formData.email}
                            onChange={handleInput}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="phone"
                            className="form-control border-left2"
                            name="phone"
                            id="phone"
                            placeholder="Phone"
                            required="required"
                            autoComplete="off"
                            value={formData.phone}
                            onChange={handleInput}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control border-left2"
                            id="order_id"
                            name="order_id"
                            placeholder="Order ID"
                            required="required"
                            autoComplete="off"
                            value={formData.order_id}
                            onChange={handleInput}
                          />
                        </div>
                      </div>
                      <div className="col-12 ">
                        <div
                          className="form-group"
                          data-aos-delay="200"
                          style={{ color: "#495057 !important" }}
                        >
                          <Select
                            options={services}
                            placeholder="Select Any Service"
                            className="service_select"
                            name="service"
                            required
                            styles={isDarkMode ? darkTheme : lightTheme}
                            onChange={handleServiceChange}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <textarea
                            className="form-control border-left1"
                            name="emessage"
                            id="emessage"
                            placeholder="Message"
                            required="required"
                            autoComplete="off"
                            value={formData.emessage}
                            onChange={handleInput}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn theme_flt_btn mt-3"
                          disabled={disable ? "disable" : ""}
                        >
                          {spinner ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            <span
                              className="font-color"
                              style={{ marginRight: 10 }}
                            >
                              <i className="fa fa-paper-plane"></i>
                            </span>
                          )}
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="col-12 col-lg-5">
                <div className="contact-us">
                  <ul>
                    {/* <a href='#' className=' my-2 font-color info-i'>
                      <li className='contact-info text-center'>
                        <span className=' font-size-22'>
                          <i className='fa fa-map font-color'></i>
                        </span>
                        <h5>
                          3/223,Behind venkateshwara school,
                          <br /> Avalapalli, Hosur-635109
                        </h5>
                      </li>
                    </a> */}
                    <a
                      href="mailto:support@techskins.com"
                      className=" my-2 font-color info-i"
                    >
                      <li className="contact-info  text-center ">
                        <span className=" font-size-22">
                          <i className="fa fa-envelope font-color"></i>
                        </span>
                        <h5>support@techskins.com</h5>
                      </li>
                    </a>
                    <div href="#" className=" my-2 font-color info-i">
                      <li className="contact-info text-center">
                        <span className=" font-size-22 info-i">
                          <i
                            className="fa fa-share-alt font-color"
                            aria-hidden="true"
                          ></i>
                        </span>
                        <br />
                        <span className="d-flex justify-content-center mt-1">
                          <ul
                            className="d-flex justify-content-between"
                            style={{ width: "70%" }}
                          >
                            <li>
                              <a
                                href="https://api.whatsapp.com/send?phone=+919940255602"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="font-size-16 fab fa-whatsapp"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://www.instagram.com/techskins.in/?igshid=OGQ5ZDc2ODk2ZA%3D%3D"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="font-size-16 fab fa-instagram"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://www.threads.net/@techskins.in"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="font-size-16 fab fa-threads"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://www.facebook.com/people/Tech-Skins/100089350340224/?mibextid=LQQJ4d"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="font-size-16 fab fa-facebook-f"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://twitter.com/TechSkinsind"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="font-size-16 fab fa-twitter"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://www.youtube.com/@techskins9630"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="font-size-16 fab fa-youtube"></i>
                              </a>
                            </li>
                          </ul>
                        </span>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="container mt-5 mb-5">
        <div className="col-md-12 mb-5">
          <div className="section-header">
            <h2 className="text-center font-color">Find Us!</h2>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31131.863565494143!2d77.839296!3d12.747118!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae77eb56f90709%3A0x630c4fa45ff2aa90!2sPixeltre%20Solutions%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1682488158540!5m2!1sen!2sin"
            width="100%"
            height="300"
            allowFullScreen=""
            loading="lazy"
            className="map"
            title="Pixeltre.com"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </main>
  );
};

export default ContactUs;
