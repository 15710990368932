import React from "react";

const EachStickerItem = ({
  sticker,
  quantity,
  id,
  selected_option_choices,
}) => {
  const basicPrice = parseInt(sticker.price);
  // Calculate the total price of selected options
  const optionsPrice = selected_option_choices.reduce(
    (total, optionChoice) =>
      total + parseInt(optionChoice.sticker_choice_map.price),
    0
  );

  const finalPrice = basicPrice + optionsPrice;
  return (
    <li className="border-bottom pb-3 mb-3">
      <div>
        <ul className="list-unstyled row mx-n2 d-flex align-items-center">
          <li className="px-2 col-auto cart_img">
            <img
              className="img-fluid"
              src={sticker?.default_image?.img_path}
              alt={sticker?.product_name}
            />
          </li>
          <li className="px-2 col">
            <h5 className="text-gray-5 font-size-14 font-weight-bold">
              {sticker?.product_name}
            </h5>
            <span className="text-gray-5 font-size-14">
              {quantity} x {finalPrice}
            </span>
          </li>
          <li className="px-2 col-auto">
            <h5 className="font-color font-size-14 font-weight-bold">
              ₹ {quantity * finalPrice}
            </h5>
          </li>
        </ul>
      </div>
    </li>
  );
};

export default EachStickerItem;
