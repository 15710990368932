import React, { useEffect, useState } from "react";
import Api from "../helpers/Api";
import { Link } from "react-router-dom";

const OrderList = () => {
  const [orders, setOrders] = useState([]);
  useEffect(() => {
    Api.OrdersList()
      .then((res) => {
        // console.log(res.data.orders);
        setOrders(res.data.orders);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  return (
    <div className="container">
      <h4 className="font-color text-center">Orders Information</h4>
      <table className="table table-hover table-striped table-bordered order-table">
        <thead className="text-center">
          <tr className="font-color">
            <th>Order ID</th>
            <th>Order Status</th>
          </tr>
        </thead>
        <tbody className="text-center">
          {orders.map((order) => (
            <tr key={order.id}>
              <td>
                <Link to="/invoice" className="btn drop_btn">
                  INV000{order.id}
                </Link>
              </td>
              <td className="font-color">
                {order.status === 0
                  ? "processing"
                  : order.status === 1
                  ? "delivered"
                  : "cancelled"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderList;
