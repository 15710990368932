import React from "react";
import { Link } from "react-router-dom";

const EachCoupleCard = ({ part1_skin_img,part2_skin_img, skin_name, id, reg_price }) => {
  return (
    <div className='col-md-2 col-6 p-card'>
      <div className='content couple-card'>
        <Link
          to={`/couples/preview/${id}`}
          className='font-size-12 text-gray-5'
        >
          <div className='front cat_img'>
            <div style={{ width: '55%', display: 'flex' }}>
              <img
                src={part1_skin_img}
                alt={skin_name}
                className='img-fluid p-2'
              />
              <img
                src={part2_skin_img}
                alt={skin_name}
                className='img-fluid p-2'
              />
            </div>
          </div>
        </Link>
        <div className='back from-bottom'>
          <div className='row pl-3 mt-2 mb-3 price-card d-flex justify-content-between align-items-center'>
            <h4 className='text-center pt-2 price-c'> ₹ {reg_price}</h4>

            <div className='product-btn d-flex align-items-center'>
              <button className='btn btn-link text-secondary '>
                <span>
                  <i className='fa fa-magnifying-glass-arrow-right'></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EachCoupleCard;
