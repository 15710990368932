import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const MaskImagesList = () => {
  let baseURL = "";
  // Check if the code is running in a local environment
  if (process.env.NODE_ENV === "development") {
    baseURL = "http://localhost:8004";
  } else {
    baseURL = "https://express.techskins.in";
  }
  const [images, setImages] = useState([]);
  const getMaskImages = async () => {
    const res = await axios.get(`${baseURL}/mask-images-list`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (res.data.status === 201) {
      console.log("data get");
      setImages(res.data.data);
    } else {
      console.log("error");
    }
  };
  useEffect(() => {
    getMaskImages();
  }, []);
  return (
    <div>
      MaskImagesList
      <Link to="/mask-images/create">Create</Link>
      <table className="table">
        <thead>
          <tr>
            <th>Image</th>
            <th>Link</th>
          </tr>
        </thead>
        <tbody>
          {images.map((image) => (
            <tr key={image.id}>
              <td>
                <div style={{ width: "100px" }}>
                  <img
                    src={`${baseURL}/uploads/${image.image_path}`}
                    alt="Masks"
                  />
                </div>
              </td>
              <td>{`${baseURL}/uploads/${image.image_path}`}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MaskImagesList;
