import React from 'react';

const ShippingCanceller = () => {
    return (
      <div className='container static-container mt-5 mb-5'>
        <h3 className='font-color'>
          <span className='text-danger'>Please Note:</span> Disclaimer
        </h3>
        <p>
          Due to possible physical differences between different monitors (e.g.
          models, settings, color gamut, panel type, screen glare, etc.), the
          product photography is illustrative only and may not precisely reflect
          the actual color of the item received.
        </p>
        <p>
          Due to possible physical differences between different monitors (e.g.
          models, settings, color gamut, panel type, screen glare, etc.), the
          product photography is illustrative only and may not precisely reflect
          the actual color of the item received. We can guarantee that the
          overall style displayed in the photography is accurate, however there
          may be differences in how the style appears during installation. This
          depends on other physical variables, e.g. Brand, Model, Shape, Design
          of the device etc.
        </p>
        <h4 className='font-color'>Cancellations, Returns & Refund</h4>
        <ul>
          <li className='font-color'>
            <i className='fa fa-check'></i> All returns are subjected to
            Approval by
            <a href='https://techskins.in/' className='h-link'>
              <strong> techskins.in </strong>
            </a>
            Returns must be initiated within 3 days from the date of delivery.
          </li>
          <li className='font-color'>
            <i className='fa fa-check'></i> Since each order is tailor maid to
            fit each brand and model, we do not take return or exchange unless
            the product we sent is damaged or wrong.
          </li>
          <li className='font-color'>
            <i className='fa fa-check'></i> We do not provide Refund as each
            order is custom made to fit specific brand and model.
          </li>
          <li className='font-color'>
            <i className='fa fa-check'></i> If the product is wrong or damaged,
            you can contact us with the video proof of the product received. Our
            team will review and will send you a new item if the claim is proven
            to be acceptable.
          </li>

          <li className='font-color'>
            <i className='fa fa-check'></i> Every order can be cancelled for a
            refund before it’s been shipped.
          </li>
        </ul>
        <h4 className='font-color'>Shipping</h4>
        <ul>
          <li className='font-color'>
            <i className='fa fa-check'></i> All order will be shipped within
            24-48 hours from the time of order.
          </li>
          <li className='font-color'>
            <i className='fa fa-check'></i> As soon as the product is shipped
            you will be up dated with a tracking number in your email.
          </li>
          <li className='font-color'>
            <i className='fa fa-check'></i> The package can be tracked under my
            account or in our partnering websites.
          </li>
          <li className='font-color'>
            <i className='fa fa-check'></i> In case the package goes missing or
            undelivered, we got you covered and we will send you a new one.
          </li>
        </ul>
        <p>
          If you have any questions or suggestions we will be happy to here form
          you, use the Contact us form to reach out to us.
        </p>
      </div>
    );
}

export default ShippingCanceller;
