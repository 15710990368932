import React, { useContext, useEffect, useState } from "react";
import { LoaderContext } from "../../context/LoaderContext";
import Api from "../../helpers/Api";
import PreLoader from "../../components/PreLoader";
import StickerTypeCard from "./StickerTypeCard";

const StickerTypeList = () => {
  const { isLoading, setIsLoading } = useContext(LoaderContext);
  const [types, setTypes] = useState([]);
  const StickerTypesFetch = () => {
    setIsLoading(true);
    Api.StickerTypesList()
      .then((res) => {
        setTypes(res.data.types);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    StickerTypesFetch();
  }, []);
  return (
    <>
      {isLoading ? (
        <PreLoader />
      ) : (
        <div className="container">
          <div className="row each_product_row mt-3">
            {types.map((type) => (
              <StickerTypeCard {...type} key={type.id} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default StickerTypeList;
