import axios from "axios";
import React, { useState } from "react";
import { ToastSuccess } from "../../helpers/Toast";

const MaskImagesCreate = () => {
  let baseURL = "";
  // Check if the code is running in a local environment
  if (process.env.NODE_ENV === "development") {
    baseURL = "http://localhost:8004";
  } else {
    baseURL = "https://express.techskins.in";
  }
  const [files, setFiles] = useState([]);

  const setImgFiles = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
  };
  const SubmitFormData = async (e) => {
    e.preventDefault();

    var formData = new FormData();
    // formData.append("photo", file);
    files.forEach((file, index) => {
      formData.append(`photos`, file); // Append the files as an array with field name "photos"
    });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const res = await axios.post(
      `${baseURL}/mask-images-upload`,
      formData,
      config
    );

    if (res.data.status === 201) {
      ToastSuccess("mask Images Uploaded SuccessFully");
      window.location.reload();
    } else {
      console.log("error");
    }
  };
  return (
    <div className="container mt-3">
      <h1>Upload Your Images Here</h1>

      <form>
        <label>Select Your Images</label>
        <input type="file" name="photos" onChange={setImgFiles} multiple />
        <button
          className="btn btn-primary"
          type="submit"
          onClick={SubmitFormData}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default MaskImagesCreate;
